.block_AB011 {
    .block-solutions {
        @apply relative;

        .heading {
            @apply text-center relative z-[1] max-[767px]:p-[0_32px];

            strong {
                @apply inline-block p-[5px_24px] font-semibold bg-sol-white dark:bg-sol-dark-gray;
            }
        }

        .block-card {
            flex-flow: row wrap;
            @apply flex max-[576px]:flex-col justify-center items-stretch min-[1351px]:gap-[16px] gap-[24px] relative z-[1];

            li {
                @apply min-[1251px]:max-w-[19%] min-[992px]:max-w-[24%] md:max-w-[32%] phablet:max-w-[47%] max-w-full min-[1251px]:flex-[1_0_17%] md:flex-[1_0_23%] phablet:flex-[1_0_50%] flex-[inherit] max-[576px]:w-full;

                .item-card {
                    @apply block w-full bg-sol-light-blue2 dark:bg-sol-dark-gray rounded-[12px] h-full no-underline p-[24px] duration-200 ease-in-out shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)];

                    &.has-link:hover {
                        @apply bg-sol-white dark:bg-sol-dark-gray mb-[16px] mt-[-16px];
                    }

                    &:not([href]) {
                        @apply bg-sol-white dark:bg-sol-dark-gray;
                    }

                    .icon-card {
                        @apply min-h-[34px] h-[34px] w-auto;
                    }

                    .new-title {
                        @apply font-semibold md:min-h-[100px] min-h-[inherit] transition-all duration-200 ease-in-out text-sol-charcoal dark:text-sol-white;
                    }

                    .text-paragraph {
                        @apply mb-0 text-sol-charcoal dark:text-sol-white;

                        p {
                            @apply mb-0;
                        }
                    }

                    &.has-link:hover {
                        @apply bg-sol-white dark:bg-sol-dark-gray no-underline text-[initial];

                        .new-title {
                            @apply text-sol-royal-blue dark:text-sol-white;
                        }
                    }
                }
            }

            &.card-columns-3 {
                @apply grid grid-cols-1 md:grid-cols-3;

                li {
                    @apply max-w-[unset];
                }
            }

            &.card-columns-4 {
                @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4;

                li {
                    @apply max-w-[unset];
                }
            }

            &.card-columns-5 {
                @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5;

                li {
                    @apply max-w-[unset];
                }
            }
        }
    }

    &.bg-transparent-custom .block-solutions .heading strong {
        @apply bg-sol-white;
    }

    &.bg-light-blue .block-solutions {
        .heading strong {
            @apply bg-sol-light-blue2;
        }

        .block-card li .item-card {
            @apply bg-sol-white;
        }
    }
}