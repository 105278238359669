.block_AB017 {    
    &.center-pos {
        .block-caption,
        .text-paragraph-col {
            @apply mx-auto text-center;
        }
        .nav-wrapper {
            @apply items-center flex flex-wrap justify-center;            
        }
    }

    &.bg-default {
        @apply bg-sol-charcoal;

        .heading,
        .text-paragraph-col p {           
            @apply text-sol-white;            
        }
    }
    

    &.bg-default,
    &.bg-light-blue {
        .swiper-wrapper__inner {
            background: linear-gradient(180deg, #fff 0%, #f3f9fe 175.62%);
        }
    }
    
    .swiper-container-vertical>.swiper-pagination-bullets {
        left: 15px;
    }

    .swiper-wrapper__inner {
        background: linear-gradient(180deg, #F3F9FE 0%, #DFF0FF 175.62%);
    }

    .block-caption{
        @apply mb-[24px];        
    }
}