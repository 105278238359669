.block_SAI-Hero-Banner {
    @apply relative bg-cover bg-center bg-no-repeat;
    padding-bottom: 125px !important;

    @media (max-width: 768px) {
        background-image: url('/wp-content/uploads/bg-hero-mobile.png') !important;
    }

    .heading-item {
        opacity: 0; /* Ensure all items are hidden initially */
        position: absolute; /* Prevent stacking issue */
        left: 0;
        top: 0;
        width: 100%;
    }

    .heading-item:first-child {
        @apply relative opacity-100;
    }

    @media (width >= 768px) {
        .sub-heading {
            @apply w-[70%] mx-auto;
        }
    }

    .sub-heading p {
        @apply text-[1.125rem];
    }

    .btn-hero-banner {
        @apply text-[1rem];
    }

    &.bg-default,
    &.bg-dark{
        * {
            @apply text-sol-white;
        }
    }
    h1 {
        font-size: 3.875rem;
    }
}
