.block_AB010 {
    @apply relative;

    &.about-cta-module {
        >.container {
            @apply z-[2] relative;
        }

        .bg-video {
            @apply z-[1] opacity-20 left-0 right-0;
            @apply mx-auto my-0 #{!important};
            clip-path: inset(2px 2px);
        }
    }

    .block-caption {
        @apply max-w-[690px] relative z-[1];
    }

    .btn-group {
        @apply flex flex-wrap gap-[10px];
    }

    .text-paragraph-col {
        @apply md:flex md:justify-end;
    }

    .text-paragraph-large {
        @apply relative z-[1] md:max-w-[445px];
    }

    .bg-img,
    .bg-video {
        @apply absolute bottom-0 desktop:left-0 left-[auto] w-full h-full object-cover z-0 max-[1199px]:ml-0;
    }
}