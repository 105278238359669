.block_AB015 {    
    &.center-pos {
        .block-caption,
        .text-paragraph-col {
            @apply mx-auto text-center;
        }
        .nav-wrapper {
            @apply items-center flex flex-wrap justify-center;            
        }
    }

    &.bg-default {
        @apply bg-sol-charcoal;

        .heading,
        .text-paragraph-col p {           
            @apply text-sol-white;            
        }
        
        .tab-content ul li,
        .tab-content ol li,
        .tab-content p {
            @apply text-sol-charcoal;
        }
        
        .nav-pills {
            @apply bg-sol-white;

            .nav-link {
                @apply text-sol-charcoal;
            }
        }

        .tab-pane .tap-mob-heading a {   
            @apply text-sol-charcoal;
        }

        .mob-tabpane-toggler svg path {                
            @apply stroke-sol-white;
        }
        
        .tab-pane.active  .tap-mob-heading a {   
            @apply text-[#F3F9FE];
        }
    }

    small b {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #0074e8, #a933fb);
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }
    
    .open {
        @apply relative before:content-[''] before:w-[90%] before:absolute before:bottom-0 before:h-[1px] before:bg-[#cacfd4];
        
        @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            @apply before:w-[100%];
        }
        @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {         
            @apply before:w-[95%];
        }
        @media all and (device-width: 820px) and (device-height: 1180px) and (orientation:portrait) {                
            @apply before:w-[95%];
        }
    }

    .dropdownDefaultButton {
        @apply text-sol-charcoal rounded-[12px] text-center flex items-center justify-between border-0 w-full px-[20px] pb-[15px] pt-[25px];
        
        @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {         
            @apply px-0;
        }
    }

    .dropdownList {
        @apply z-10 bg-sol-white rounded-[12px] shadow w-full mt-[-5px];

        .nav-link {
            @apply text-[14px] border-none #{!important};
        }
    }

    .tap-mob-heading {
        @apply hidden max-[768px]:block;
    }     

    .nav-wrapper {
        @apply max-[768px]:hidden #{!important};
    }

    .tab-pane {         
        .tap-mob-heading {
            @apply max-[768px]:py-[20px] max-[768px]:pt-0 max-[768px]:pb-[10px] max-[768px]:mb-[20px] max-[768px]:px-0 max-[768px]:border-b-[1px] max-[768px]:border-b-[#cacfd4] max-[768px]:border-solid max-[768px]:border-x-0 max-[768px]:border-t-0;                            

            a {     
                @apply max-[768px]:text-[16px] max-[768px]:tracking-[0.32px] max-[768px]:text-sol-charcoal max-[768px]:font-bold;            
            }
        }

        .mob-tabpane-toggler {
            @apply max-[768px]:items-center max-[768px]:flex max-[768px]:justify-between max-[768px]:w-full;                
        }
        
        .tabpane-inner-content {
            @apply max-[768px]:hidden;
            
            .button-block {
                @apply flex lg:mt-[60px] mt-[40px];
            }
    
            .solution-links {
                &__title {
                    @apply text-[16px] font-bold lg:mt-[32px] mt-[22px] text-sol-charcoal;
                }
    
                &__content {
                    @apply inline-flex flex-wrap lg:gap-[25px] gap-[15px] mt-[10px] text-[14px] font-bold;
    
                    a {
                        @apply text-sol-royal-blue;
                    }
                }
            }
    
            .bg-img {
                @apply object-cover w-full rounded-[12px];
            }

            h1,h2,h3,h4,h5,h6 {
                @apply mb-[15px];
            }
        }

        &.active {
            .mob-tabpane-toggler svg {    
                @apply max-[768px]:rotate-[180deg];
            }
    
            .tap-mob-heading a {   
                @apply max-[768px]:text-sol-charcoal;
            }
    
            .tabpane-inner-content {
                @apply max-[768px]:block;
    
                .row {
                    @apply max-[768px]:flex-col max-[768px]:gap-[20px];
                }
            }
        }
    }

    .block-caption {
        @apply mb-[5px];        
    }

    .tab__wrapper {   
        @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            @apply px-[24px];
        }

        .desktop {
            @apply lg:block hidden;

            // @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            //     @apply hidden #{!important};
            // }
        }

        .mobile {
            @apply lg:hidden block;

            // @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            //     @apply block #{!important};
            // }
        }
    }

    .nav-pills {                   
        @apply inline-flex lg:items-center items-end p-[4px] flex-nowrap w-full mb-[.75rem] mt-0 lg:whitespace-nowrap whitespace-normal;          
        
        .nav-link.active, 
        .show>.nav-link {
            @apply text-sol-charcoal font-bold bg-transparent;            
        }

        .nav-link {
            @apply relative text-sol-charcoal font-bold text-[16px] lg:max-lg:px-[14px] lg:px-[24px] px-[20px] pt-[12px] lg:pb-[24px] pb-[12px] text-center border-0 bg-transparent;  
                
            span {             
                &::before { 
                    @apply content-[''] w-full h-[1px] absolute lg:bottom-[10px] bottom-[-2px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                }
                div {
                    @apply relative;

                    &::before { 
                        visibility: hidden;
                        @apply content-[''] w-full h-[3px] absolute bottom-[-15px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                    }
                }
            }
            &.active span { 
                &::before {
                    visibility: visible;
                    @apply h-[3px] bg-opacity-100;             
                }

                &.green::before {
                    @apply bg-sol-green;
                }
    
                &.pink::before {
                    @apply bg-sol-pink;
                }
    
                &.purple::before {
                    @apply bg-sol-light-purple;                    
                }
                
                &.dark-purple::before {
                    @apply bg-sol-dark-purple;                    
                }
    
                &.yellow::before {
                    @apply bg-sol-yellow;
                }
    
                &.blue::before {
                    @apply bg-sol-royal-blue;
                }
    
                &.light-blue::before {
                    @apply bg-sol-light-blue;
                }
    
                &.orange::before {
                    @apply bg-sol-orange;
                }
            }                                   
        }
    }

    .tab-content {        
        @apply lg:pb-0 lg:pt-[26px] py-[26px] lg:px-0 md:px-[30px] px-[20px];

        .tab-pane {
            @apply relative hidden;
            
            &.show {
                @apply block;
            }
        }

        ul {
            @apply ml-[30px];

            li {        
                @apply text-[16px] leading-[32px] list-disc mb-[15px];                       
            }
        }
    }
}

.block_AB015 .object-contain {
    -o-object-fit: contain;
    object-fit: contain !important;
}