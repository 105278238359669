.block_AB018 {   
    @apply lg:pt-[60px] pt-0;

    .mobile-dropdown {
        @apply pt-[20px] mt-[40px] border-t border-b-0 border-r-0 border-l-0 border-solid border-[#DEDFE0];
    }

    .dropdown-sticky {
        @apply lg:top-[100px] z-[99] top-[60px] lg:py-0 py-[15px] lg:w-full w-[96%] lg:max-w-[268px];

        .dropdownDefaultButton {            
            @apply border-0 rounded-[12px] flex justify-between items-center w-full p-[15px];

            &.open {                
                @apply rounded-bl-none rounded-br-none;
            }
        }

        .disabled {
            @apply min-w-0 mr-0 w-0 h-0;
        }

        &__list {
            @apply bg-[#F3F9FE] rounded-[12px] p-[20px] flex flex-col gap-[20px] mt-0;

            li a {
                @apply text-[#25282E] hover:text-[#0074E8] hover:underline font-bold text-[20px];

                &:hover span {
                    @apply text-[#0074E8];
                }
            }

            .dropdownList {
                @apply rounded-bl-[12px] rounded-br-[12px];

                .nav-link {
                    @apply w-full px-[20px] py-[10px] text-left;                
                }
                
                .nav-item {
                    @apply w-full py-[10px] px-[15px];
                }
            }
        }
    }

    .dropdownList {
        @apply rounded-bl-[12px] rounded-br-[12px] mt-[-14px];
        
        .nav-link {
            @apply w-full block px-[10px] py-0 text-left;                
        }
        
        .nav-item {
            @apply w-full py-[10px] px-[15px];
        }
    }

    .block-solutions {
        &__entry {
            @apply border-b border-r-0 border-l-0 border-t-0 border-solid border-[#DEDFE0] lg:py-[80px] py-[40px];
            
            &.first {
                @apply lg:pt-[26px] lg:pb-[80px] py-[40px];
            }
        }

        &__left {
            @apply flex lg:flex-row flex-col justify-between lg:items-center items-start lg:mb-[50px] mb-[20px] lg:gap-0 gap-[20px];
        }

        &__right {
            @apply flex lg:flex-row flex-col lg:gap-[40px] gap-[32px] lg:pb-[40px] pb-[20px] lg:mb-[30px] mb-[20px] border-b border-r-0 border-l-0 border-t-0 border-dashed border-[#0074E8];

            .subtitle {
                @apply lg:mb-[32px] mb-[20px];
            }

            .text-paragraph {
                @apply lg:mb-[30px];
            }
        }

        &__features {
            @apply grid;

            &.two-col {
                @apply lg:grid-cols-2;

                .block-solutions__features-list a:hover {
                    @apply lg:w-[90%];
                }
            }

            &-list {
                @apply flex flex-row items-center;

                svg {
                    @apply hidden;
                }

                a {
                    @apply relative flex items-center justify-start hover:justify-between gap-[15px] text-[#0074E8] w-full py-[10px];

                    &:hover {
                        @apply cursor-pointer lg:w-[90%] w-[95%] lg:ml-0 ml-[10px];
    
                        &::before {
                            @apply content-[''] bg-[#F3F9FE] rounded-[12px] w-[106%] absolute left-[-3%] h-[106%] top-[-3%] z-[-1];                                                    
                        }
    
                        svg {
                            @apply block;
                        }
                    }
                }                               
            }
        }
    }
    
    .block-img {
        @apply relative;

        .stats {
            @apply absolute bottom-0 z-[1] w-full;

            img {
                @apply w-full object-contain block h-auto;
            }
        }
    }
}