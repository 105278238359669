.symphonyai-navbar {
  position: fixed;
  @apply bg-sol-white font-sans h-auto p-0 top-0 w-full z-[9999] transition-all duration-[1s] ease-in-out shadow-[0_3px_10px_0_rgba(0,0,0,.102)];

  .header-inner {
    @apply bg-transparent relative p-0 w-full transition-all duration-[1s] ease-in-out before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-full before:border-b before:border-x-0 before:border-t-0 before:border-solid before:border-transparent after:content-[''] after:backdrop-blur-[10px] after:bg-[#515151] after:bg-opacity-[0.33] after:absolute after:left-0 after:top-0 after:w-full after:h-full after:z-[-1] hover:bg-sol-white;

    &:hover {
      .navbar-brand img {
        @apply desktop:filter-none;
      }

      .menu .menu-item .nav-link {
        @apply desktop:text-sol-charcoal desktop:hover:text-sol-royal-blue;        
      }
    }

    .header-nav {
      @apply flex relative z-[1] desktop:py-0 desktop:px-[15px] p-[20px] items-center desktop:h-[86px] h-[68px];
    }
  }

  .header-mobile {
    @apply relative flex items-center;

    @media (max-width: 768px) {
      svg {
        @apply w-[80%];
      }
    }

    @media (max-width: 1199px) {
      @apply w-full left-0 top-0 z-[999] h-full;
    }

    .heading-link.labels-group {
      @apply ml-[20px] text-[13px] font-semibold leading-[140%] desktop:hidden;
    }
  }

  .navbar-collapse {
    @apply h-full justify-end;

    @media(max-width:1199px) {
      position: fixed;
      @apply bg-sol-white h-[calc(100vh-104px)] overflow-auto w-full left-0 top-[70px] duration-200 ease-in-out #{!important};
    }

    .get-started {      
      @apply desktop:flex desktop:justify-center desktop:items-center desktop:ml-[16px];
      
      @media(max-width:1199px) {
        @apply bg-sol-white relative p-[20px] z-[999] w-full text-center;
      }

      .btn {
        @apply mb-0;
      }

      a {
        @media(max-width:1199px) {
          @apply w-full text-center;
        }
      }
    }

    &.show {
      @media(max-width:1199px) {
        @apply flex flex-col justify-between;
      }

      .menu-header-menu-navigation-container {
        @media(max-width:1199px) {
          @apply opacity-[1];
        }
      }
    }

    &.collapsing {
      @media(max-width:1199px) {
        @apply flex flex-col justify-between;
      }
    }

    .menu-header-menu-navigation-container {
      @media(max-width:1199px) {
        @apply opacity-0 w-full overflow-y-scroll ease-in-out duration-500 transition-opacity;
      }
    }    
  }

  .navbar-brand {
    @apply duration-200 ease-in-out flex relative z-[2] leading-[1];

    img {
      @apply duration-200 ease-in-out md:h-[30px] h-[22px] brightness-0 invert md:max-w-[214px];
    }
  }

  .dropdown-solutions {
    @apply py-[21px] px-[20px] ml-[20px] relative border-l border-y-0 border-r-0 border-solid phablet:block hidden border-[$ColorGray2];

    .btn {
      @apply pr-[47px] relative after:content-[''] after:w-[12px] after:h-[7px] after:right-[26px] after:bottom-[50%] after:bg-contain after:absolute after:cursor-pointer after:translate-y-[50%];

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.801758 1.22119L6.80176 7.22119L12.8018 1.22119' stroke='%2325282E'/%3E%3C/svg%3E");
      }
    }

    &.ds-mobile {
      @apply block w-full relative m-0 pt-[10px] px-[20px] pb-[11px] border-t border-x-0 border-b-0 border-solid border-[#00000012] phablet:hidden after:content-[''] after:top-[19px] after:right-[20px];

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.801758 1.22119L6.80176 7.22119L12.8018 1.22119' stroke='%231A2D39'/%3E%3C/svg%3E");
      }

      a {
        @apply text-sol-charcoal leading-[1];
      }
    }
  }

  .navbar-toggler {
    transform: translate(0, -50%);
    @apply duration-200 ease-in-out bg-transparent flex justify-end items-center shadow-none border-0 rounded-none z-[3] absolute right-0 top-[50%] p-0 w-[28px] h-[28px] desktop:hidden focus:shadow-none;

    .icon-hamburger {
      @apply w-[23px] h-[16px] desktop:hidden;

      rect {
        @apply duration-200 ease-in-out fill-sol-white;
      }
    }

    .icon-close {
      @apply hidden w-[18px] h-[18px];

      rect {
        @apply duration-200 ease-in-out fill-sol-white;
      }
    }

    &.close-navbar {
      .icon-close {
        @apply block;
      }

      .icon-hamburger {
        @apply hidden;
      }
    }
  }

  .menu-header-menu-navigation-container {
    @apply flex p-0 desktop:h-full h-auto desktop:items-center items-start desktop:justify-end justify-start desktop:flex-row flex-col;
  }

  .menu {
    @apply p-0 list-none desktop:inline-flex block flex-row flex-wrap justify-center m-auto desktop:w-fit w-full h-full;

    .menu-item {
      @media (max-width: 1199px) {
        @apply py-0 px-[20px] w-full block relative;
      }

      .nav-link {
        @apply duration-200 ease-in-out flex items-center relative no-underline desktop:text-sol-white text-sol-charcoal font-sans h-full font-semibold desktop:text-[16px] text-[20px] desktop:p-[16px] py-[16px] px-0 m-0 desktop:border-0 border-t-[3px] border-x-0 border-b-0 border-solid border-transparent desktop:before:content-none before:content-[''] desktop:after:content-none after:content-['']
              active:text-sol-royal-blue hover:text-sol-royal-blue focus:text-sol-royal-blue active:bg-transparent hover:bg-transparent focus:bg-transparent;

        &:before {
          @media (max-width: 1199px) {
            @apply duration-200 ease-in-out absolute w-full top-0 left-0 border-t border-x-0 border-b-0 border-solid border-[#00000010];
          }
        }

        &.dropdown-item {
          &::after {
            @media (max-width: 1199px) {
              background-image: url("data:image/svg+xml,%3Csvg width='19' height='11' viewBox='0 0 19 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9.5 9L18 1' stroke='%2325282E' stroke-width='2'/%3E%3C/svg%3E%0A");              
              @apply duration-200 ease-in-out rotate-0 bg-contain absolute w-[17px] h-[10px] right-0 top-auto;
            }
          }

          &.show {              
            @media (max-width: 1199px) {
              @apply text-sol-royal-blue after:-rotate-180;              
            }
          }
        }

        &.dropdown-toggle {
          @apply relative after:content-[''] after:duration-200 after:ease-in-out after:bg-cover after:mt-[-3px] after:ml-[12px] after:w-[12px] after:h-[7px] after:rotate-0 after:border-none after:m-0 desktop:after:hidden after:inline-block after:bg-[$IconChevronUp];

          &.show {
            @apply after:-rotate-180;
          }
        }
      }

      .toggler {
        @apply hidden;
      }

      .dropdown-menu .dropdown-active>a {
        //@apply bg-[#7EB8F2] rounded-[8px];
        
        img {
          @apply rotate-[90deg] #{!important};
        }
      }
    }

    .dropdown {
      @apply desktop:static;

      .megamenu {
        @apply desktop:mt-0 #{!important};
        @apply hidden desktop:shadow-[0_3px_5px_-3px_rgba(0,0,0,0.102)] desktop:bg-sol-white desktop:duration-200 desktop:ease-in-out desktop:absolute desktop:w-full desktop:m-auto desktop:p-[20px] desktop:top-full desktop:left-0 desktop:border-none desktop:rounded-none desktop:bg-repeat-x
              desktop:before:content-[''] desktop:after:content-[''] desktop:bg-[position:0_104.6%];

        @media(max-width:1199px) {
          @apply relative transform-none #{!important};
          @apply border-none pt-0 px-0 pb-[30px] w-full bg-sol-white;
        }

        &::before {
          @apply desktop:bg-sol-white desktop:bg-repeat-x desktop:h-full desktop:left-[-100%] desktop:min-h-full desktop:absolute desktop:top-0 desktop:w-full desktop:shadow-[0_3px_5px_-3px_rgba(0,0,0,0.102)] desktop:bg-[position:0_104.6%];
        }

        &::after {
          @apply desktop:bg-sol-white desktop:bg-repeat-x desktop:h-full desktop:min-h-[100px] desktop:absolute desktop:right-[-100%] desktop:top-0 desktop:w-full desktop:shadow-[0_3px_5px_-3px_rgba(0,0,0,0.102)] desktop:bg-[position:0_104.6%];
        }        

        &.show {
          @apply block #{!important};
        }

        .megamenu-inner {
          @apply flex desktop:before:content-[''] desktop:after:content-[''];

          &::before,
          &::after {
            @apply desktop:absolute desktop:h-full desktop:w-[25px] desktop:left-[-10px] desktop:top-[-2px] desktop:z-[9] desktop:bg-sol-white;
          }

          .megamenu-row {
            @apply flex desktop:py-[10px] desktop:px-0 desktop:flex-row flex-col;

            @media(max-width:1199px) {
              @apply w-full;
            }

            .megamenu-column {
              @apply desktop:py-0 desktop:px-[50px];

              .megamenu-subnav.solutions {
                @media(max-width:1199px) {
                  @apply w-full pt-0 #{!important};
                }

                li a {
                  @media(max-width:1199px) {
                    @apply pl-[30px] #{!important};
                  }

                  .heading-title {
                    @media(max-width:1199px) {
                      @apply mb-0 #{!important};
                    }
                  }
                }
              }

              &.column-left {
                @apply desktop:min-w-[446px] pr-[66px];

                &.solutions {
                  @apply py-[15px] px-[30px] desktop:bg-sol-light-blue2 desktop:rounded-[16px] desktop:p-[30px] desktop:relative desktop:w-[446px] desktop:min-w-[unset] desktop:after:content-[''] desktop:after:bg-[url("../img/solution-mask.png")] desktop:after:absolute desktop:after:top-0 desktop:after:left-0 desktop:after:w-full desktop:after:h-full desktop:after:opacity-[0.8] desktop:after:z-[9] desktop:after:bg-cover;

                  article {
                    @apply flex h-full justify-between relative z-[11];
                  }

                  .column-left__link {
                    @apply text-sol-royal-blue tracking-[0.28px] leading-[100%] font-bold text-[14px] justify-between items-center desktop:flex hidden;
                  }

                  .text-paragraph {
                    @apply desktop:block hidden;

                    p {
                      @apply text-[14px] font-normal leading-[140%];
                    }
                  }
                }
              }

              &.column-right.solutions {
                @apply pr-0 after:hidden;
              }

              article {
                @apply flex flex-col desktop:gap-[20px] gap-0 max-w-[330px];

                .heading-overline {
                  @apply desktop:text-[20px] text-[18px] desktop:leading-[24px] leading-[22px] font-sans tracking-normal;
                }

                .text-paragraph,
                .text-paragraph p {
                  @apply font-sans;

                  @media(max-width:1199px) {
                    @apply text-[14px] leading-[20px];
                  }
                }

                figure {
                  @apply overflow-hidden desktop:block hidden;

                  img {
                    @apply h-full w-full object-cover rounded-[16px];
                  }
                }

                .btn {
                  @media(max-width:1199px) {
                    @apply text-sol-royal-blue font-sans inline-block pl-0 bg-transparent border-transparent;
                  }

                  span {
                    @media(max-width:1199px) {
                      @apply relative after:content-[''] after:absolute after:right-[-15px] after:top-[4px] after:w-[7px] after:h-[11px] after:bg-contain;
                    }

                    &::after {
                      @media(max-width:1199px) {
                        background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5.5L1 10' stroke='%230074E8' stroke-width='2'/%3E%3C/svg%3E%0A");
                      }
                    }
                  }
                }                
              }

              &.column-right {
                @apply flex relative items-center desktop:after:content-[''] desktop:after:absolute desktop:after:top-0 desktop:after:left-0 desktop:after:h-full desktop:after:w-[1px] desktop:after:bg-[#00000010];

                .megamenu-subnav {
                  @apply desktop:grid desktop:grid-cols-3 desktop:grid-rows-2 desktop:gap-x-[50px] desktop:gap-y-[50px] desktop:list-none desktop:m-0;

                  @media(max-width:1199px) {
                    @apply flex flex-col pt-[40px] px-0 pb-[10px];
                  }

                  &.solutions {
                    @apply desktop:grid-cols-2 desktop:gap-x-[60px] desktop:gap-y-[20px];

                    li a {
                      @apply border-t-0 h-full py-[18px] pr-[27px] pl-[45px] gap-0 after:hidden hover:border-t-0 hover:after:hidden desktop:hover:bg-sol-light-blue2 desktop:hover:rounded-[16px];

                      .heading-title {
                        @apply items-center flex justify-between mb-[12px];

                        svg {
                          @apply opacity-0;
                        }
                      }

                      &:hover {
                        .heading-title svg {
                          @apply opacity-[1];
                        }

                        .heading-link {
                          @apply text-sol-charcoal;
                        }
                      }
                    }
                  }

                  li {
                    a {
                      @apply desktop:gap-y-[16px] desktop:gap-x-[50px] duration-200 ease-in-out text-sol-charcoal border-transparent no-underline relative desktop:py-[20px] py-[10px] px-0 flex flex-col border-t-[3px] border-x-0 border-b-0 border-solid desktop:after:content-[''] desktop:after:w-full desktop:after:border-t desktop:after:border-solid desktop:after:border-[#00000010] desktop:after:border-x-0 desktop:after:border-b-0 desktop:after:absolute desktop:after:left-0 desktop:after:top-0 desktop:hover:border-t-sol-green desktop:hover:after:border-t-sol-green;

                      .heading-link {
                        @apply duration-200 ease-in-out font-sans desktop:text-[20px] text-[16px] desktop:leading-[24px] leading-[20px] font-semibold;

                        &.labels-group {
                          @apply relative;

                          i {
                            @apply hidden left-[-25px] absolute;
                          }
                        }
                      }

                      .description-link {
                        @apply font-sans desktop:block hidden text-[14px] leading-[20px];
                      }

                      &:hover {
                        .heading-link {
                          @apply text-sol-royal-blue font-sans;

                          &.labels-group i {
                            @apply desktop:block;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.show {
          @apply desktop:rounded-none desktop:left-0 desktop:top-[100%] desktop:transform-none #{!important};
        }
      }
    }

    &.menu-solutions li:first-child .nav-link::before {
      @apply border-t-transparent;
    }
  }

  &.fixed,
  &.header-white,
  &.has-quick-menu {
    .header-inner {
      @apply after:hidden;

      .navbar-brand img {
        @apply filter-none;
      }

      .menu .menu-item {
        .nav-link {
          @apply desktop:relative desktop:text-sol-charcoal;

          &.active,
          &:hover,
          &:focus {
            @apply desktop:bg-transparent desktop:after:content-[''] desktop:after:bg-sol-royal-blue desktop:after:w-[90%] desktop:after:absolute desktop:after:left-[10px] desktop:after:h-[3px] desktop:after:bottom-[24px];
          }

          &.show {
            @apply desktop:after:content-[''] desktop:after:bg-sol-royal-blue desktop:after:w-[80%] desktop:after:absolute desktop:after:left-[10px] desktop:after:h-[3px] desktop:after:bottom-[15px];
          }
        }          
      }
    }

    .icon-hamburger,
    .icon-close {
      rect {
        @apply fill-sol-white;
      }
    }
  }

  .solutions-menu {
    @apply hidden w-full border-0 p-0 overflow-hidden border-t border-x-0 border-b-0 border-solid border-[#00000012] desktop:relative desktop:w-full desktop:m-auto desktop:shadow-none desktop:duration-200 desktop:ease-in-out desktop:bg-sol-white;

    @media(max-width:1199px) {
      position: fixed !important;
      @apply w-[calc(100%-40px)] h-[calc(100%-105px)] overflow-auto bg-sol-white transform-none left-[20px] #{!important};
    }

    @media(max-width:576px) {
      @apply mt-0;
    }

    @media(max-width: 767px) {      
      @apply h-[calc(100vh-104px)];
    }

    &-back {
      @apply md:hidden inline-block relative mt-[20px] md:mx-[50px] mx-[20px] mb-0 pl-[17px] text-[16px] after:content-[''];

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.801758 1.22119L6.80176 7.22119L12.8018 1.22119' stroke='%230d6dfd'/%3E%3C/svg%3E");
        stroke: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
        @apply w-[12px] h-[7px] left-0 bottom-[9px] rotate-90 bg-contain absolute cursor-pointer pointer-events-none;
      }
    }

    a .active {
      @apply text-sol-royal-blue;
    }

    .megamenu-row {
      @apply flex justify-center md:flex-row flex-col;

      .megamenu-column {
        @apply bg-sol-light-blue2 md:p-[50px] pt-[20px] px-[20px] pb-0 md:w-[50%] w-full;

        &.column-right+.megamenu-column.column-right {
          @apply bg-sol-light-blue2;
        }

        &.column-right {
          @apply relative bg-[#f5f5f5];

          @media(max-width:767px) {
            @apply pb-[10vh];
          }
        }

        .column-inner {
          flex-flow: row wrap;
          @apply flex flex-col desktop:gap-[30px] md:gap-y-[40px] md:gap-x-[20px] gap-y-[15px] gap-x-[20px];

          .column-list {
            @apply w-[calc(50%-25px)];

            @media(max-width:1199px) {
              @apply w-full;
            }

            .heading-title {
              @apply duration-200 ease-in-out md:text-[18px] text-[16px] leading-[20px] font-semibold font-sans md:mb-[35px] mb-[30px] text-[#00000050];
            }

            .heading-subtitle {
              @apply duration-200 ease-in-out text-[16px] leading-[20px] font-semibold font-sans mb-[20px];
            }

            .megamenu-subnav-v {
              @apply flex flex-col;

              li {
                @apply mb-[20px];

                a {
                  @apply block text-[16px] leading-[20px] text-sol-charcoal duration-200 ease-in-out;

                  &:hover {
                    @apply text-sol-royal-blue;
                  }

                  &.col-header-link {
                    @apply font-bold;
                  }

                  &.active {
                    @apply text-sol-royal-blue;
                  }

                  &.new {
                    @apply pt-[16px] px-[20px] pb-[22px] bg-sol-light-blue2 #{!important};

                    span {
                      @apply block text-[11px] mb-[10px] font-medium;
                    }

                    &:hover {
                      @apply bg-sol-royal-blue text-sol-white;
                    }
                  }
                }
              }
            }

            &.empty-list .megamenu-subnav-v li {
              @apply md:mb-[-18px];
            }
          }
        }

        &.column-left .column-inner {
          @apply desktop:justify-end;
        }

        &.has-multi-column.column-left .column-inner {
          @apply desktop:justify-start;
        }

        &.has-heading-title {
          @apply desktop:pt-[105px];

          >div:first-child {
            @apply relative;

            .heading-title {
              @apply desktop:absolute desktop:top-[-55px];
            }
          }
        }
      }

      &.number-1-col {
        .megamenu-column {
          &.has-multi-column {
            @apply md:w-full;
          }

          .column-inner {
            @apply md:gap-[30px];

            .column-list {
              @apply md:w-[30%];
            }
          }
        }
      }
    }

    &.show {
      @apply block transform-none #{!important};

      @media(max-width:767px) {
        @apply h-[calc(100vh-140px)];
      }
    }
  }
}

body {
  &.js-showmenu {
    @apply desktop:overflow-auto overflow-hidden;

    .normal-dropdown-menu {
      @apply block;
    }

    .symphonyai-navbar {
      .navbar-collapse .menu-header-menu-navigation-container {
        @apply opacity-[1];
      }

      .header-inner {
        @apply bg-sol-white after:hidden;

        .navbar-brand img {
          @apply filter-none;
        }

        .icon-hamburger,
        .icon-close {
          rect {
            @apply fill-sol-white;
          }
        }
      }
    }
  }

  &.js-show-megamenu {
    .symphonyai-navbar {
      .header-inner {
        @apply bg-sol-white before:border-[#00000010] after:hidden;

        .navbar-brand img {
          @apply filter-none;
        }

        .menu .menu-item {
          .nav-link {
            @apply desktop:text-sol-charcoal;
          }

          &.dropdown.dropdown-active .nav-link {
            @apply desktop:text-sol-royal-blue;
          }          
        }

        .icon-hamburger,
        .icon-close {
          rect {
            @apply desktop:fill-sol-charcoal;
          }
        }
      }
    }
  }

  .navbar-collapse.no-transition {
    @apply transition-[height] duration-[0.01s] #{!important};
  }

  .dropdown-solutions {
    @apply hidden;
  }
  
  .submenu {
    @apply bg-sol-light-blue2 fixed desktop:top-[86px] top-[68px] transition-all duration-[1s] ease-in-out w-full z-[89];

    &.js-scrolled {
      @apply top-0 shadow-[0_3px_10px_0_rgba(0,0,0,.102)];

      .acf-nav-menu .menu .menu-item.menu-item-has-children.active .sub-menu {
        @apply top-[65px] transition-all duration-[1s] ease-in-out;
      }
    }

    &__wrapper {
      @apply flex desktop:items-center items-start desktop:justify-between justify-start desktop:flex-row flex-col;
      
      .menu {
        @apply mt-0 mr-[10px];
      }
    }

    .acf-nav-menu {
      @apply desktop:w-[77%] w-full;

      @media(max-width: 1199px) {
        @apply hidden h-[100vh] ml-[20px] overflow-y-scroll pb-[80px];
      }

      .menu>.menu-item:first-child {
        @media(max-width: 1199px) {
          @apply ml-0;
        }
      }

      .menu {
        @apply flex desktop:justify-end justify-start desktop:flex-row flex-col desktop:whitespace-normal whitespace-nowrap mr-0;

        .sub-menu {
          @apply desktop:shadow-[0_3px_10px_#25282E26] desktop:bg-sol-white bg-transparent desktop:rounded-[8px] rounded-none desktop:hidden block left-0 py-[6px] px-[8px] desktop:absolute relative desktop:top-[65px] top-0 desktop:min-w-[240px] min-w-full;

          li {
            @apply mt-0 desktop:mx-0 ml-[20px] mr-0 mb-[5px] last:mb-0;
          }

          .sub-menu {
            @apply desktop:hidden desktop:left-[106%] desktop:top-[-6px];
          }
        }

        .menu-item {
          &:hover .sub-menu .sub-menu {
            @apply desktop:hidden block;
          }

          &:hover .sub-menu .menu-item:hover .sub-menu {
            @apply block before:content-[''] before:absolute before:w-[40px] before:left-[-20px] before:h-full;
          }

          .sub-menu .menu-item.menu-item-has-children {
            @apply pr-0 after:-rotate-90;
          }
        }

        >.menu-item.current-menu-item {
          @apply desktop:before:content-[''] desktop:before:absolute desktop:before:w-full desktop:before:left-0 desktop:before:h-[2px] desktop:before:bottom-[15px];
        }

        .menu-item {
          @apply desktop:ml-[20px] ml-0 relative;

          &.menu-item-has-children {
            @apply desktop:pr-[30px] pr-[20px] desktop:after:bg-[url(../img/arrow-down-2.svg)] desktop:after:bg-no-repeat desktop:after:content-[''] desktop:after:absolute desktop:after:w-[12px] desktop:after:right-[10px] desktop:after:h-[8px] desktop:after:top-[45%];
            
            .sub-menu .menu-item {
              @apply before:hidden;
            }

            &.active {
              @apply after:scale-y-[-1];

              &::after {
                filter: FlipV;
                -ms-filter: "FlipV";
              }
            }
          }

          &:hover,
          &.current-menu-parent {
            @apply desktop:before:content-[''] desktop:before:absolute desktop:before:w-full desktop:before:left-0 desktop:before:h-[2px] desktop:before:bottom-[15px];
          }

          &:hover {
            .sub-menu {
              @apply desktop:block;

              .menu-item {
                @apply desktop:hover:before:hidden;
              }
            }

            &::after {
              @apply desktop:scale-y-[-1];

              @media(min-width: 1200px) {
                filter: FlipV;
                -ms-filter: "FlipV";
              }
            }
          }

          a {
            @apply text-sol-charcoal block text-[14px] font-semibold leading-[100%] tracking-[0.28px] desktop:py-[27px] pt-[24px] px-0 pb-[12px];

            @media(max-width:1199px) {
              text-wrap: wrap;
              @apply ml-0;
            }
          }

          .sub-menu {
            a {
              @apply font-normal leading-[140%] py-[10px] px-[12px] hover:bg-sol-light-blue2 hover:rounded-[8px];
            }

            .current-menu-ancestor>a,
            .current-menu-item a {
              @apply bg-[#0074E880] rounded-[8px];
            }

            .current-menu-item a {
              @apply after:content-[''] after:bg-[url('../img/check.svg')] after:absolute after:w-[8px] after:right-[10px] after:h-[6px] after:top-[45%];
            }
          }
        }
      }
    }

    .solution-heading {
      @media(max-width:1199px) {
        @apply block py-[20px] px-[5px] w-full after:content-[''] after:bg-[url('../img/arrow-down-2.svg')] after:top-[30px] after:absolute after:h-[8px] after:right-[20px] after:w-[12px];
      }

      &.active::after {
        @media(max-width:1199px) {
          @apply scale-y-[-1];
          filter: FlipV;
          -ms-filter: "FlipV";
        }
      }

      a {
        @media(max-width:1199px) {
          @apply w-full;
        }
      }

      .heading-description {
        @apply text-sol-charcoal text-[20px] font-semibold leading-[120%];
      }
    }

    .show {
      @apply block #{!important};
    }

    .megamenu-subnav.solutions {
      i,
      svg {
        @apply desktop:block hidden;
      }
    }

    .mob-overview a {
      @media(max-width: 1199px) {
        @apply ml-[20px];
        @apply font-normal #{!important};
      }
    }
  }
}

.normal-dropdown-menu {
  @apply bg-sol-white desktop:flex hidden desktop:relative fixed desktop:top-0 top-[67px] left-0 justify-end w-full h-full;

  .menu-item {
    @apply p-0;

    &:first-of-type .nav-link {
      @apply pr-[17px] #{!important};
    }

    .nav-link {
      @apply desktop:px-[20px] desktop:py-0 #{!important};
      @apply lg:text-[16px] text-[20px];
    }

    &__link,
    &.dropdown .dropdown-item {
      @apply text-sol-charcoal flex-nowrap py-[12px] flex items-center justify-between font-semibold hover:bg-sol-light-blue2 hover:text-sol-charcoal hover:rounded-[8px] relative;
      outline: none;

      &:focus-visible {
        outline: none;
      }
    }

    &__link {
      @apply pl-[20px] pr-[50px] text-[16px];
    }
    
    &.dropdown {
      .dropdown-item {      
        @apply desktop:px-[20px];
        
        @media(max-width: 1199px) {
          @apply text-sol-charcoal hover:text-sol-charcoal;
        }

        &.show {      
          @media(max-width: 1199px) {
            @apply text-sol-royal-blue hover:text-sol-royal-blue;
          }
        }

        &:hover,
        &.show:hover {      
          @media(max-width: 1199px) {
            @apply bg-transparent #{!important};
          }
        }

        &.show-dropdown {
          @apply desktop:pl-[20px] pl-0 pr-[50px];
        }
      }
      &.dropdown-active .dropdown-item:hover {
        @apply desktop:bg-sol-light-blue2 text-sol-charcoal desktop:rounded-[8px];
      }
    }
  }
  
  &.show {
    @apply flex bg-sol-white flex-col h-[calc(100vh-68px)] pb-[40px] #{!important};

    .dropdown-menu.show {
      @apply block border-0 p-0 bg-transparent shadow-none;
    }

    >.menu-item:last-of-type {
      @apply py-[10px] px-[20px];
    }

    .nav-link {
      @apply text-[18px];

      &.show {
        @apply after:-scale-y-100;
      }
    }

    .labels-group span i {
      @apply hidden;
    }

    .navbar-nav.menu {
      @apply overflow-y-scroll;
    }
  }

  .navbar-nav {
    @apply desktop:flex desktop:justify-end desktop:items-center w-full gap-[20px];
  }

  .dropdown-menu {
    @apply desktop:hidden desktop:absolute desktop:left-[-24px] py-[8px] desktop:top-[94px] border-0 rounded-[8px] desktop:shadow-[0_0_30px_0_rgba(0,0,0,0.10)] bg-sol-white;

    .menu-item {
      @apply px-[8px];
    }
  }

  .menu-item a:hover svg {
    @apply lg:block hidden;
  }

  .menu-item__link.current-page-item {    
    @apply bg-[#7eb8f2] rounded-[8px] relative after:content-[''];
    // @apply max-lg:after:hidden max-lg:text-sol-royal-blue #{!important};

    &::after {
      @apply bg-[url('../img/check.svg')] block absolute right-[10px] top-[45%] w-[8px] h-[6px];
    }

    &:hover {
      @apply bg-[#7eb8f2] rounded-[8px] relative;
    
      svg {
        @apply hidden #{!important};
      }
    }
  }
  .current-page-parent {
    @apply bg-[#7EB8F2] rounded-[8px];
    // @apply max-lg:text-sol-royal-blue;
    @apply hover:bg-[#7EB8F2] #{!important};
  }

  .has-children.open a {
    @apply desktop:bg-sol-light-blue2 rounded-[8px] relative;
  }
}

.country-selector.weglot-default {
  @apply z-[9999];
}

.header-2025 {
  background: transparent !important;
  box-shadow: none !important;

  .normal-dropdown-menu {
    background: transparent;
  }
  .header-inner {
    background: transparent !important;
  }
  .header-inner:focus {
    background: transparent !important;
  }

  .header-inner:active {
    background: transparent !important;
  }

  .header-inner:focus-within {
    background: transparent !important;
  }

  input#search {
    display: none;
  }

  .menu {
    .menu-item {
      .nav-link {
        color: #fff !important;
      }
    }
  }


  // Media query for max-width 768px
  @media (max-width: 1199px) {    
    .menu {
      .menu-item {
        .nav-link {
          background: #fff !important;
          color: #000 !important;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .header-2025.header-2025-w svg rect {
    fill: #fff !important;
  }
  .symphonyai-navbar svg rect {
    fill: #000 !important; // Fix old code issue
  }
}
@media (max-width: 768px) {
  .header-2025.header-2025-w  svg rect {
    fill: #fff !important; // Ensures the rectangle inside the SVG is white
  }
}


.header-2025-w {
  background: #ffffff;
  
  input#search {
    display: none;
  }

  .normal-dropdown-menu {
    background: transparent;
  }
  .header-inner {
    background: transparent !important;
  }

  @media (max-width: 1199px) {

    svg rect {
      fill: #000 !important; // Ensures the rectangle inside the SVG is white
    }

    .menu-item {
      background: #ffffff !important;

    }
  }
}

@media (max-width: 768px) {
  .header-2025.header-2025-w  svg rect {
    fill: #fff !important; // Ensures the rectangle inside the SVG is white
  }
}

// Remove Search for now 
.wp-block-search__inside-wrapper {
  display: none;
}

