.block_SL005 {
    @apply overflow-hidden;

    .swiper-reports {
        @apply relative flex grow p-[64px] bg-sol-charcoal;

        @media(max-width:991px) {
            @apply flex-col p-[64px_48px_48px];
        }

        .row {
            @media(max-width:991px) {
                @apply flex-col-reverse;
            }
        }

        .block-caption {
            @apply p-[48px_0_140px_0] relative z-[1];

            @media(max-width:991px) {
                @apply p-[30px_0_48px_0];
            }

            .animate-heading {
                @apply text-sol-white transition delay-[400ms];
            }

            .animate-text {
                @apply text-sol-white transition delay-[600ms];
            }

            .animate-btn {
                @apply text-sol-white transition delay-[800ms];
            }
        }

        .block-img {
            @apply overflow-hidden mb-[48px] h-full relative z-[1];

            @media(max-width:991px) {
                @apply max-h-[360px] mb-0;
            }
            
            img,
            video {
                @apply w-full h-full block;
            }

            video {
                @apply object-cover;
            }

            img {
                @apply lg:object-cover object-contain;
            }
        }

        .swiper-pagination {
            @apply absolute z-[2] text-sol-white top-[64px] left-[64px] h-auto w-auto;

            @media(max-width:991px) {
                @apply left-[48px] top-[32px];
            }
        }

        .swiper-nav {
            @apply desktop:h-[70px] h-[48px] desktop:w-[248px] w-[212px] gap-[16px] absolute bottom-[77px] left-[64px] flex items-center justify-between z-[1];

            @media(max-width:991px) {
                @apply w-full relative bottom-auto left-auto;
            }

            div {
                @apply flex items-center justify-center rounded-[60px] border border-white border-solid transition duration-200 ease-in-out desktop:w-[100px] w-[84px] desktop:h-[70px] h-[48px];

                &::after {
                    @apply text-sol-white text-[18px] font-bold;
                }

                &:hover {
                    @apply bg-sol-white;

                    &::after {
                        @apply text-sol-charcoal;
                    }
                }
            }
        }

        .swiper-slide {
            @apply opacity-0 #{!important};
            @apply transition-opacity duration-[400ms];

            &.swiper-slide-active {
                @apply opacity-100 #{!important};
            }

            .block-img img {
                @apply duration-[800ms] opacity-0 object-[60%] translate-x-[-100%];
            }

            .block-caption>* {
                @apply duration-[800ms] opacity-0 translate-y-[-30px];                
                transition-duration: 0.8s;
            }
        }

        .swiper-slide-active {
            .block-img img {
                @apply opacity-100 object-[50%] transform-none;

                &.image-contain {
                    @apply object-contain h-auto;
                }
            }

            .block-caption>* {
                @apply opacity-100 transform-none;
            }
        }

        .bg-img,
        .bg-video {
            @apply absolute left-0 top-0 object-cover w-full h-full block z-0;
        }
    }
}