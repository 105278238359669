.sai-stats {
    .wrapper {
        @media (min-width: 1024px) {
            background-image: url('/wp-content/uploads/grid-graphics-top-right.png');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 100%;
        }

        @media (max-width: 1023px) {
            border-radius: 12px;
            border: 1px solid rgba(208, 208, 208, 0.41);
            background: linear-gradient(108deg, #222 9.24%, #101010 100%);
        }
    }
    .block-caption {
        @apply p-[3.5rem_2rem] shadow-[0px_10px_30px_0px_#0000001A] rounded-[0.75rem] m-[1px];

        .heading {
            @apply text-center max-w-[820px] ml-auto mr-auto;
        }

        .block-stats {
            @apply grid justify-between m-0 p-0 gap-[75px_0] list-none;

            @media(max-width:767px) {
                @apply flex-col gap-[20px] justify-center flex;
            }

            li {
                @apply px-[48px] relative h-auto flex-[25%] after:content-[''];

                @media (min-width:1024px) {
                    &::after {
                        // background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,116,232,1) 50%, rgba(255,255,255,0) 100%);
                        @apply h-full w-[1px] absolute right-0 top-0 max-lg:hidden;
                    }
                }

                &:last-of-type::after {
                    @apply hidden;
                }
    
                @media(max-width: 991px) {
                    @apply px-[16px];
                }

                @media(max-width:767px) {
                    @apply p-0 text-center;

                    .text-paragraph {
                        @apply mb-[25px];
                    }

                    &::after {
                        content: "";
                        //background: linear-gradient(270deg, rgba(0, 116, 232, 0.00) 0%, #0074E8 50.5%, rgba(0, 116, 232, 0.00) 100%);
                        @apply absolute bottom-0 left-0 w-full h-px;
                    }
                }

                p {
                    @apply dark:text-sol-white text-center;
                }

                .stats-value {
                    // background: linear-gradient(to right, #0074E8, #A933FB);                    
                    // -webkit-text-fill-color: transparent;
                    @apply text-[56px] tracking-[-1px] leading-[1.11] font-normal bg-clip-text mb-0 dark:text-sol-white;
                    font-weight: 600;
                    line-height: 120%;
                    
                    @media(max-width:991px) {
                        @apply text-[52px] leading-[62px];
                    }

                    .unit {
                        @apply text-[52px];

                        @media(max-width:991px) {
                            @apply text-[40px];
                        }
                    }
                }                
            }

            .transition-count {
                background: linear-gradient(92deg, #80B9F3 26.6%, #B247FB 74.17%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }
        }
    }

    &.bg-white-2 {
        .wrapper {
            padding-bottom: 50px;

            @media (min-width: 1024px) {
                background-image: url('/wp-content/uploads/grid-graphics-top-right-light.png');
                background-repeat: no-repeat;
                background-position: top left;
                background-size: cover;
                border-radius: 12px;
            }
    
            @media (max-width: 1023px) {
                border-radius: 12px;
                border: 1px solid rgba(208, 208, 208, 0.41);
                background: linear-gradient(107deg, rgba(255, 255, 255, 0.79) 9.26%, #FFF 117.15%);
            }

            .block-caption {
                box-shadow: none;
            }

            .transition-count {
                background: linear-gradient(90deg, #0074E8 0%, #A933FB 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                font-weight: 600;
            }

            .btn-outline--blue span {
                background: linear-gradient(92deg, #0074E8 0%, #250144 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        
            .btn-outline--blue:hover span {
                background: linear-gradient(92deg, #ffffff 0%, #ffffff 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .unmute-color-band img {
            filter: invert(0) !important;
        }

        .swiper {
            .swiper-wrapper {
                @apply ease-linear items-center;
    
                .swiper-slide {
                    @apply flex items-center justify-center;
    
                    img {
                        @apply h-auto;
                        filter: grayscale(100%) brightness(0);
    
                        @media (max-width: 768px) {
                            @apply max-h-[50px];
                        }
                    }
                }
            }
        }
    }
}