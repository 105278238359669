.block_AB005 {
    .block-caption {
        @apply md:pb-[80px] pb-[40px];
    }

    .block-card {
        @apply desktop:p-[48px] p-[32px] bg-sol-white dark:bg-sol-dark-gray h-full flex desktop:gap-[48px] gap-[32px] items-start shadow-[0px_6px_10px_0px_#00000020] max-[991px]:flex-col;

        .card-icon {
            @apply w-[64px] max-w-[64px] h-auto flex-[1_0_auto] max-[991px]:flex-[inherit];

            &.lottie {
                @apply max-w-fit;
            }
        }
    }

    &.bg-light-blue .block-card {
        @apply bg-sol-white;
    }
}