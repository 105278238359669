/*
* Buttons
*/

.btn {
    @apply border-none rounded-full font-semibold text-[16px] p-[15px_28px] mb-[14px] relative leading-none tracking-[.35px] inline-block text-center no-underline;

    @media (max-width: 767px) {
        @apply block text-center;
    }

    span {
        @apply font-semibold;
    }

    &:focus {
        @apply outline-none shadow-none;
    }

    &.btn-small {
        @apply p-[5px_18px] min-h-[31px] text-[1.4rem];
    }

    &.btn-primary {
        @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;

        &:hover,
        &.hover {
            @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }

        &.btn-inverse {
            @apply bg-sol-white text-sol-royal-blue border border-sol-white;

            &:hover,
            &.hover {
                @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
            }

            &:active,
            &:focus,
            &.active {
                @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
            }
        }
    }

    &.btn-white {
        @apply border-2 border-sol-white text-sol-white bg-transparent;

        &:hover,
        &.hover {
            @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }

        &.btn-solid {
            @apply bg-sol-white border-sol-white text-sol-dark-primary2;

            &:hover,
            &.hover {
                @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
            }
        }
    }

    &.btn-secondary {
        @apply border-2 border-sol-royal-blue text-sol-royal-blue bg-transparent;

        &:hover,
        &.hover {
            @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }

        &.btn-inverse {
            @apply border-sol-royal-blue text-sol-white bg-transparent;

            &:hover,
            &.hover {
                @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
            }

            &:active,
            &:focus,
            &.active {
                @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
            }
        }
    }

    &.btn-gradient {
        @apply border-2 border-sol-royal-blue text-sol-white bg-transparent;

        &:hover,
        &.hover {
            @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }

        &.btn-inverse {
            @apply border-sol-royal-blue text-sol-white bg-transparent;

            &:hover,
            &.hover {
                @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
            }

            &:active,
            &:focus,
            &.active {
                @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
            }
        }
    }

    &.btn-outline {
        @apply border-2 text-sol-dark-primary border-sol-dark-primary bg-transparent;

        &--blue {
            @apply border-2 border-sol-royal-blue text-sol-royal-blue;

            &:hover,
            &.hover {
                @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
            }

            &:active,
            &:focus,
            &.active {
                @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
            }
        }

        &:hover,
        &.hover {
            @apply text-sol-white bg-sol-dark-primary2 border-sol-dark-primary2;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }
    }

    &.btn-tag {
        @apply p-[5px_18px] min-w-[100px] min-h-[31px] text-[1.4rem] bg-sol-light-gray border-sol-light-gray text-sol-charcoal;

        &:hover,
        &.hover {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }

        &:active,
        &:focus,
        &.active {
            @apply bg-sol-royal-blue border-sol-royal-blue text-sol-white;
        }
    }

    &.btn-charcoal15 {
        @apply bg-sol-charcoal border-charcoal;

        &:hover,
        &.hover {
            @apply text-sol-charcoal bg-grey border-grey;
        }

        &:active,
        &:focus,
        &.active {
            @apply text-sol-charcoal bg-grey border-grey;
        }
    }
}

.baunfire-homepage {
    // Mixin for gradient border
    @mixin gradient-border($thickness: 1px) {
        content: "";
        position: absolute;
        inset: 0;
        padding: $thickness; // Border thickness
        background: linear-gradient(to right, #4496E9, #002BFF);
        border-radius: inherit;
        -webkit-mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        z-index: -1;
    }

    .btn-outline--blue,
    .btn-primary {
        position: relative;
        display: inline-block;
        z-index: 1;
        border: none !important;
    }

    .btn-outline--blue::before {
        @include gradient-border(1px);
    }

    .btn-outline--blue::after,
    .btn-primary::before {
        content: "";
        position: absolute;
        inset: 0;
        background: radial-gradient(123.36% 233.52% at 50% 140.91%, #0074E8 31%, #250144 100%);
        box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        border-radius: 24px;
        z-index: -1;
    }

    .btn-outline--blue:hover::after,
    .btn-primary:hover::before {
        opacity: 1;
    }
}
