.block_SL006 {
    @apply bg-sol-white;

    &.dark {
        @apply bg-sol-charcoal;

        .section__wrapper {
            .heading,
            .text-paragraph-large {
                @apply text-sol-white;
            }
        }
    }

    .section__wrapper {
        .heading,
        .text-paragraph-large {
            @apply text-sol-charcoal;
        }
    }

    .block-caption {
        @apply relative z-[1]
    }

    .swiper-benefits {
        @apply md:pt-[180px] pt-0 md:mt-[-100px] mt-[48px] md:pb-[20px] pb-[100px] relative;

        .swiper-nav {
            @apply desktop:h-[70px] h-[48px] desktop:w-[248px] w-[212px] gap-[16px] absolute right-[20px] top-0 flex items-center justify-between;

            @media(max-width:767px) {
                @apply left-[50%] bottom-[16px] right-auto translate-x-[-50%] top-auto;
            }

            div {
                @apply rounded-[60px] w-[100px] h-[70px] flex items-center justify-center border-[1px] border-solid border-sol-royal-blue duration-200 ease-in-out;

                @media(max-width:1199px) {
                    @apply w-[84px] h-[48px];
                }

                &::after {
                    @apply text-[18px] font-bold text-sol-royal-blue;
                }

                &:hover {
                    @apply bg-sol-royal-blue;

                    &::after {
                        @apply text-sol-white;
                    }
                }
            }
        }

        .swiper-slide {
            @apply h-auto;

            .block-card {
                box-shadow: 0px 6px 10px 0px #00000020;
                @apply desktop:p-[48px] p-[32px] h-full bg-sol-white;

                .card-icon {
                    @apply desktop:mb-[40px] mb-[20px] mb-[20px] h-[76px];

                    &.lottie {
                        @apply max-w-fit;
                    }
                }
            }
        }
    }
}