.search-filter-results {
	.block-item {
		@apply bg-[#F3F9FE] rounded-[12px];

		&:hover {
			@apply mb-[16px] mt-[-16px] bg-sol-white shadow-[0px_4px_20px_0px_rgba(0,0,0,0.15)];					

			a,
			.heading,
			.date {
				@apply text-[#0074E8];
			}
		}
	}

	.block-grid {
		@apply gap-y-[32px];

		.block-item:first-child {
			@apply bg-sol-light-blue2 #{!important};
			@apply rounded-[12px];
		}

		.block-item:nth-child(2) {
			@media(min-width:992px) {
				grid-column: span 1 !important;
			}
		}

		.block-item a:not(.btn):not(.husl-btn) .resource-caption .heading {
			@apply font-semibold;
		}
	}
}

.block_FR002 {		
	&.cbg-white {
		@apply bg-sol-charcoal;

		.heading-default {
			@apply text-sol-white;
		}

		.block-grid {
			.block-item:nth-child(2) {
				@apply bg-sol-white;
			}
		}
	}

	&.bg-light-blue .block-grid .block-item {
		@apply bg-sol-white;
	}

	&.cbg-dark {
		@apply bg-sol-white;

		.heading-2 {
			@apply text-sol-charcoal;
		}

		.block-grid {
			.block-item {
				@apply bg-sol-charcoal;
				&:nth-child(2) {
					@apply bg-sol-charcoal;
				}

				a:not(.btn):not(.husl-btn) {
					@apply text-sol-white;

					.resource-label img,
					.resource-bottom:after {
						filter: grayscale(1) brightness(2);
					}

					.resource-label img {						
						opacity: 1;
						mix-blend-mode: plus-lighter;
					}
				}
			}
		}		
	}
	
	.block-grid {
		@apply m-0 p-0 grid min-[992px]:grid-cols-3 grid-cols-1 gap-x-[20px] gap-y-[32px];		

		&.no-title {			
			&:first-child {
				@apply bg-sol-light-blue2 p-[32px] #{!important};
				@apply rounded-[12px];
			}

			&:nth-child(2) {
				@apply min-[992px]:grid-cols-1;
			}
		}

		.block-caption {
			@apply max-[991px]:mb-[48px];			

			.btn {
				@apply inline-flex text-sol-white;
			}
		}

		.block-item {
			@apply bg-sol-light-blue2 rounded-[12px];

			a:not(.btn):not(.husl-btn) {
				@apply flex flex-col no-underline md:gap-8 gap-[24px] transition-all duration-200 p-8 h-full text-[initial];

				.resource-label {
					@apply flex justify-between items-center gap-[32px] flex-row;

					span {
						@apply text-[14px] font-semibold uppercase leading-[18px] tracking-widest;
					}

					img {
						@apply max-h-[32px] max-[991px]:max-w-[150px] max-[480px]:max-w-[110px] max-w-[110px] opacity-50 mix-blend-multiply w-auto h-auto grayscale-[1];
					}
				}

				.resource-caption {
					@apply flex-[67%] relative;

					.heading {
						@apply md:mb-[48px] mb-[20px] font-semibold;
					}
				}

				.resource-bottom {
					@apply relative;

					&::after {
						@apply content-[''] w-[26px] h-[15px] absolute bottom-0 right-0 bg-contain bg-no-repeat;
						background-image: url("data:image/svg+xml,%3Csvg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.913 0.999023L25 6.99902M25 6.99902L18.913 12.999M25 6.99902L1 6.99902' stroke='%230074E8' stroke-linecap='square'/%3E%3C/svg%3E");
						
					}
				}

				&:hover {
					.resource-caption,
					.resource-bottom {
						@apply text-[#0074E8];
					}

					.resource-bottom::after {
						filter: invert(45%) sepia(81%) saturate(6414%) hue-rotate(197deg) brightness(95%) contrast(105%);					
					}
				}
			}

			&:first-child {
				@apply bg-transparent p-0;
			}

			&:nth-child(2) {
				@media(min-width:992px) {
					grid-column: span 2;
				}

				&:hover {
					@apply shadow-[3px_3px_10px_rgba(0,0,0,.15)];
					.resource-caption,
					.resource-bottom {
						@apply text-[#0074E8];
					}
				}
			}
		}
	}
}