.block_VID001 {
    @apply pb-[120px];

    .block-caption {
        @apply max-w-[870px] ml-auto mr-auto;
    }

    .block-video {
        @apply overflow-hidden relative max-w-[1070px] m-auto;

        .icon-play {
            @apply w-full h-full z-[1] absolute flex items-center justify-center;

            svg {
                @apply md:w-[130px] w-[64px] md:h-[130px] h-[64px];
            }
        }

        .video {
            @apply object-cover w-full h-full block z-0;
        }

        &.block-video--oembed,
        &.block-video--other {
            aspect-ratio: 16/9;

            iframe {
                @apply h-full w-full;
            }
        }
    }
}