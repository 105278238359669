.block_TXT004 {
    @apply py-[120px];

    .block-caption {
        @apply overflow-hidden relative flex items-center justify-center w-full bg-sol-charcoal;

        &>* {
            @apply text-sol-white;
        }

        .caption-inner {
            @apply relative z-[1] max-w-[860px] p-[120px_48px] flex gap-[48px] items-start;

            @media (max-width: 767px) {
                @apply p-16 gap-8;
            }

            @media(max-width:576px) {
                @apply flex-col p-[96px_32px_48px];
            }

            .quote {
                @apply text-[96px] flex-[40px] leading-[1];

                @media(max-width:767px) {
                    @apply flex-[32px] text-[72px];
                }

                @media(max-width:576px) {
                    @apply absolute left-[32px] top-[48px];
                }
            }

            .blockquote {
                @apply flex flex-col gap-[48px] mb-[1rem];

                @media(max-width:767px) {
                    @apply gap-[32px];
                }

                .blockquote-foot {
                    @apply flex items-center justify-between gap-[48px];

                    @media(max-width:767px) {
                        @apply gap-[32px];
                    }

                    @media(max-width:576px) {
                        @apply flex-col gap-[16px] items-start;
                    }

                    .text-paragraph {
                        @apply text-sol-white;

                        strong {
                            @apply block;
                        }
                    }

                    .brand {
                        @apply h-[32px];
                    }
                }
            }
        }

        .bg-img,
        .bg-video {
            @apply absolute left-0 top-0 object-cover w-full h-full block z-0;
        }
    }
}