.block_AB016 {    
    &.center-pos {
        .block-caption,
        .text-paragraph-col {
            @apply mx-auto text-center;
        }

        .nav-wrapper {
            @apply items-center flex flex-wrap justify-center;            
        }
    }

    &.bg-default {
        @apply bg-sol-charcoal;

        .heading,
        .text-paragraph-col p {           
            @apply text-sol-white;            
        }
    }

    .block-caption{
        @apply mb-[24px];        
    }

    .card_wrapper {
        &__inner {
            &.small,
            &.large {
                &:nth-of-type(2n+1) > div {
                    @apply lg:pr-[20px] pb-[20px];
                }
                &:nth-of-type(4) > div {
                    @apply lg:pr-[20px] pb-[20px];
                }
                &:nth-of-type(5) > div {
                    @apply lg:pr-0 pb-[20px];
                }
                > div {
                    @apply lg:pl-[0px] pb-[20px];
                }
            }

            &.large .card_wrapper__asset {
                @apply max-h-[265px];
            }
            
            &.small .card_wrapper__asset {
                @apply max-h-[208px];
            }
        }
    }
}