.block_TXT007 {
    .block-title {
        @apply max-w-[820px];
    }

    .block-list {
        @apply flex justify-center flex-wrap gap-5;

        @media (max-width: 576px) {
            @apply flex-col gap-[20px];
        }

        .block-card {
            box-shadow: 0px 6px 10px 0px #00000020;
            width: calc(25% - 20px);
            @apply flex flex-col h-auto bg-sol-white;

            @media(max-width:991px) {
                width: calc(50% - 20px);
            }

            @media(max-width:576px) {
                @apply w-full;
            }

            img {
                @apply w-full max-h-[350px] object-cover block h-auto;

                @media(max-width:991px) {
                    @apply max-h-[450px];
                }

                @media(max-width:767px) {
                    @apply max-h-[350px];
                }
            }

            .block-caption {
                @apply flex flex-col p-[32px];
            }
        }
    }
}