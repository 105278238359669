.block_HR006 {
    @apply relative overflow-y-visible;

    .block-caption {
        @apply flex items-start flex-col lg:pr-[80px];

        @media(max-width:767px) {
            max-width: inherit;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply mb-0;
        }

        .sub-heading {
            @apply flex flex-col gap-[20px];

            p {
                @apply mb-0;
            }
        }
    }

    &.flip-columns .block-caption {
        @apply lg:pr-0 lg:pl-[80px];

    }

    &.block-padding {
        &-top {
            @apply lg:pt-[120px] pt-[60px];
        }

        &-bottom {
            @apply lg:pb-[120px] pb-[30px];
        }
    }

    .block-border {
        @apply border-l-0 border-r-0 border-t-0 border-b-0;

        &-top {
            @apply border-t-[1px] border-solid border-t-[#cacfd4] lg:pt-[85px] pt-[60px];
        }

        &-bottom {
            @apply border-b-[1px] border-b-[#cacfd4] lg:pb-[85px] pb-[60px];
        }        
    }

    .block-media {
        @apply rounded-[16px] w-full h-full overflow-hidden relative;

        .bg-img,
        .bg-video {
            @apply object-cover h-full w-full z-0 block;
        }

        .icon-play {
            transform: translate(-50%, -50%);
            @apply absolute left-1/2 top-1/2 w-[90px] h-[90px] rounded-full bg-sol-royal-blue flex items-center justify-center cursor-pointer transition-all ease-in-out duration-200;            

            svg {
                @apply translate-x-[3px];
            }

            @media(min-width: 768px) {
                @apply w-[131px] h-[131px];
            }
        }
    }

    &.gradient::after {
        @apply content-[''] blur-[212px] absolute top-0 right-0 opacity-[0.34] h-[600px] w-[600px] z-0 rounded-[619.776px] bg-sol-royal-blue hidden lg:block;
    }

    .block-well-content {
        @apply mt-[40px] relative;

        .block-well {
            @apply flex items-start gap-[70px] p-[40px] rounded-[12px] overflow-hidden relative;
            background: linear-gradient(94.8deg, rgba(243, 249, 254, 1) 0%, rgba(252, 252, 252, 1) 99.67%);
            box-shadow: 0px 10px 30px 0px #00000047;

            @media(max-width:1199px) {
                @apply gap-[40px];
            }

            @media(max-width:991px) {
                @apply flex-col gap-[30px] p-[30px];
            }

            &::after {
                content: "";
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background-color: rgba(81, 81, 81, .33);

                @apply absolute w-full h-full left-0 top-0 z-[-1];
            }

            .block-media {
                @apply hidden;

                .block-img,
                .block-video {
                    @apply w-[480px] block rounded-[4px] h-[290px] relative overflow-hidden;

                    @media(max-width:1024px) {
                        @apply w-[420px];
                    }

                    @media(max-width:991px) {
                        width: 100%;
                    }

                    .icon-play {
                        transform: translate(-50%, -50%);
                        @apply absolute z-[1] top-1/2 left-1/2 w-[80px] h-[80px];

                        svg {
                            @apply w-full;
                            height: auto;
                        }
                    }

                    img {
                        @apply rounded-[15px] h-full object-cover w-full;
                    }
                }
            }

            .block-text {
                @apply flex gap-[70px] items-start w-full;

                @media(max-width:991px) {
                    @apply flex-col gap-[30px];
                }

                .heading,
                .text-paragraph {
                    @apply text-sol-white;
                }

                .block-title {
                    @apply max-w-[480px];
                }

                .cta-group {
                    @apply flex gap-[20px];
                    flex-flow: row wrap;

                    .btn {
                        @apply m-0;
                    }
                }

                .block-description {
                    @apply flex flex-col gap-[20px];
                }
            }

            &.with-media {
                @apply items-center;

                @media(max-width:991px) {
                    @apply items-start;
                }

                .block-media {
                    @apply block w-full;
                }

                .block-text {
                    @apply flex flex-col gap-[20px];

                    .block-title {
                        max-width: inherit;
                    }
                }
            }

            @media(max-width:768px) {
                @apply gap-[30px] p-[16px] flex-col;
            }
        }

        &::after {
            content: "";
            top: auto;
            @apply h-[60%] block absolute w-full bottom-0 z-[-1]
        }

        &.bg-dark {
            @apply bg-transparent #{!important};

            &::after {
                @apply bg-sol-charcoal;
            }
        }

        &.bg-light-blue {
            @apply bg-transparent #{!important};

            &::after {
                @apply bg-sol-light-blue2;
            }
        }

    }

    small b {
        background: linear-gradient(to right, $ColorPrimary, $ColorPurple);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
    }

    &.campaign-mode-1,
    &.campaign-mode-2 {
        .heading {
            @media(min-width:1440px) {
                @apply max-w-[75%];
            }
        }
    }

    &.campaign-mode-1 {
        @apply mb-[130px];

        @media(max-width:767px) {
            @apply pt-[150px];
        }

        .block-well-content {
            height: unset !important;
            @apply mb-[-120px];

            .block-well .block-text {
                .heading,
                .text-paragraph {
                    @apply bg-transparent text-sol-charcoal;
                }
            }
        }
    }

    &.campaign-mode-2 {
        @apply mb-[225px];

        @media(max-width:767px) {
            @apply pt-[150px] mb-[165px];
        }

        .block-well-content {
            height: unset !important;
            @apply mb-[-245px];

            .block-well .block-text {
                .heading,
                .text-paragraph {
                    @apply text-sol-charcoal;
                }
            }
        }

        &.bottom-dark .block-well-content::after {
            @apply bg-sol-charcoal;
        }

        &.bottom-light .block-well-content::after {
            @apply bg-sol-white;                            
        }
    }
}

.embed-video {
    >iframe {
        @apply w-full h-full;
    }
}