.block_SAI-Carousel {
    &.center-pos {
        .block-caption,
        .text-paragraph-col {
            @apply mx-auto text-center;
        }
        .nav-wrapper {
            @apply items-center flex flex-wrap justify-center;            
        }
    }

    &.bg-default {
        @apply bg-sol-charcoal;

        .heading,
        .text-paragraph-col p {           
            @apply text-sol-white;            
        }
        
        .tab-content ul li,
        .tab-content ol li,
        .tab-content p {
            @apply text-sol-charcoal;
        }
        
        .nav-pills {
            @apply bg-sol-white;

            .nav-link {
                @apply text-sol-charcoal;
            }
        }

        .tab-pane .tap-mob-heading a {   
            @apply text-sol-charcoal;
        }

        .mob-tabpane-toggler svg path {                
            @apply stroke-sol-white;
        }
        
        .tab-pane.active  .tap-mob-heading a {   
            @apply text-[#F3F9FE];
        }
    }

    &.dark {
        * {
            @apply text-sol-white;
        }
    }

    &.bg-white,
    &.dark {
        .line {
            &.green-gradient {
                background: linear-gradient(to right, rgba(39, 233, 195, 0) 0%, #27E9c3 50%, #27E9c3 50%, rgba(39, 233, 195, 0) 100%);
            }

            &.lightblue-gradient {                
                background: linear-gradient(to right, rgba(30, 172, 252, 0.20) 0%, #1EACFC 50%, #1EACFC 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.blue-gradient {
                background: linear-gradient(to right, rgba(0, 116, 232, 0) 0%, #0074e8 50%, #0074e8 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.yellow-gradient {
                background: linear-gradient(to right, rgba(255, 183, 0, 0) 0%, #ffb600 50%, #ffb600 50%, rgba(255, 183, 0, 0) 100%);
            }

            &.purple-gradient {
                background: linear-gradient(to right, rgba(169, 51, 251, 0) 0%, #a933fb 50%, #a933fb 50%, rgba(169, 51, 251, 0) 100%);
            }
            
            &.pink-gradient {
                background: linear-gradient(to right, rgba(251, 36, 169, 0) 0%, #fb24a9 50%, #fb24a9 50%, rgba(251, 36, 169, 0) 100%);
            }
        }

        .nav-link:hover .line {
            bottom: 10px;
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;

            &.green-gradient {
                background: linear-gradient(to right, rgba(39, 233, 195, 0) 0%, #27E9c3 50%, #27E9c3 50%, rgba(39, 233, 195, 0) 100%);
            }

            &.lightblue-gradient {                
                background: linear-gradient(to right, rgba(30, 172, 252, 0.20) 0%, #1EACFC 50%, #1EACFC 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.blue-gradient {
                background: linear-gradient(to right, rgba(0, 116, 232, 0) 0%, #0074e8 50%, #0074e8 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.yellow-gradient {
                background: linear-gradient(to right, rgba(255, 183, 0, 0) 0%, #ffb600 50%, #ffb600 50%, rgba(255, 183, 0, 0) 100%);
            }

            &.purple-gradient {
                background: linear-gradient(to right, rgba(169, 51, 251, 0) 0%, #a933fb 50%, #a933fb 50%, rgba(169, 51, 251, 0) 100%);
            }
            
            &.pink-gradient {
                background: linear-gradient(to right, rgba(251, 36, 169, 0) 0%, #fb24a9 50%, #fb24a9 50%, rgba(251, 36, 169, 0) 100%);
            }
        }
    }

    small b {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #0074e8, #a933fb);
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }

    .dropdownDefaultButton {
        @apply text-sol-charcoal rounded-[12px] text-center flex items-center justify-between border-0 w-full px-[20px] pb-[15px] pt-[25px];
        
        @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {         
            @apply px-0;
        }
    }

    .dropdownList {
        @apply z-10 bg-sol-white rounded-[12px] shadow w-full mt-[-5px];

        .nav-link {
            @apply text-[14px] border-none #{!important};
        }
    }

    .tap-mob-heading {
        @apply hidden max-[768px]:block;
    }     

    .nav-wrapper {
        @apply max-[768px]:hidden #{!important};
    }

    .tab-pane {         
        .tap-mob-heading {
            @apply max-[768px]:py-[20px] max-[768px]:pt-0 max-[768px]:pb-[10px] max-[768px]:mb-[20px] max-[768px]:px-0 max-[768px]:border-b-[1px] max-[768px]:border-b-[#cacfd4] max-[768px]:border-solid max-[768px]:border-x-0 max-[768px]:border-t-0;                            

            a {     
                @apply max-[768px]:text-[16px] max-[768px]:tracking-[0.32px] max-[768px]:text-sol-charcoal max-[768px]:font-bold;            
            }
        }

        .mob-tabpane-toggler {
            @apply max-[768px]:items-center max-[768px]:flex max-[768px]:justify-between max-[768px]:w-full;                
        }
        
        .tabpane-inner-content {
            @apply max-[768px]:hidden;
            
            .button-block {
                @apply flex lg:mt-[60px] mt-[40px];
            }
    
            .solution-links {
                &__title {
                    @apply text-[16px] font-bold lg:mt-[32px] mt-[22px] text-sol-charcoal;
                }
    
                &__content {
                    @apply inline-flex flex-wrap lg:gap-[25px] gap-[15px] mt-[10px] text-[14px] font-bold;
    
                    a {
                        @apply text-sol-royal-blue;
                    }
                }
            }
    
            .bg-img {
                @apply object-cover w-full rounded-[12px];
            }

            h1,h2,h3,h4,h5,h6 {
                @apply mb-[15px];
            }
        }

        &.active {
            .mob-tabpane-toggler svg {    
                @apply max-[768px]:rotate-[180deg];
            }
    
            .tap-mob-heading a {   
                @apply max-[768px]:text-sol-charcoal;
            }
    
            .tabpane-inner-content {
                @apply max-[768px]:block;
    
                .row {
                    @apply max-[768px]:flex-col max-[768px]:gap-[20px];
                }
            }
        }
    }

    .block-caption {
        @apply mb-[5px];        
    }

    .tab__wrapper {   
        @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            @apply px-[24px];
        }

        .desktop {
            @apply lg:block hidden;

            // @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            //     @apply hidden #{!important};
            // }
        }

        .mobile {
            @apply lg:hidden block;

            // @media all and (device-width: 1024px) and (device-height: 1366px) and (orientation:portrait) {                
            //     @apply block #{!important};
            // }
        }
    }

    .nav-pills {
        @apply relative inline-flex lg:items-center items-end p-[4px] flex-nowrap w-full mb-[.75rem] mt-0 lg:whitespace-nowrap whitespace-normal;          
        
        .nav-link.active, 
        .show>.nav-link {
            @apply text-sol-charcoal font-bold bg-transparent;            
        }

        .nav-link {
            @apply relative text-sol-charcoal font-bold text-[16px] lg:max-lg:px-[14px] lg:px-[24px] px-[20px] pt-[12px] lg:pb-[24px] pb-[12px] text-center border-0 bg-transparent;  

            @media (max-width: 1160px) {
                @apply px-[12px];
                
            }
                
            span {             
                &::before { 
                    @apply content-[''] w-full h-[1px] absolute lg:bottom-[10px] bottom-[-2px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                }
                div {
                    @apply relative;

                    &::before { 
                        visibility: hidden;
                        @apply content-[''] w-full h-[3px] absolute bottom-[-15px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                    }
                }
            }
            &.active span { 
                &::before {
                    visibility: visible;
                    @apply h-auto bg-opacity-100;             
                }

                &.green::before {
                    @apply bg-sol-green;
                }
    
                &.pink::before {
                    @apply bg-sol-pink;
                }
    
                &.purple::before {
                    @apply bg-sol-light-purple;                    
                }
                
                &.dark-purple::before {
                    @apply bg-sol-dark-purple;                    
                }
    
                &.yellow::before {
                    @apply bg-sol-yellow;
                }
    
                &.blue::before {
                    @apply bg-sol-royal-blue;
                }
    
                &.light-blue::before {
                    @apply bg-sol-light-blue;
                }
    
                &.orange::before {
                    @apply bg-sol-orange;
                }
            }                                   
        }
    }

    .nav-pills::before {
        @apply content-[''] absolute bottom-[14px] left-0 w-full h-[2px] bg-[rgba(255,255,255,0.20)];
    }

    .tab-content {        
        @apply lg:pb-0 lg:pt-[26px] py-[26px] lg:px-0 md:px-[30px] px-[20px];

        .tab-pane {
            @apply relative hidden;
            
            &.show {
                @apply block;
            }
        }

        ul {
            @apply ml-[30px];

            li {        
                @apply text-[16px] leading-[32px] list-disc mb-[15px];                       
            }
        }
    }
}

.block_SAI-Carousel .object-contain {
    -o-object-fit: contain;
    object-fit: contain !important;
}

.block_SAI-Carousel {
    .sai-carousel {
        @apply w-full relative overflow-hidden min-h-[600px];
    }

    .swiper-wrapper {
        @apply flex items-center transition-transform duration-200 ease-in-out;
    }

    .swiper-slide {
        @apply cursor-grab transition-all duration-200 ease-in-out opacity-20 w-full max-w-[1250px];

        .h1 {
            @apply bg-clip-text text-transparent tracking-tight mr-4;
            background-image: linear-gradient(90deg, #00EAC3, #0074E8);

            &.financial-h1 {
                background-image: linear-gradient(90deg, #4169E1, #87CEFA);
            }

            &.industrial-h1 {
                background-image: linear-gradient(90deg, #FF8500, #FFB600);
            }

            &.enterprise-h1 {
                background-image: linear-gradient(90deg, #80B9F3, #B247FB);
            }

            &.media-h1 {
                background-image: linear-gradient(90deg, #FB24A9, #FFB600);
            }
        }

        .carousel-image-card {
            @apply rounded-[12px] p-5;
            background-image: linear-gradient(to top right, #80B9F3, #CB96F6);
        }
    }

    .sai-carousel-light {
        @apply overflow-hidden;

        .swiper-slide {
            .carousel-card {
                border-radius: 12px;
                border: 1px solid rgba(236, 236, 236, 0.80);
                padding: 5rem;

                &.green-gradient {
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #27E9c3 180%);
                }

                &.lightblue-gradient {                    
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #1EACFC 180%);
                }
    
                &.blue-gradient {
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #0074e8 180%);
                }
    
                &.yellow-gradient {
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #ffb600 180%);
                }
    
                &.purple-gradient {
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #a933fb 180%);
                }
                
                &.pink-gradient {
                    background: linear-gradient(180deg, rgba(253, 253, 253, 0.5) 77%, #fb24a9 180%);
                }
            }

            .carousel-image-card {
                background-image: none;
                padding: 0;
            }
        }
    }

    .bg-white {
        .nav-link {
            &.active span { 
                &::before {
                    opacity: 0.2;
                    height: 1px;          
                }
            }
        }
    }

    .swiper-slide-active, .swiper-slide.active-slide {
        @apply opacity-100 transition-opacity duration-200;
    }

    .swiper-slide-active {
        opacity: 1 !important;
    }

    .swiper-slide-active .slide-inner {
        transform: translate3d(0px, 0%, 0px) !important;
    }

    .nav-pills .nav-link {
        @apply cursor-pointer transition-all duration-200;
    }

    .nav-pills .nav-link.active {
        @apply font-bold text-sol-charcoal;
    }
}

.sai-mobile-accordion {
    @apply w-full flex flex-col gap-4;

    .accordion-item {
        @apply border-b;

        &:first-of-type .accordion-button {
            @apply pt-4;
            border-top: 1px solid rgba(255, 255, 255, 0.20);
        }
    }

    .accordion-item {
        @apply border-b;
      }
      

    .accordion-header {
        @apply flex justify-between items-center px-6 cursor-pointer rounded-md transition-all duration-300;
    }

    .accordion-button {
        @apply flex items-center justify-between w-full text-left font-medium transition-all duration-300 pb-4;
        border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    }

    .accordion-content {
        @apply overflow-hidden transition-[max-height] duration-500 ease-in-out max-h-0;
    }

    .accordion-content.open {
        @apply max-h-[1000px];
    }

    .accordion-body {
        @apply pt-10 px-6;

        .h1 {
            @apply bg-clip-text text-transparent tracking-tight mr-4;
            background-image: linear-gradient(90deg, #00EAC3, #0074E8);

            &.financial-h1 {
                background-image: linear-gradient(90deg, #4169E1, #87CEFA);
            }

            &.industrial-h1 {
                background-image: linear-gradient(90deg, #FF8500, #FFB600);
            }

            &.enterprise-h1 {
                background-image: linear-gradient(90deg, #80B9F3, #B247FB);
            }

            &.media-h1 {
                background-image: linear-gradient(90deg, #FB24A9, #FFB600);
            }
        }

        .button-block {
            @apply pt-4;
        }

        .carousel-image-card {
            @apply rounded-[12px] p-5 mt-4;
            background-image: linear-gradient(to top right, #80B9F3, #CB96F6);
        }
    }

    .accordion-icon {
        @apply transition-transform duration-300 rotate-0;
    }

    .accordion-icon.open {
        @apply rotate-180;
    }
}

.bg-white {
    .sai-mobile-accordion {
        .accordion-item {
            @apply border-b;
    
            &:first-of-type .accordion-button {
                @apply pt-4;
                border-top: 1px solid rgba(0,0,0,.12);
            }
        }

        .accordion-button {
            @apply flex items-center justify-between w-full text-left font-medium transition-all duration-300 pb-4;
            border-bottom: 1px solid rgba(0,0,0,.12);
        }
    }
}