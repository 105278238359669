body.baunfire-homepage {
    &.home-backlog-modules,
    &.home {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply font-sans font-semibold;

            span.highlight {
                @apply bg-transparent;
            }
        }

        p {
            @apply text-[16px] leading-[25.2px] font-sans font-normal;
        }

        .section,
        .section_new {
            @apply lg:px-0 lg:py-[120px] py-[80px];

            .container {
                @apply lg:px-[12px] px-[15px];
            }
        }

        .heading {
            @apply lg:mb-[24px] mb-[20px];

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                @apply text-sol-charcoal dark:text-sol-white;
            }
        }

        .block_HR006__inner .text-paragraph h3 {
            @apply lg:mb-[38px] mb-[20px] mt-[10px];
        }

        .block_LOG001 .heading {
            @apply lg:mb-[96px] mb-[40px];
        }
    }
}