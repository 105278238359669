.block_SL003 {
    @apply overflow-hidden;

    .container-fluid {
        @apply p-0 #{!important};

        .row {
            @media(max-width:767px) {
                @apply flex-col-reverse;
            }
        }

        .col-slider {
            flex: 0 0 auto;
            @apply desktop:w-[54%] md:w-1/2 w-full box-border desktop:basis-[54%] md:basis-[50%] basis-full desktop:max-w-[54%] md:max-w-[50%] max-w-full;
        }

        .col-caption {
            flex: 0 0 auto;
            @apply box-border desktop:w-[46%] md:w-1/2 w-full desktop:basis-[46%] md:basis-[50%] basis-full desktop:max-w-[46%] md:max-w-[50%] max-w-full;
        }
    }

    .block-caption {
        @apply relative z-[1] flex flex-col gap-[24px] desktop:pl-[96px] md:pl-[48px] px-[30px] h-full desktop:max-w-[400px] md:max-w-[350px] max-w-[inherit] justify-center;

        @media(max-width:767px) {
            @apply mb-[32px];
        }

        .swiper-nav {
            @apply desktop:h-[70px] h-[48px] desktop:w-[220px] md:w-[200px] w-[180px] gap-[16px] flex items-center justify-between;

            div {
                @apply rounded-[60px] desktop:w-[100px] w-[84px] desktop:h-[70px] h-[48px] flex items-center justify-center border-[1px] border-solid border-sol-royal-blue duration-200 ease-in-out;

                &::after {
                    font-variant: initial;
                    @apply normal-case #{!important};
                    @apply text-sol-royal-blue text-[18px] font-bold tracking-normal leading-[1];
                }

                &:hover {
                    @apply bg-sol-royal-blue;

                    &::after {
                        @apply text-sol-white;
                    }
                }

                &.swiper-button-next-testimonials::after {
                    content: 'next';     
                    font-family: swiper-icons;               
                }

                &.swiper-button-prev-testimonials::after {
                    content: 'prev';     
                    font-family: swiper-icons;                           
                }
            }
        }
    }

    .swiper .swiper-slide {
        @apply h-auto md:p-[24px] p-[15px];

        .block-card {
            box-shadow: 0px 6px 10px 0px #00000014;
            @apply bg-sol-white p-[32px] h-full gap-[32px] flex flex-col items-start;

            .card-icon {
                @apply max-h-[100px] rounded-full;
            }
        }
    }
}