.block_HR002 {
    @apply relative lg:h-[700px] bg-sol-charcoal;

    &::after {
        background: #000;
        background: linear-gradient(0deg,#000,transparent);
        @apply content-[''] z-[1] absolute bottom-0 left-0 w-full h-[50vh];
    }

    &.careers-hero {
        .bg-img {
            @media(max-width: 768px) {
                object-position: 65% 50%;
            }
        }
    }

    .container {
        @apply relative h-full flex justify-center flex-col z-[2];        
    }

    .block-caption {
        @apply flex flex-col items-start lg:gap-[30px] gap-[20px] lg:pb-[17px];     
        
        .eyebrow {
            @apply text-sol-white;
        }

        .heading {
            @apply text-sol-white lg:text-[64px] text-[40px] tracking-[-1.28px] lg:max-w-[610px] max-w-[inherit];           
        }

        .sub-heading,
        .text-paragraph-large {
            @apply text-sol-white lg:max-w-[505px];
        }

        p:last-child {
            @apply mb-0;
        }
    }

    .bg-img,
    .bg-video {
        @apply object-cover absolute bottom-0 left-0 h-full w-full z-0;
    }
    
}

.block_HR002 .block-well-content {
    margin-top: 40px;
    position: relative;
}

.block_HR002  .block-well-content .block-well .block-media .block-img img, .block_HR002  .block-well-content .block-well .block-media .block-video img {
    border-radius: 15px;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
}

@media screen and (min-width: 768px) {
    .block_HR002 .block-well-content {
        margin-top: -50px;
    }
}

@media screen and (max-width: 767px) {
    .block_HR002 .block-well-content {
        margin-top: 20px;
    }
    .block_HR002.campaign-mode-2,  .block_HR002.campaign-mode-1 {
    margin-bottom: 30% !important;
}

}

/* .block_HR002.campaign-mode-2,  .block_HR002.campaign-mode-1 {
    margin-bottom: 20% !important;
} */

.block_HR002 .block-well-content::after {
    content: '';
    height: 60%;
    display: block;
    position: absolute;
    background-color: transparent;
    width: 100%;
    bottom: 0;
    z-index: -1;
    top: auto;
}

.block_HR002 .block-well {
    background: linear-gradient(94.8deg, rgba(243, 249, 254, 1) 0%, rgba(252, 252, 252, 1) 99.67%);
    display: flex;
    align-items: flex-start;
    border-radius: 12px;
    gap: 70px;
    overflow: hidden;
    position: relative;
    padding: 40px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.28);
}

@media screen and (max-width: 1199px) {
    .block_HR002 .block-well {
        gap: 40px;
    }
}

@media screen and (max-width: 991px) {
    .block_HR002 .block-well {
        flex-direction: column;
        gap: 30px;
        padding: 30px;
    }
}

.block_HR002 .block-well::after {
    content: '';
    background-color: rgba(81, 81, 81, 0.33);
    filter: blur(10px);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
}

.block_HR002 .block-media {
    display: none;
}

.block_HR002 .block-media .block-img,
.block_HR002 .block-media .block-video {
    width: 480px;
    display: block;
    border-radius: 4px;
    max-height: 290px;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width: 1024px) {
    .block_HR002 .block-media .block-img,
    .block_HR002 .block-media .block-video {
        width: 420px;
    }
}

@media screen and (max-width: 991px) {
    .block_HR002 .block-media .block-img,
    .block_HR002 .block-media .block-video {
        width: 100%;
    }
}

.block_HR002 .block-media .block-img img {
    border-radius: 15px;
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.block_HR002 .block-text {
    display: flex;
    gap: 70px;
    align-items: flex-start;
}

@media screen and (max-width: 991px) {
    .block_HR002 .block-text {
        flex-direction: column;
        gap: 30px;
    }
}

.block_HR002 .block-text .block-title {
    max-width: 480px;
}

.block_HR002 .block-text .cta-group {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.block_HR002 .block-text .cta-group .btn {
    margin-bottom: 0;
}

.block_HR002 .block-text .block-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.block_HR002 .block-well.with-media {
    align-items: center;
}

@media screen and (max-width: 991px) {
    .block_HR002 .block-well.with-media {
        align-items: flex-start;
    }
}

.block_HR002 .block-well.with-media .block-media {
    display: block;
    width: 100%;
}

.block_HR002 .block-well.with-media .block-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.block_HR002 .block-well.with-media .block-text .block-title {
    max-width: inherit;
}

@media screen and (max-width: 768px) {
    .block_HR002 .block-well {
        gap: 30px;
        padding: 16px;
        flex-direction: column;
    }
}

.block_HR002.campaign-mode-1 {
    margin-bottom: 130px;
    padding-top: 100px !important;
}

.block_HR002.campaign-mode-1 .block-well-content {
    margin-bottom: -120px;
}

.block_HR002.campaign-mode-1 .block-well .block-text .heading,
.block_HR002.campaign-mode-1 .block-well .block-text .text-paragraph {
    color: #333333; /* Assuming sol-charcoal is dark gray */
}

.block_HR002.campaign-mode-2 {
    margin-bottom: 168px;
    padding-top: 200px !important;
}

.block_HR002.campaign-mode-2 .block-well-content {
    margin-bottom: -190px;
}

.block_HR002.campaign-mode-2 .block-well .block-text .heading,
.block_HR002.campaign-mode-2 .block-well .block-text .text-paragraph {
    color: #333333; /* Assuming sol-charcoal is dark gray */
}

.block_HR002.campaign-mode-2 .bg-video {
    min-height: 720px;
}

.block_HR002.campaign-mode-2.bottom-dark .block-well-content::after {
    background-color: #333333; /* Assuming sol-charcoal is dark gray */
}

.block_HR002.campaign-mode-2.bottom-light .block-well-content::after {
    background-color: #FFFFFF; /* Assuming sol-white is white */
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .block_HR002 .block-well-content {
        margin-top: 24px !important;
    }
}