.block_AB020 {
    .block-subscribe {
        .gform_wrapper {
            @apply flex lg:flex-row flex-col;

            form .gform-body {
                .gform_fields {
                    @apply lg:flex-row flex-col gap-[20px] flex-nowrap;

                    .gfield.gfield--width-half {
                        @apply lg:mb-[27px] max-md:max-w-full;
                    }
                }
                .gfield {
                    @apply lg:mb-[16px] mb-0;
                }
            }
        }

        .gform_heading {
            @apply lg:pr-[40px] lg:pb-0 pb-[24px];

            .gform_title {
                @apply text-[30px] mb-[16px] #{!important};
            }

            .gform_description {
                @apply mb-0;
            }
        }

        .gform_footer {
            @apply lg:flex lg:justify-end block lg:mt-0 mt-[27px];

            .btn {
                @apply lg:w-[295px] w-full;
            }
        }

        .gform_confirmation_messag h3 {
            @apply mb-0 text-[30px];            
        }
        
        .gform_validation_errors h2.gform_submission_error {
            @apply text-[14px];
        }

        .gform_required_legend {
            @apply hidden;
        }
        
        /* hubspot form*/
        .hbspt-form {
            .hs-form { 
                @apply flex lg:flex-row flex-col items-center gap-[10px];
            }
            .hs-error-msgs,
            .hs-email label,
            .legal-consent-container { 
                @apply hidden #{!important};
            }
            .hs-submit {
                @apply w-full #{!important};
            }
            .hs-email {
                //@apply lg:min-w-[290px] w-full #{!important};
                .input input {
                    @apply  bg-[var(--color-grey)] border border-solid border-transparent text-[var(--color-body)] text-[14px] h-[48px] py-[8px] px-[16px] w-full rounded-[3px] outline-none;

                    &.error {
                        @apply border border-solid border-sol-red;
                    }
                }
            }
            .hs-button {
                @apply bg-sol-royal-blue border border-solid border-sol-royal-blue text-sol-white py-[15px] px-[25px] text-[14px] font-bold w-[138px] rounded-[9999px] appearance-none text-center;
            }
            .submitted-message {
                @apply dark:text-sol-white text-charcoal;
            }
        }
    }
}