.block_SL004 {
    @apply overflow-hidden;

    .block-title {
        @apply md:mt-[50px] mt-0 mb-[30px];
        @apply md:mb-[120px] #{!important};        
    }

    .block-sticky-content {
        @apply flex h-[60vh] justify-around items-center w-full;
    }

    .panel-wrap {
        @apply flex justify-center items-center;
    }

    .point {
        @apply flex justify-center items-center h-[60vh] w-full p-[64px];
    }

    .panel-text {
        @apply w-full z-[10] lg:pr-[40px];

        .heading,
        .text-paragraph-large {
            @apply max-w-[505px];
        }

        ul {
            @apply ml-[20px];

            li {
                @apply list-disc;
            }
        }
    }

    .panel-img {
        @apply max-w-[570px] h-auto;
    }

    .panel-text-mobile .text-paragraph-large ul {
        @apply pl-[1em] list-[unset];

        li {
            @apply list-[unset];
        }
    }            
}