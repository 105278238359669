.rich-text.bf {
  h1 {
    @apply text-[30px] font-semibold leading-[120%] lg:text-[36px];
  }

  h2 {
    @apply text-[24px] font-semibold leading-[140%] lg:text-[28px];
  }

  h3 {
    @apply text-[20px] font-semibold leading-[120%] lg:text-[22px] lg:tracking-[0.44px] tracking-[0.4px];
  }

  h4,
  h5 {
    @apply text-[18px] font-semibold leading-[120%];
  }

  h6 {
    @apply text-[18px] font-semibold leading-[120%];
  }

  p:not(.text-lg):not(.lg\:text-2xl),
  li,
  a {
    @apply text-[18px] leading-[140%] font-normal;
  }

  ul li {
    @apply before:bg-[$ColorDarkGray2]    
  }

  ol {
    @apply pl-0;
  }
}

.husl-block.nylon-block.hero-block {
  @apply lg:pt-[260px] pt-[200px] mb-0;
}