.rw {
    @apply py-[50px];

    p {
        @apply mb-0 lg:max-w-full max-w-[200px] lg:text-left text-center;
        @apply font-bold #{!important};
        text-wrap: wrap;
    }

    &__circle-container {
        @apply relative lg:h-[500px] h-[350px] lg:w-[500px] w-[350px] ml-auto scale-x-[-1] translate-x-[50%];
    }

    &__circle {
        @apply relative lg:h-[500px] h-[350px] lg:w-[500px] w-[350px] rounded-[50%] shadow-[0px_-1px_15.3px_0px_rgba(0,_0,_0,_0.14)];        
    }

    &__circle-inner {
        @apply lg:h-[456px] h-[312px] lg:w-[456px] w-[312px] rounded-[50%] border-solid border border-[#0074e880] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
    }

    &__item {
        @apply absolute h-[1px] w-[1px];

        &.active {
            .rw {
                &__image {
                    @apply lg:h-[100px] h-[70px] lg:w-[120px] w-[90px];

                    img {
                        @apply object-contain w-full h-full;
                    }
                }

                &__content {
                    @apply opacity-100 transition-all duration-[0.3s] ease-in-out delay-200;
                }
            }
        }
    }

    &__item-inner {
        @apply absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%];
    }

    &__image {
        @apply lg:h-[50px] h-[30px] lg:w-[80px] w-[60px] scale-x-[-1] transition-all duration-[0.4s] ease-in-out;

        svg {
            @apply h-full w-full;
        }
    }

    &__content {
        @apply opacity-0 absolute min-w-fit whitespace-nowrap h-auto top-[50%] translate-y-[-50%] right-0 translate-x-[100%] flex gap-[12px] items-center scale-x-[-1] flex-row-reverse;
    }

    &__line {
        @apply relative h-[3px] lg:w-[102px] w-[52px] scale-x-[-1];

        &.green {
            @apply bg-sol-green;
        }

        &.pink {
            @apply bg-sol-pink;
        }

        &.purple {
            @apply bg-sol-light-purple;
        }

        &.dark-purple {
            @apply bg-sol-dark-purple;
        }

        &.yellow {
            @apply bg-sol-yellow;
        }

        &.blue {
            @apply bg-sol-royal-blue;
        }

        &.light-blue {
            @apply bg-sol-light-blue;
        }

        &.orange {
            @apply bg-sol-orange;
        }

        span {
            @apply absolute top-[50%] translate-y-[-50%] rounded-[50%] lg:h-[16px] h-[8px] lg:w-[16px] w-[8px] bg-[inherit] right-0;
        }
    }
}