.integration-entry {
    @apply dark:w-[185px] w-[256px] h-[185px] rounded-[12px] flex justify-center items-center overflow-hidden opacity-0 relative;    
    transition: opacity 1s ease-in-out; /* Smooth opacity transition */

    &::before {  /* Corrected nesting */        
        @apply rounded-[15px] content-[''] inset-0 p-[1px] absolute dark:bg-[linear-gradient(45deg,hsla(0,0%,82%,.41),#17191b)];
    }
  
    img {
        @apply w-full max-w-[100px] h-auto opacity-[1];
    }
  
    @media (max-width: 1200px) and (min-width: 993px) {
        @apply w-[150px] h-[150px];
    }

    @media (max-width: 992px) {
        @apply w-[120px] h-[120px] dark:w-[180px] dark:h-[180px];
    }
}

.sai-integration {
    background-position: bottom right;
    @apply bg-no-repeat bg-cover bg-[#FAFAFA];


    &.dark {
        @apply bg-[#121212] before:content-[''] before:bg-[url("../img/integration-grid.png")] before:absolute before:bottom-0 before:right-0;
        
        .integration-entry::before {
            mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
            mask-composite: exclude;
        }
    }
}

.integration-p {
    @apply max-h-[32rem];
}