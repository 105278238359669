.block_HR004 {
    @apply relative overflow-hidden;
    min-height: auto;

    @media(max-width:767px) {
        @apply pb-0 #{!important};
    }

    .container {
        @apply relative z-[3];
    }

    .block-caption {
        @apply flex flex-col gap-[12px];

        @media(max-width:767px) {
            @apply mb-[48px];
        }

        .text-paragraph-large {
            @apply mt-[14px];
        }

        .block-btn {
            @apply flex gap-[20px];
            flex-flow: row wrap;

            .btn {
                @apply m-0;
            }
        }
    }

    .bg-img {
        @apply object-cover object-center relative;

        &__wrapper {
            @apply flex overflow-hidden;
        }
    }

    .bg-video {
        @apply object-cover absolute bottom-0 left-0 h-full w-full z-[1];
    }

    .bg-gradient {
        @apply absolute w-1/2 h-full z-[0]  top-0 right-0;

        &::after {
            content: "";
            @apply absolute w-full h-[75%] bottom-[-30%] right-0 rounded-full blur-[30px] opacity-[0.6];
            background: rgb(163, 54, 251);
            background: radial-gradient(circle, rgba(163, 54, 251, 0.6) 0%, rgba(163, 54, 251, 0) 100%);
            filter: blur(30px);
        }

        &::before {
            content: "";
            @apply absolute w-[150%] h-[125%] top-[-50%] right-[-68%] rounded-full blur-[30px] opacity-[0.6];
            background: rgb(0, 116, 232);
            background: radial-gradient(circle, rgba(0, 116, 232, 0.6) 0%, rgba(0, 116, 232, 0) 100%);
            filter: blur(30px);
        }
    }
    
    .block-well-content{
        @apply mt-[80px] relative;

        @media(max-width:767px){
            @apply mt-[40px];
        }
        .block-well{
            @apply items-start rounded-[12px] flex gap-[70px] overflow-hidden relative p-[40px];
            
            background: linear-gradient(94.8deg, rgba(243, 249, 254, 1) 0%, rgba(252, 252, 252, 1) 99.67%);
            box-shadow: 0px 10px 30px 0px #00000047;
            @media(max-width:1199px){
                @apply gap-[40px];
            }
            @media(max-width:991px){
                @apply flex-col gap-[30px] p-[30px];
            }
            &::after{
                content:"";
                @apply absolute w-full h-full left-0 top-0 z-[-1];
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background-color: rgba(81,81,81,.33);

            }
            .block-media{
                @apply hidden;
                .block-img,
                .block-video{
                    @apply w-[480px] block rounded-[4px] max-h-[290px] relative overflow-hidden;
                    @media(max-width:1024px){
                        @apply w-[420px];
                    }
                    @media(max-width:991px){
                        @apply w-full;
                    }
                    .icon-play{
                        @apply absolute z-[1] top-1/2 left-1/2  w-[80px] h-[80px];

                        transform: translate(-50%, -50%);
                        svg{
                            @apply w-full;
                            height: auto;
                        }
                    }
                    img {
                        @apply rounded-[15px] h-[100%] object-cover w-full;
                    }
                }
            }
            .block-text{
                @apply flex gap-[70px] items-start;

                @media(max-width:991px){
                    @apply flex-col gap-[30px];
                }
                .heading,
                .text-paragraph{
                    @apply text-sol-white;
                }
                .block-title{
                    @apply max-w-[480px];
                }
                .cta-group{
                    @apply flex gap-[20px];
                    flex-flow: row wrap;
                    .btn{
                        @aaply m-0;
                    }
                }
                .block-description{
                    @apply flex flex-col gap-[20px];
                }
            }
            &.with-media{
                @apply items-center;
                @media(max-width:991px){
                    @apply items-start;
                }
                .block-media{
                    @apply block w-full;
                }
                .block-text{
                    @apply flex flex-col gap-[20px];
                    .block-title{
                        max-width: inherit;
                    }
                }
            }
            @media(max-width:768px){
                @apply gap-[30px] p-[16px] flex-col;
            }
        }
        &::after {
            content: "";
            top: auto;
            @apply h-[60%] block absolute bg-transparent w-full bottom-0 z-[-1];

        }
    }
    
    &.campaign-mode-1,    
    &.campaign-mode-2 {
        @apply bg-sol-light-blue2;
        .bg-gradient {
            @apply hidden;
        }
        @media(min-width:1440px) {
            .heading {
                @apply max-w-[75%];
            }
            .text-paragraph {   
                @apply max-w-[90%];
            }
        }
        .bg-img {
            @apply object-contain;
        }
    }

    &.campaign-mode-1 {
        @apply mb-[130px] h-auto overflow-visible;
        max-height: unset;

        .block-well-content {
            @apply mb-[-120px];

            .block-well .block-text {
                .heading, 
                .text-paragraph {
                    @apply text-sol-charcoal;
                }
            }
        }
    }
    
    &.campaign-mode-2{
        @apply mb-[230px] overflow-visible;
        height: unset;
        max-height: unset;
    }

    &.campaign-mode-2 {
        @apply mb-[230px] h-auto overflow-visible;
        max-height: unset;

        .block-well-content {
            @apply mb-[-250px];

            .block-well .block-text {
                .heading, 
                .text-paragraph {
                    color: #25282E;
                }
            }
        }

        .bg-video {
            min-height: 720px;
        }    

        &.bottom-dark{
            .block-well-content{
                &::after{
                    background-color: #25282E;
                }
            }
        }
        
        &.bottom-light{
            .block-well-content{
                &::after{
                    background-color: #fff;
                }
            }
        }
    }
}