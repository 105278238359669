.block_HR005 {
    @apply relative bg-sol-charcoal;

    .container {
        @apply relative z-[2] h-full flex justify-end flex-col;
    }

    .block-caption {
        @apply flex items-start gap-[50px] flex-col;

        @media(max-width:991px) {
            @apply gap-[32px];
        }

        @media(max-width:767px) {
            @apply flex-col items-start gap-[50px];
        }

        .heading {
            @apply max-w-[75%] text-sol-white;

            @media(max-width:767px) {
                max-width: inherit;
            }

            span {
                @apply bg-[#0074E880] p-[0_7px] ml-[-7px];

                &#translate_words {
                    @apply hidden #{!important};
                }

                &#typewrite_phrase {
                    display: table;
                }
            }
        }

        .text-paragraph-large {
            @apply max-w-[75%] text-sol-white;
        }

        .cta-group {
            flex-flow: row wrap;
            @apply flex gap-[20px];

            .btn {
                @apply m-0;
            }
        }
    }

    .block-well-content {
        @apply relative mt-[80px];

        @media(max-width:767px) {
            @apply mt-[40px];
        }

        .block-well {
            box-shadow: 0px 10px 30px 0px #00000047;
            @apply rounded-[12px] relative overflow-hidden p-[40px] gap-[70px] flex items-start;

            @media(max-width:1199px) {
                @apply gap-[40px];
            }

            @media(max-width:991px) {
                @apply flex-col gap-[30px] p-[30px];
            }

            @media(max-width:768px) {
                @apply gap-[30px] p-[16px] flex-col;
            }

            &::after {
                content: "";
                @apply absolute w-full h-full left-0 top-0 z-[-1];

                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background-color: rgba(81, 81, 81, .33);
            }

            .block-media {
                @apply hidden;

                .block-img,
                .block-video {
                    @apply w-[480px] block rounded-[4px] max-h-[290px] relative overflow-hidden;

                    @media(max-width:1024px) {
                        @apply w-[420px];
                    }

                    @media(max-width:991px) {
                        @apply w-full;
                    }

                    .icon-play {
                        @apply absolute z-[1] top-1/2 left-1/2 w-[80px] h-[80px];

                        transform: translate(-50%, -50%);

                        svg {
                            @apply w-full;
                            height: auto;
                        }
                    }

                    img {
                        @apply w-full h-full object-cover;
                    }
                }
            }

            .block-text {
                @apply flex gap-[70px] items-start;

                @media(max-width:991px) {
                    @apply flex-col gap-[30px];
                }

                .heading,
                .text-paragraph {
                    @apply text-sol-white;
                }

                .block-title {
                    @apply w-[480px];
                }

                .cta-group {
                    @apply flex gap-[20px];
                    flex-flow: row wrap;

                    .btn {
                        @apply m-0;
                    }
                }

                .block-description {
                    @apply flex flex-col gap-[20px];
                }
            }

            &.with-media {
                @apply items-center;

                @media(max-width:991px) {
                    @apply items-start;
                }

                .block-media {
                    @apply block w-full;
                }

                .block-text {
                    @apply flex flex-col gap-[20px];

                    .block-title {
                        max-width: inherit;
                    }
                }
            }

            &.bg-light-blue {
                .heading,
                .text-paragraph {
                    @apply text-sol-charcoal;
                }

                .btn.btn-outline-white {
                    @apply border-sol-royal-blue text-sol-royal-blue;

                    &:hover {
                        @apply text-sol-white bg-sol-royal-blue;
                    }
                }

                &::after {
                    @apply backdrop-filter-none bg-transparent;
                }
            }
        }

        &::after {
            @apply content-[''] top-auto h-[60%] block absolute bg-transparent w-full bottom-0 z-[1];
        }
    }

    .bg-video {
        @apply object-cover absolute bottom-0 left-0 h-full w-full z-0 top-0;
    }

    &.campaign-mode-1 {
        @apply flex items-center min-h-[720px];

        .block-caption {
            box-shadow: 0px 10px 30px 0px #00000047;
            @apply max-w-[890px] rounded-[12px] relative overflow-hidden p-[40px_50px] gap-[20px];

            @media(max-width:768px) {
                width: calc(100% - 20px);
                @apply gap-[10px] p-[40px_30px] left-[10px];
            }

            &::after {
                content: "";
                -webkit-backdrop-filter: blur(10px);
                backdrop-filter: blur(10px);
                background-color: rgba(81, 81, 81, .33);
                @apply absolute w-full h-full left-0 top-0 z-[-1];

            }

            .heading {
                @apply max-w-[100%];
            }

            .text-paragraph-large {
                @apply text-sol-white max-w-[100%];
            }

            .cta-group {
                @apply flex gap-[20px];
                flex-flow: row wrap;
            }
        }

        .block-well-content {
            @apply hidden;
        }
    }

    &.campaign-mode-2 {
        @apply pt-[210px] pb-[0] #{!important};
        @apply min-h-[720px];

        @media(max-width:767px) {
            @apply pt-[150px] #{!important};
        }

        .bg-video {
            @apply min-h-[720px];
        }

        &.bottom-dark {
            .block-well-content {
                &::after {
                    @apply bg-sol-charcoal;
                }
            }
        }

        &.bottom-light {
            .block-well-content {
                &::after {
                    @apply bg-sol-white;
                }
            }
        }
    }
}