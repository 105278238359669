.block_SL002 {
    @apply overflow-hidden;

    &.bg-default {
        .swiper-solutions {
            .swiper-scrollbar .swiper-scrollbar-drag {
                @apply bg-sol-white;
            }
            .swiper-nav div {
                @apply border-sol-white;

                &:after {
                    @apply text-sol-white;
                }
            }
            
        }
    }

    .swiper-solutions {
        @apply relative flex grow pb-[40px];

        .block-caption {
            @apply p-[48px_0px_0px_40px];

            @media(max-width:991px) {
                @apply p-[40px_0_40px_32px];
            }

            @media(max-width:767px) {
                @apply p-[40px_0_140px_0];
            }

            .animate-eyebrow {
                @apply delay-[200ms];
            }

            .animate-heading {
                @apply delay-[400ms];
            }

            .animate-text {
                @apply delay-[600ms];
            }

            .animate-btn {
                @apply delay-[800ms];
            }
        }
        .btn-top {
            @apply lg:pl-[40px] mt-0;
        }

        .block-img {
            @apply rounded-[16px] overflow-hidden mb-0 md:mt-0 mt-[40px] h-full;

            @media(max-width:767px) {
                @apply max-h-[360px];
            }

            video,
            img {
                @apply w-full h-full block object-cover rounded-[16px];
            }
        }

        .swiper-pagination {
            left: calc(58.33333333% + 52px);
            @apply absolute bottom-auto w-auto;

            @media(max-width:1023px) {
                left: calc(50% + 52px);
            }

            @media(max-width:991px) {
                left: calc(50% + 48px);
                @apply top-0;
            }

            @media(max-width:767px) {
                @apply left-[6px];
            }
        }

        .swiper-nav {
            @apply desktop:h-[70px] h-[48px] desktop:w-[200px] md:w-[212px] w-full gap-[16px] absolute bottom-[44px] flex items-center justify-between right-0;

            @media(max-width:767px) {
                @apply left-auto;
            }

            div {
                @apply rounded-[60px] desktop:w-[80px] w-[84px] desktop:h-[55px] h-[48px] flex items-center justify-center border-[1px] transition-colors duration-200 ease-in-out border-sol-royal-blue border-solid;

                &::after {
                    @apply text-[18px] font-bold text-sol-royal-blue;
                }

                &:hover {
                    @apply bg-sol-royal-blue;

                    &::after {
                        @apply text-sol-white;
                    }
                }
            }
            .swiper-button-prev {
                @apply left-0;
            }
        }

        .swiper-slide {
            @apply transition-opacity duration-[400ms] pb-[85px];
            @apply opacity-0 #{!important};

            &.swiper-slide-active {
                @apply opacity-100 #{!important};
            }

            .block-img img {
                @apply translate-x-[-100%] opacity-0 transition-transform duration-[0.8s] object-[60%];
            }

            .block-img.contained img {
                object-position: initial;
                @apply object-contain #{!important};
            }
        }

        .swiper-slide-active {
            .block-img img {
                @apply opacity-100 object-[50%] transform-none object-contain;
            }
        }

        .swiper-scrollbar {
            background: rgba(37, 40, 46, 0.15);
            left: 0 !important;
            @apply absolute bottom-0 md:bottom-[65px] rounded-none w-[57%];

            @media(max-width:1199px) {
                width: calc(50% - 15px);
            }

            @media(max-width:767px) {
                width: calc(100% - 15px);
            }

            .swiper-scrollbar-drag {
                @apply rounded-none bg-sol-royal-blue;
            }
        }
    }

    &.flip-columns {
        .block-caption {
            @apply md:p-[48px_48px_40px_0] p-[48px_0_40px_0];
        }

        .btn-top {
            @apply pl-0;
        }

        .swiper-slide {
            @apply pb-[70px];
        }

        .swiper-solutions {
            @apply pb-[70px];
            .swiper-pagination {                
                @apply md:left-[7px] left-auto;
            }
            .swiper-nav {
                @apply left-0;
            }

        }

        .swiper-scrollbar {
            left: unset !important;
            @apply right-0 w-[57%];
        }
    }

    &.bg-default {
        @apply text-sol-white;
    }
}

.text-paragraph-large ul li {
    @apply pl-[32px] relative mb-[0.3rem];

    &:before {
        @apply content-[''] absolute left-0 top-[16px] w-[15px] h-[2px] rounded-[50%] bg-sol-royal-blue;
    }
}

@media screen and (max-width: 768px) {
    .block_SL002 .swiper-solutions .block-caption {
        padding-bottom: 0px;
    }

    .block_SL002 .swiper-solutions .swiper-slide-active .block-img img {
        height: 100% !important;
        max-width: 100%;
    }

    .block-img.j-oc {
        max-height: 100% !important;
    }
}