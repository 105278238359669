.block_AB004 {
    &.pt-0 .block-solutions {
        @apply mt-0;
    }

    &.pb-0 {
        @apply pb-[50px] #{!important};
    }
    
    .block-img {
        @apply h-full min-h-[450px] relative overflow-hidden duration-200 ease-in-out w-full md:max-w-[500px] max-[767px]:mb-[48px];

        .image-container,
        .video-container {
            @apply w-full h-full object-cover absolute top-0 left-0 z-0;
        }

        .stats {
            @apply absolute bottom-0 z-[1] w-full;

            img {
                @apply w-full object-contain block h-auto;
            }
        }
    }

    .block-caption {
        .eyebrow {
            @apply flex items-center gap-[8px] text-sol-charcoal dark:text-sol-white;

            i {
                @apply w-[8px] h-[8px] rounded-full;
            }

            strong {
                @apply font-semibold;
            }
        }
    }

    .block-solutions {
        @apply mt-[80px] relative;

        .heading {
            @apply text-center relative z-[1] max-[767px]:p-[0_32px];

            strong {
                @apply inline-block p-[5px_24px] font-semibold bg-sol-white dark:bg-sol-charcoal text-sol-charcoal dark:text-sol-white;
            }
        }

        .block-card {
            flex-flow: row wrap;
            @apply flex justify-center items-stretch gap-[32px_16px] max-[1250px]:gap-[24px] relative z-[1] max-[576px]:flex-col;

            li {
                @apply max-[578px]:w-full max-w-[19%] max-[1250px]:max-w-[24%] max-[991px]:max-w-[32%] max-[767px]:max-w-[47%] max-[576px]:max-w-full flex-[1_0_17%] max-[1250px]:flex-[1_0_23%] max-[767px]:flex-[1_0_50%] max-[576px]:flex-[inherit];

                .item-card {
                    @apply block w-full rounded-[12px] bg-sol-light-blue2 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] h-full no-underline transition-all duration-200 ease-in-out p-[24px];

                    &.has-link:hover {
                        @apply bg-sol-white mb-[16px] mt-[-16px];
                    }

                    &:not([href]) {
                        @apply bg-sol-white;
                    }

                    .icon-card {
                        @apply min-h-[34px] h-[34px];
                        width: auto;
                    }

                    .new-title {
                        @apply font-semibold md:min-h-[100px] min-h-[inherit] transition-all duration-200 ease-in-out text-sol-charcoal;
                    }

                    .text-paragraph {
                        @apply mb-0 text-sol-charcoal;

                        p {
                            @apply mb-0;
                        }
                    }

                    &.has-link:hover {
                        @apply bg-sol-white no-underline text-sol-charcoal;

                        .new-title {
                            @apply text-sol-royal-blue;
                        }
                    }
                }
            }

            &.card-columns-3 {
                @apply grid grid-cols-1 md:grid-cols-3;

                li {
                    @apply max-w-[unset];
                }
            }

            &.card-columns-4 {
                @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4;

                li {
                    @apply max-w-[unset];
                }
            }

            &.card-columns-5 {
                @apply grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5;

                li {
                    @apply max-w-[unset];
                }
            }
        }

        &.wrapper-outline {
            &::after {
                @apply content-[''] absolute min-[1351px]:left-[-65px] left-0 top-[20px] min-[1351px]:w-[calc(100%+130px)] w-full h-[calc(100%+20px)] border-[1.5px] border-dashed rounded-[16px] z-0 border-sol-royal-blue dark:border-sol-white;
            }

            .block-card {
                @apply max-[1350px]:p-[0_32px];

                li {
                    @apply w-full;
                }
            }
        }
    }

    &.bg-transparent-custom .block-solutions .heading strong {
        @apply bg-sol-white;
    }

    &.bg-light-blue {
        .block-solutions {
            .heading strong {
                @apply bg-sol-light-blue2;
            }

            .block-card li .item-card {
                @apply bg-sol-white;

                &.has-link:hover {
                    @apply bg-sol-light-blue2;
                }
            }
        }
    }
}