.block_FR001 {
	.block-caption {
		@apply max-[1200px]:mb-[48px];
	}

	.resource-label,
	.labels-group {
		span {
			@apply dark:text-sol-white #{!important}
		}
	}

	.resource-list {
		@apply m-0 p-0 list-none;

		li {
			@apply p-[16px_0_48px] border-t border-x-0 border-b-0 border-solid border-[#ddd];

			a {
				flex-flow: row nowrap;
				@apply flex no-underline transition-all duration-200 md:gap-[32px] gap-[24px] text-[initial] max-[767px]:flex-col;				
				
				.resource-label {
					@apply flex-[33%];

					span {
						@apply text-[14px] font-semibold uppercase leading-[18px] tracking-widest;
					}
				}

				.resource-caption {
					@apply flex-[67%] relative pr-[40px];

					h5 {
						@apply mb-[48px];
					}

					&::after {
						background-image: url("data:image/svg+xml,%3Csvg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.913 0.999023L25 6.99902M25 6.99902L18.913 12.999M25 6.99902L1 6.99902' stroke='%2325282E' stroke-linecap='square'/%3E%3C/svg%3E");
						@apply content-[''] w-[32px] h-[18px] absolute bottom-0 right-0 bg-contain;

					}
				}
				
				&:hover {
					.resource-caption {
						@apply text-sol-royal-blue dark:text-sol-white;

						&::after {
							@apply [filter:invert(45%)_sepia(81%)_saturate(6414%)_hue-rotate(197deg)_brightness(95%)_contrast(105%)];
						}
					}
				}
			}
		}
	}
}