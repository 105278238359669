.block_FR003 {
	@apply lg:py-[90px];

	.resource-wrapper {
		@apply flex lg:flex-row flex-col gap-[16px];
	}

	.resource-title {
		@apply text-[22px] font-semibold leading-[26.4px] #{!important};

		&.highlight {
			@apply lg:text-[28px] text-[22px] lg:leading-[39.2px] leading-[26.4px];
		}
	}
	
	.block-caption {
		@apply desktop:mb-[36px] mb-[26px];
	}

	.labels-group span {
		@apply text-[17.7px] #{!important};
	}

	.resource-thumbnail {
		@apply relative overflow-hidden bg-cover bg-center bg-no-repeat p-[24px] lg:min-h-[260px];

		img {
			@apply h-full w-full object-cover;
		}
	}

	.content-wrapper {
		@apply flex justify-between flex-col lg:h-full lg:gap-0 gap-[15px];

		.top-content {
			@apply flex flex-col gap-[16px];
		}

		.bottom-content {
			&__desktop {
				@apply lg:flex hidden items-center justify-end;

				&.highlight {
					@apply justify-start gap-[5px];
				}
			}

			&__mobile {
				@apply lg:hidden flex items-center justify-start gap-[5px];
			}
		}
	}

	.resource-list-new {
		@apply lg:max-h-[350px] lg:overflow-y-scroll lg:overflow-x-hidden gap-[16px] lg:pr-[16px];

		&.one {			
			@apply pr-0 overflow-hidden;

			.resource-tile-new {
				.resource-thumbnail {						
					@apply min-w-full lg:min-h-[332px] min-h-[232px] h-[232px];
				}

				.content-wrapper {
					@apply lg:justify-center lg:gap-[24px] lg:p-[20px];
				}

				.resource-title {
					@apply lg:text-[28px] text-[22px] lg:leading-[39.2px] leading-[26.4px] #{!important};
				}
			}
		}

		&.default {
			@apply lg:flex-row flex-col overflow-hidden;

			.resource-tile-new {
				@apply flex-col w-full lg:h-[350px];

				.resource-thumbnail {
					@apply rounded-t-[12px] rounded-b-none overflow-hidden lg:max-w-full lg:min-h-[280px];
				}
				.resource-title {
					@apply lg:text-[28px] text-[22px] lg:leading-[39.2px] leading-[26.4px] #{!important};
				}
			}
		}

		&::-webkit-scrollbar {
			@apply w-[4px] rounded-[2px];
		}
		
		&::-webkit-scrollbar-track {
			box-shadow: inset 0 0 6px $ColorLightBlue;
		}
		
		&::-webkit-scrollbar-thumb {
			background-color: $ColorDarkPrimary3;
		}
	
		.resource-tile-new {
			@apply flex flex-col lg:gap-[6px] bg-sol-light-blue2 relative rounded-[15px];

			.resource-thumbnail {
				@apply lg:h-[208px] min-h-[232px] h-[232px] w-full rounded-t-[12px] rounded-b-none overflow-hidden;		
			}

			.content-wrapper {
				@apply lg:p-[20px] py-[20px] px-[28px];
			}
		}
	}

	.highlight {
		@apply w-full lg:max-w-[480px];
		
		.resource-tile-new {
			@apply flex flex-col bg-sol-light-blue2 w-full relative rounded-[12px] h-full;
			
			.content-wrapper {
				@apply lg:p-[20px] px-[28px] py-[20px];
			}
			.resource-title {
				@apply lg:text-[28px] lg:leading-[39.2px] #{!important};
			}
		}
		.resource-thumbnail {
			@apply w-full lg:min-h-[280px] min-h-[232px] lg:max-h-[280px] max-h-[232px] rounded-t-[12px] rounded-b-none overflow-hidden;				
		}
	}
	
	.resource-link {
		@apply absolute left-0 top-0 w-full h-full z-[99];  
	}

	&.bg-light-blue .resource-tile-new {
		@apply bg-sol-white;
	}

	&.bg-default {
		.resource-tile-new {
			@apply bg-sol-white text-[#25282E];
		}
	}
}