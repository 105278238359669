.block_BN001 {
    @apply relative overflow-hidden;

    @media(max-width:767px) {
        @apply m-[60px_20px_20px] rounded-[20px];
        @apply pt-[200px] pb-[50px] #{!important};
    }

    .block-caption {
        @apply relative z-[2] max-w-[500px];
    }

    .bg-video,
    .bg-img {
        @apply object-cover absolute md:h-[465px] h-full md:w-[935px] w-full bottom-0 right-0 max-[767px]:top-[-30px];
    }

    &::after {
        @apply content-[''] absolute w-full h-full left-0 top-0 z-[1];
        background: linear-gradient(90deg, #F3F9FE 57.36%, rgba(243, 249, 254, 0) 70.95%), linear-gradient(180deg, #F3F9FE 9.49%, rgba(243, 249, 254, 0) 63.27%);

        @media(max-width:767px) {
            background: linear-gradient(90deg, #F3F9FE 1.36%, rgba(243, 249, 254, 0) 70.95%), linear-gradient(0deg, #F3F9FE 63.49%, rgba(243, 249, 254, 0) 100.27%);
        }
    }

    &.img-more-to-the-left .bg-img {
        @apply desktop:bottom-[4%] desktop:right-[7vw];              
    }
}