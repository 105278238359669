.block_DATA001 {

    .block-grid {
        @apply grid gap-[20px] grid-cols-[repeat(auto-fit,minmax(221px,1fr))];

        li {
            position: inherit;
            @apply h-auto;

            .block-card {
                @apply bg-sol-light-blue2 h-full duration-200 ease-in-out text-[initial];

                .block-img {
                    @apply h-[300px] relative;

                    img {
                        @apply w-full h-full object-cover cursor-pointer;
                    }

                    i {
                        @apply absolute bg-sol-white text-sol-royal-blue right-[8px] bottom-[8px] text-[22px] font-normal flex items-center justify-center w-[32px] h-[32px] rounded-full leading-[1px] not-italic duration-200 ease-in-out pl-[1px] pb-[2px] cursor-pointer;
                    }
                }

                &:hover {
                    @apply text-sol-royal-blue;

                    i {
                        @apply bg-sol-royal-blue text-sol-white;
                    }
                }
            }

            .block-caption {
                @apply p-[16px_0] gap-[32px];
            }
        }
    }

    .heading-default {
        @apply text-sol-charcoal dark:text-sol-white;
    }    

    .text-paragraph-large {
        @apply text-sol-charcoal dark:text-sol-white min-[992px]:pl-[96px] md:pl-[48px] pl-0;
    }

    .block-caption {
        @apply text-sol-charcoal dark:text-sol-white dark:hover:text-sol-royal-blue;
    }
}

.team-modal-btn {
    @apply mb-[32px] cursor-pointer;

    &:hover {
        svg rect {
            @apply fill-sol-royal-blue;
        }

        svg path {
            @apply stroke-white;
        }
    }
}