.block_DATA003 {
	.block-title {
		@apply max-w-[870px] mx-auto;
	}

	.block-filter {
		.multiselect {
			@apply relative block;

			.select-box {
				@apply relative cursor-pointer rounded-xl border-0 shadow-[0px_0px_30px_0px_rgba(0,0,0,0.1)] transition-all duration-200 border-b border-transparent bg-sol-white;

				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.801758 1.22119L6.80176 7.22119L12.8018 1.22119' stroke='%230074E8'/%3E%3C/svg%3E");
					@apply content-[''] rotate-0 w-[14px] h-[8px] right-[32px] top-[25px] absolute transition-all duration-200;					
				}

				.form-select {
					@apply text-[18px] p-[1.5rem_5rem_1.5rem_2.5rem] h-auto border-0 bg-transparent m-0;
				}

				.over-select {
					@apply absolute inset-0;
				}
			}

			&.active {
				.select-box {
					@apply rounded-t-xl border-b border-[#00000020] after:rotate-[-180deg];
				}
			}

			.select-options {
				@apply hidden absolute overflow-y-auto h-auto w-full top-full rounded-b-xl p-[1.2rem_2rem] z-[99] bg-sol-white shadow-[0px_0px_30px_0px_#0000001A];

				.label-group {
					@apply flex flex-wrap gap-x-2 gap-y-2;

					label {
						@apply inline-block cursor-pointer;

						span {
							@apply border border-[#D2D2D2] rounded-full py-1 px-4 text-[16px] text-[#6A6A6A] transition-all duration-200;

							&:hover {
								@apply border-sol-royal-blue text-sol-royal-blue;
							}
						}

						input[type="checkbox"] {
							@apply hidden;

							&:checked + span {
								@apply border-sol-royal-blue text-sol-royal-blue;
							}
						}
					}
				}
			}
		}
	}

	.block-list {
		@apply flex flex-wrap phablet:gap-[20px] gap-[30px] max-[576px]:flex-col;

		.block-card {
			@apply h-auto flex flex-col min-[992px]:w-[calc(33.75%-20px)] md:w-[calc(50%-20px)] w-full mb-[32px] cursor-pointer bg-sol-white text-[initial] shadow-[0px_6px_10px_0px_#00000020];						

			img {
				@apply w-full h-auto min-[992px]:max-h-[250px] md:max-h-[350px] max-h-[inherit] object-cover;
			}

			.block-caption {
				@apply flex flex-col phablet:p-[32px] p-[24px] phablet:gap-8 gap-6 relative h-full;

				.top-card {
					@apply flex justify-between;

					.resource-label {
						@apply max-w-[50%] overflow-hidden whitespace-nowrap text-ellipsis;

						span {
							@apply text-[14px] font-semibold uppercase leading-[18px] tracking-wider;
						}
					}

					.resource-date {
						@apply max-[576px]:text-[14px];
					}
				}

				&::after {
					background-image: url("data:image/svg+xml,%3Csvg width='26' height='14' viewBox='0 0 26 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.913 0.999023L25 6.99902M25 6.99902L18.913 12.999M25 6.99902L1 6.99902' stroke='%2325282E' stroke-linecap='square'/%3E%3C/svg%3E");
					@apply content-[''] w-[32px] h-[18px] absolute phablet:bottom-[32px] phablet:right-[32px] bottom-[24px] right-[24px] bg-contain;
				}

				.resource-caption {
					@apply flex-grow;
				}

				.heading {
					@apply transition-all duration-200;
				}

				.labels-group {
					@apply flex flex-wrap pr-[50px] gap-x-[24px] gap-y-[16px];
				}
			}

			&:hover .block-caption {
				.heading {
					@apply text-sol-royal-blue;
				}

				&::after {
					@apply [filter:invert(45%)_sepia(81%)_saturate(6414%)_hue-rotate(197deg)_brightness(95%)_contrast(105%)];
				}
			}
		}
	}
}
