/*!
Theme Name: RightRev
Author: Baunfire
Description: 
Version: 1.0.0
Tested up to: 5.4
Requires PHP: 5.6
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: RightRev
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned. 

RightRev is based on Underscores https://underscores.me/, (C) 2012-2020 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later. 

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
***/

	
html {
    scroll-behavior: smooth;
}

*, ::after, ::before {
    box-sizing: border-box;    
}

.container, 
.container-fluid, 
.container-lg, 
.container-md, 
.container-sm, 
.container-xl, 
.container-xxl {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    @apply pr-[calc(theme(spacing.gutter-x)*.5)] pl-[calc(theme(spacing.gutter-x)*.5)] mx-auto w-full;
}

a,
p,
div,
button,
span,
ul,
ol,
li,
i,
fieldset {
    @apply font-sans;
}

p {
    @apply mt-0;
}

.bg-dark {
    @apply bg-sol-charcoal;
}

fieldset {
    @apply border-0 p-0;
}

video::-webkit-media-controls-panel {
    background-image: linear-gradient(transparent, transparent) !important; //Transparent for your case
}

.custom-video .playpause {
    width:25%;
    height:25%;
    position:absolute;
    left:0%;
    right:0%;
    top:0%;
    bottom:0%;
    margin:auto;
}

.light-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    small {
        @apply text-sol-white #{!important};
        // text-shadow: 0 2px 2px #818181;
    }
    p,
    span {
        @apply text-sol-white #{!important};
    }
}

.page-template-template-glossary .wpg-list-wrapper.wpg-list-wrapper-template-category .wpg-list-block {
    @apply block #{!important};
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import "utilities/variables";
@import "baunfire-header";
@import "baunfire-footer";

@import "parts/animations";
@import "parts/blocks";
@import "parts/buttons";
@import "parts/forms";
@import "parts/general";
@import "parts/homepage";
@import "parts/resources";