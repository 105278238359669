.block_TXT005 {
    @apply py-[120px];

    &.block_TXT005--light {
        @apply bg-sol-white;

        >* {
            @apply text-sol-charcoal #{!important};
        }
    }

    .block-caption {
        @apply flex h-full flex-col justify-center;
    }

    .block-img {
        @apply overflow-hidden max-w-[500px];

        @media(max-width:767px) {
            @apply mb-[32px];
        }

        img {
            @apply w-full h-full object-cover block;
        }
    }

    &.bg-default,
    &.bg-dark-gray,
    &.bg-black,
    &.block_TXT005--dark {
        >* {
            @apply text-sol-white;
        }
    }

    &.block_TXT005--dark {
        @apply bg-sol-charcoal;
    }
}