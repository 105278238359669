.block_TXT008 {
    .block-title {
        @apply max-w-[820px];
    }

    .block-img {
        @apply bg-sol-white shadow-[0px_10px_30px_0px_#0000001A];

        img {
            @apply w-full;
        }
    }

    .block-list {
        @apply flex flex-col w-full md:max-w-[400px] max-w-[inherit];

        .block-card {
            @apply bg-sol-light-blue2 p-[32px] mb-[20px] shadow-[0px_3px_10px_0px_#00000014];
        }
    }
}