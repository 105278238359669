.block_FR004 {
	@apply lg:py-[90px];

	.resource-wrapper {
		@apply flex;
	}

	.heading-default {
		@apply dark:text-sol-white;
	}

	.heading-link {
		@apply absolute bottom-[23px] lg:left-[32px] left-[20px];
	}
	
	.block-caption {
		@apply desktop:mb-[36px] mb-[26px];
	}

	.labels-group span {
		@apply text-sol-white;
	}

	.husl-btn.btn-primary {
		@apply dark:bg-sol-white dark:text-sol-charcoal #{!important};
	}

	.resource-thumbnail {
		@apply relative lg:overflow-hidden lg:max-h-none max-h-[215px];

		img {
			@apply h-full w-full object-cover;
		}
	}

	.content-wrapper {
		@apply flex justify-between flex-col lg:h-full lg:gap-0 gap-[15px];

		.top-content {
			@apply flex flex-col gap-[16px];
		}

		.bottom-content {
			&__desktop {
				@apply lg:flex hidden items-center justify-end;
			}

			&__mobile {
				@apply lg:hidden flex items-center justify-start gap-[5px];
			}
		}
	}

	.resource-list-new {
		@apply flex flex-col gap-[16px] pb-[30px];

		.resource-tile-new {
			@apply flex lg:flex-row flex-col lg:gap-[6px] bg-sol-light-blue2 relative rounded-[12px] shadow-[0_0_10px_0px_rgba(0,0,0,0.20)];

			.resource-thumbnail {
				@apply lg:min-w-[245px] lg:max-w-[245px] h-full lg:rounded-bl-[12px] lg:rounded-tl-[12px] lg:rounded-tr-none lg:rounded-br-none rounded-t-[12px] rounded-b-none overflow-hidden;		
			}

			.content-wrapper {
				@apply p-[20px];
			}
		}
	}
	
	.resource-link {
		@apply absolute left-0 top-0 w-full h-full z-[99];  
	}

    .swiper-nav {
        @apply gap-[16px] flex items-center justify-start;

        div {
            @apply rounded-[60px] w-[80px] h-[56px] flex items-center justify-center border-[1px] border-solid border-sol-royal-blue dark:border-sol-white duration-200 ease-in-out;

            &::after {
                font-variant: initial;
                @apply normal-case #{!important};
                @apply text-sol-royal-blue dark:text-sol-white text-[18px] font-bold tracking-normal leading-[1];
            }

            &:hover {
                @apply bg-sol-royal-blue dark:bg-sol-white;

                &::after {
                    @apply text-sol-white dark:text-sol-charcoal;
                }
            }

            &.swiper-button-next-resources::after {
                content: 'next';     
                font-family: swiper-icons;               
            }

            &.swiper-button-prev-resources::after {
                content: 'prev';     
                font-family: swiper-icons;                           
            }
        }
    }
}