.sai-carousel-50-50 .object-contain {
    -o-object-fit: contain;
    object-fit: contain !important;
}

.sai-carousel-50-50 {
    .sai-carousel-5050 {
        @apply w-full relative overflow-hidden min-h-[600px];

        .tab-logo {
            max-width: 150px;
            margin-bottom: 40px;
        }

        .tabpane-inner-content {
            p {
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 24px;
            }

            .caption {
                font-size: 14px;
                line-height: 120%;
                letter-spacing: 1.4px;
                text-transform: uppercase;
                font-weight: 600;
                margin-bottom: 12px;
            }

            .content {
                @apply lg:pr-[40px];
            }
        }

        .swiper-button {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            width: 100%;

            &.green-gradient::before {
                background: linear-gradient(90deg, #00EAC3 30%, #414141 40%), rgb(65, 65, 65);
            }
    
            &.blue-gradient::before {
                background: linear-gradient(90deg, #4169E1 30%, #414141 40%), rgb(65, 65, 65);
            }
    
            &.purple-gradient::before {
                background: linear-gradient(90deg, #A933FB 30%, #414141 40%), rgb(65, 65, 65);
            }
    
            &.pink-gradient::before {
                background: linear-gradient(90deg, #FB24A9 30%, #414141 40%), rgb(65, 65, 65);
            }
    
            &.yellow-gradient::before {
                background: linear-gradient(90deg, #FFB600 30%, #414141 40%), rgb(65, 65, 65);
            }
    
            &.lightblue-gradient::before {
                background: linear-gradient(90deg, #1EACFC 30%, #414141 40%), rgb(65, 65, 65);
            }
        }
        
        .swiper-button::before {
            content: "";
            position: absolute;
            left: 0;
            width: 80%;
            height: 1px;
        }

        @media (max-width: 767px) {
            .swiper-button::before {
                width: 68%;
            }

            .button-block {
                .btn.btn-primary {
                    display: inline-block;
                    margin-bottom: 40px;
                }
            }
        }
        
        .swiper-button-prev,
        .swiper-button-next {
            position: relative;
            z-index: 1;
        }
        
        .swiper-button-prev {
            order: 1;
            width: 11px;
            height: 16px;
            margin: 0 80px 0 0;

            &::after {
                content: '' !important;
            }
        }
        
        .swiper-button-next {
            order: 2;
            width: 11px;
            height: 16px;
            margin: 0;

            &::after {
                content: '' !important;
            }
        }        
    }

    .swiper-wrapper {
        @apply flex items-center transition-transform duration-200 ease-in-out;
    }

    .swiper-slide {
        @apply transition-all duration-200 ease-in-out opacity-20 w-full max-w-[1250px];

        .green-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #00EAC3, #0074E8);
            }
        }

        .blue-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #4169E1, #87CEFA);
            }
        }

        .purple-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #A933FB, #0074E8);
            }
        }

        .pink-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #FB24A9, #FFB600);
            }
        }

        .yellow-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #FFB600, #FF8500);
            }
        }

        .lightblue-gradient {
            .h1 {
                background-image: linear-gradient(90deg, #1EACFC, #00EAC3);
            }
        }

        .h1 {
            @apply bg-clip-text text-transparent tracking-tight mr-4;
        }
    }

    .swiper-slide-active, .swiper-slide.active-slide {
        @apply opacity-100 transition-opacity duration-200;
    }

    .swiper-slide-active {
        opacity: 1 !important;
    }

    .swiper-slide-active .slide-inner {
        transform: translate3d(0px, 0%, 0px) !important;
    }
}
