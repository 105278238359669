.sai-product {
	
	@apply lg:py-[90px];

	.resource-wrapper {
		@apply flex max-lg:flex-col;
	}

	.heading-default {
		@apply dark:text-sol-white;
	}

	.heading-link {
		@apply absolute bottom-[23px] lg:left-[32px] left-[20px];
	}
	
	.block-caption {
		@apply desktop:mb-[36px] mb-[26px];
	}

	.labels-group span {
		@apply text-sol-white;
	}

	.content-wrapper {
		@apply flex justify-between flex-col h-full lg:gap-0 gap-[15px];

		.top-content {
			@apply flex flex-col gap-[16px];
		}

		.bottom-content {
			&__desktop {
				@apply lg:flex hidden items-center justify-end;
			}

			&__mobile {
				@apply lg:hidden flex items-center justify-start gap-[5px];
			}
		}
	}

	.resource-list-new {
		@apply flex flex-col gap-[16px] pb-[30px] rounded-[12px] overflow-hidden pb-0;
		@apply  lg:min-w-[388px] lg:max-w-[388px] #{!important};

		&.swiper-slide {
			@apply h-auto mr-0 #{!important};
		}

		.resource-tile-new {
			@apply flex lg:flex-row flex-col lg:gap-[6px] relative rounded-[12px] shadow-[0_0_10px_0px_rgba(0,0,0,0.20)] border border-solid m-[1px];
            background: linear-gradient(107deg, #222 9.26%, #101010 117.15%);
			
			&::before {
				content: "";
			}

			.content-wrapper {
				@apply p-[20px];
			}

			&::after {
				content: "";
				border-radius: 439px;
				bottom: -60px;
				filter: blur(33.85px);
				position: absolute;
				width: 100%;
				height: 60px;
				background: transparent;
				opacity: 0;
				transition: opacity 0.4s ease;
			}
		}
	}

	.resource-1 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #0074e8 10.36%, #a933fb 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-2 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #ff8500 10.36%, #ffb600 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-3 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #BF77F6 10.36%, #950aff 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-4 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #fb24a9 10.36%, #d29d12 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-5 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #4169e1 10.36%, #008cff 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-retail {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg,#1fcfc5 10.36%,#0076f1 61.75%);
				opacity: 1;
			}
		}
	}

	.resource-6 {
		.resource-tile-new {
			&:hover::after {
				background: linear-gradient(119deg, #008afc 10.36%, #0076f1 61.75%);
				opacity: 1;
			}
		}
	}
	
	.resource-link {
		@apply absolute left-0 top-0 w-full h-full z-[99];  
	}
}
.resource-tile-new  {
	.resource-title {
		min-height: 100.78px;
	}
}
