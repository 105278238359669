.block_SL001 {
    .block-caption {
        @apply relative z-[1];
    }

    .swiper-benefits {
        @apply md:pt-[110px] pt-0 md:mt-[-100px] mt-[48px] md:pb-[20px] pb-[100px] relative;

        .swiper-nav {
            @apply desktop:max-h-[70px] max-h-[48px] h-auto desktop:w-[248px] w-[212px] gap-[16px] absolute md:right-[20px] right-auto md:top-0 top-auto flex items-center justify-between;

            @media(max-width:767px) {
                @apply left-1/2 bottom-[16px] translate-x-[-50%];
            }

            div {
                @apply rounded-[60px] desktop:w-[100px] w-[84px] desktop:h-[70px] h-[48px] flex items-center justify-center border-[1px] border-solid border-sol-royal-blue duration-200 ease-in-out;

                &::after {
                    @apply text-[18px] font-bold text-sol-royal-blue;
                }

                &:hover {
                    @apply bg-sol-royal-blue;

                    &::after {
                        @apply text-sol-white;
                    }
                }
            }
        }

        .swiper-slide {
            @apply h-auto;

            & > a {
                @apply text-sol-charcoal;
            }

            .block-card {
                position: relative;
                box-shadow: 0px 6px 22px 2px #00000020;
                @apply p-[18px] h-full bg-sol-white;
                transition: all 0.15s ease-in-out;
                top: 0;

                .card-icon {
                    @apply desktop:mb-[40px] mb-[20px] h-auto max-h-[76px] w-auto;

                    &.lottie {
                        @apply max-w-fit;
                    }
                }

                &:hover {
                    @apply top-[-5%] #{!important};
                }
            }
        }
    }

    .vertical {
        @apply before:content-[''] before:inline-block before:w-[10px] before:h-[10px] before:mr-[10px] before:align-middle before:rounded-[50%] before:mb-[2px] before:bg-sol-green;    
    
        &.symphonyai,
        &.ai {
          @apply before:bg-sol-orange;      
        }
    
        &.concertai {
          @apply before:bg-sol-green;      
        }
    
        &.financial-services {
          @apply before:bg-sol-royal-blue;      
        }
    
        &.retail-cpg {
          @apply before:bg-sol-green;      
        }
    
        &.industrial {
          @apply before:bg-sol-yellow;      
        }
    
        &.it-esm {
          @apply before:bg-sol-light-purple;      
        }
    
        &.business-it {
          @apply before:bg-sol-royal-blue;      
        }
    
        &.media {
          @apply before:bg-sol-pink;      
        }
    
        &.public-sector {
          @apply before:bg-sol-green;      
        }
    
        &.terarecon {      
          @apply before:bg-sol-green;      
        }
    
        &.financial-crime {
          @apply before:bg-sol-pink;      
        }
    
        &.itsm {
          @apply before:bg-sol-light-purple;      
        }
    }
}