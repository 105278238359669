.block_SAI-Carousel-Accordion {
    @media (max-width: 767px) {
        .btn.btn-primary {
            display: inline-block !important;
        }
    }

    .bg-white {
        .line {
            &.green-gradient {
                background: linear-gradient(to right, rgba(39, 233, 195, 0) 0%, #27E9c3 50%, #27E9c3 50%, rgba(39, 233, 195, 0) 100%);
            }

            &.lightblue-gradient {                
                background: linear-gradient(to right, rgba(30, 172, 252, 0.20) 0%, #1EACFC 50%, #1EACFC 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.blue-gradient {
                background: linear-gradient(to right, rgba(0, 116, 232, 0) 0%, #0074e8 50%, #0074e8 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.yellow-gradient {
                background: linear-gradient(to right, rgba(255, 183, 0, 0) 0%, #ffb600 50%, #ffb600 50%, rgba(255, 183, 0, 0) 100%);
            }

            &.purple-gradient {
                background: linear-gradient(to right, rgba(169, 51, 251, 0) 0%, #a933fb 50%, #a933fb 50%, rgba(169, 51, 251, 0) 100%);
            }
            
            &.pink-gradient {
                background: linear-gradient(to right, rgba(251, 36, 169, 0) 0%, #fb24a9 50%, #fb24a9 50%, rgba(251, 36, 169, 0) 100%);
            }
        }

        .nav-link:hover .line {
            bottom: 10px;
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            width: 100%;

            &.green-gradient {
                background: linear-gradient(to right, rgba(39, 233, 195, 0) 0%, #27E9c3 50%, #27E9c3 50%, rgba(39, 233, 195, 0) 100%);
            }

            &.lightblue-gradient {                
                background: linear-gradient(to right, rgba(30, 172, 252, 0.20) 0%, #1EACFC 50%, #1EACFC 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.blue-gradient {
                background: linear-gradient(to right, rgba(0, 116, 232, 0) 0%, #0074e8 50%, #0074e8 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.yellow-gradient {
                background: linear-gradient(to right, rgba(255, 183, 0, 0) 0%, #ffb600 50%, #ffb600 50%, rgba(255, 183, 0, 0) 100%);
            }

            &.purple-gradient {
                background: linear-gradient(to right, rgba(169, 51, 251, 0) 0%, #a933fb 50%, #a933fb 50%, rgba(169, 51, 251, 0) 100%);
            }
            
            &.pink-gradient {
                background: linear-gradient(to right, rgba(251, 36, 169, 0) 0%, #fb24a9 50%, #fb24a9 50%, rgba(251, 36, 169, 0) 100%);
            }
        }
    }

    small b {
        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, #0074e8, #a933fb);
        -webkit-background-clip: text;
        background-clip: text;
        font-weight: 600;
    }

    .tab-pane {         
        .tap-mob-heading {
            @apply max-[768px]:py-[20px] max-[768px]:pt-0 max-[768px]:pb-[10px] max-[768px]:mb-[20px] max-[768px]:px-0 max-[768px]:border-b-[1px] max-[768px]:border-b-[#cacfd4] max-[768px]:border-solid max-[768px]:border-x-0 max-[768px]:border-t-0;                            

            a {     
                @apply max-[768px]:text-[16px] max-[768px]:tracking-[0.32px] max-[768px]:text-sol-charcoal max-[768px]:font-bold;            
            }
        }

        .mob-tabpane-toggler {
            @apply max-[768px]:items-center max-[768px]:flex max-[768px]:justify-between max-[768px]:w-full;                
        }
        
        .tabpane-inner-content {
            @apply max-[768px]:hidden;
            
            .button-block {
                @apply flex lg:mt-[60px] mt-[40px];
            }
    
            .solution-links {
                &__title {
                    @apply text-[16px] font-bold lg:mt-[32px] mt-[22px] text-sol-charcoal;
                }
    
                &__content {
                    @apply inline-flex flex-wrap lg:gap-[25px] gap-[15px] mt-[10px] text-[14px] font-bold;
    
                    a {
                        @apply text-sol-royal-blue;
                    }
                }
            }
    
            .bg-img {
                @apply object-cover w-full rounded-[12px];
            }

            h1,h2,h3,h4,h5,h6 {
                @apply mb-[15px];
            }
        }

        &.active {
            .mob-tabpane-toggler svg {    
                @apply max-[768px]:rotate-[180deg];
            }
    
            .tap-mob-heading a {   
                @apply max-[768px]:text-sol-charcoal;
            }
    
            .tabpane-inner-content {
                @apply max-[768px]:block;
    
                .row {
                    @apply max-[768px]:flex-col max-[768px]:gap-[20px];
                }
            }
        }
    }

    .nav-pills-accordion {
        @apply relative inline-flex lg:items-center items-end p-[4px] flex-nowrap w-full mb-[.75rem] mt-0 lg:whitespace-nowrap whitespace-normal;          
        
        .nav-link.active, 
        .show>.nav-link {
            @apply text-sol-charcoal font-bold bg-transparent;            
        }

        .nav-link {
            @apply relative text-sol-charcoal font-bold text-[16px] lg:max-lg:px-[14px] lg:px-[24px] px-[20px] pt-[12px] lg:pb-[24px] pb-[12px] text-center border-0 bg-transparent;  

            @media (max-width: 1160px) {
                @apply px-[12px];
                
            }
                
            span {             
                &::before { 
                    @apply content-[''] w-full h-[1px] absolute lg:bottom-[10px] bottom-[-2px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                }
                div {
                    @apply relative;

                    &::before { 
                        visibility: hidden;
                        @apply content-[''] w-full h-[3px] absolute bottom-[-15px] left-0 bg-sol-charcoal bg-opacity-[0.2];
                    }
                }
            }
            &.active span { 
                &::before {
                    visibility: visible;
                    @apply h-auto bg-opacity-100;             
                }

                &.green::before {
                    @apply bg-sol-green;
                }
    
                &.pink::before {
                    @apply bg-sol-pink;
                }
    
                &.purple::before {
                    @apply bg-sol-light-purple;                    
                }
                
                &.dark-purple::before {
                    @apply bg-sol-dark-purple;                    
                }
    
                &.yellow::before {
                    @apply bg-sol-yellow;
                }
    
                &.blue::before {
                    @apply bg-sol-royal-blue;
                }
    
                &.light-blue::before {
                    @apply bg-sol-light-blue;
                }
    
                &.orange::before {
                    @apply bg-sol-orange;
                }
            }                                   
        }
    }

    .nav-pills-accordion::before {
        @apply content-[''] absolute bottom-[14px] left-0 w-full h-[2px] bg-[rgba(255,255,255,0.20)];
    }
}

.block_SAI-Carousel-Accordion .object-contain {
    -o-object-fit: contain;
    object-fit: contain !important;
}

.block_SAI-Carousel-Accordion {
    .swiper-wrapper {
        @apply flex items-center transition-transform duration-200 ease-in-out;
    }

    .swiper-slide {
        .carousel-image-card {
            @apply rounded-[12px] py-[5rem];
            background-image: linear-gradient(to top right, #80B9F3, #CB96F6);
        }

        .tabpane-inner-content {
            h5, .h5 {
                margin-bottom: 15px;
            }
        }
    }

    .bg-white {
        .nav-link {
            &.active span { 
                &::before {
                    opacity: 0.2;
                    height: 1px;          
                }
            }
        }
    }

    .swiper-slide-active, .swiper-slide.active-slide {
        @apply opacity-100 transition-opacity duration-200;
    }

    .swiper-slide-active {
        opacity: 1 !important;
    }

    .swiper-slide-active .slide-inner {
        transform: translate3d(0px, 0%, 0px) !important;
    }

    .nav-pills-accordion .nav-link {
        @apply cursor-pointer transition-all duration-200;
    }

    .nav-pills-accordion .nav-link.active {
        @apply font-bold text-sol-charcoal;
    }
}

.sai-mobile-accordion {
    @apply w-full flex flex-col gap-4;

    .accordion-item {
        @apply border-b;

        &:first-of-type {
            .carousel-accordion-header-mobile {
                .accordion-button {
                    @apply pt-4;
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                }
            }
        }

        .carousel-accordion-header-mobile {
            @apply flex justify-between items-center px-6 cursor-pointer rounded-md transition-all duration-300;    
            .accordion-button {
                @apply flex items-center justify-between w-full text-left font-medium transition-all duration-300 pb-4;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            }
        }
    }

    .accordion-item {
        @apply border-b;
      }

    .carousel-accordion-content-mobile {
        @apply overflow-hidden transition-[max-height] duration-500 ease-in-out max-h-0;
    }

    .carousel-accordion-content-mobile.open {
        @apply max-h-[1000px];
    }

    .accordion-body {
        @apply pt-10 px-6;

        .h1 {
            @apply bg-clip-text text-transparent tracking-tight mr-4;
            background-image: linear-gradient(90deg, #00EAC3, #0074E8);

            &.financial-h1 {
                background-image: linear-gradient(90deg, #4169E1, #87CEFA);
            }

            &.industrial-h1 {
                background-image: linear-gradient(90deg, #FF8500, #FFB600);
            }

            &.enterprise-h1 {
                background-image: linear-gradient(90deg, #80B9F3, #B247FB);
            }

            &.media-h1 {
                background-image: linear-gradient(90deg, #FB24A9, #FFB600);
            }
        }

        .carousel-image-card {
            @apply rounded-[12px] p-5 mt-4;
            background-image: linear-gradient(to top right, #80B9F3, #CB96F6);
        }

        .tabpane-inner-content {
            h5, .h5 {
                margin-bottom: 15px;
            }
        }
    }

    .carousel-accordion-icon-mobile {
        @apply transition-transform duration-300 rotate-0;
    }

    .carousel-accordion-icon-mobile.open {
        @apply rotate-180;
    }
}

.sai-accordion {
    @apply w-full flex flex-col gap-4;

    .accordion-heading {
        color: rgba(0, 0, 0, 0.44);
        font-size: 14px;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .accordion-item {
        @apply border-b;

        .accordion-button {
            padding-top: 0 !important;
        }

        &:first-of-type .accordion-button {
            padding-top: 1rem !important;
        }
    }

    .accordion-item {
        @apply border-b;

        .accordion-button {
            @apply flex items-center justify-between w-full text-left font-medium transition-all duration-300 pb-4;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    
            span {
                font-size: 18px !important;
                font-weight: 600;
                line-height: 140% !important;
                opacity: 0.5;
            }
        }

        .open {
            span {
                opacity: 1;
            }
        }
        
        .carousel-accordion-icon {
            align-self: flex-end;
            height: 15px;
            margin-left: auto;
            transition: transform .3s ease;
            width: 30px;
            margin-bottom: 20px;
        }
    }
      
    .carousel-accordion-header {
        @apply flex justify-between items-center pr-6 cursor-pointer rounded-md transition-all duration-300;
        padding-left: 0;

        &.open .accordion-button {
            border-bottom: 0;
        }
    }

    @media (max-width: 1023px) {
        .carousel-accordion-header {
            padding-right: 0;
        }
    }
    
    .carousel-accordion-content {
        @apply overflow-hidden duration-500 ease-out max-h-0 opacity-0;
        transition: none;

        .text-dark {
            color: #25282e;
        }
    }
    
    .carousel-accordion-content.open {
        @apply max-h-[500px] opacity-100 duration-500 ease-in;
        transition: max-height 0.5s ease-in, opacity 0.5s ease-in;
    }

    .accordion-body {
        @apply pt-0 pr-6 pl-0;

        .carousel-image-card {
            @apply rounded-[12px] p-5 mt-4;
            background-image: linear-gradient(to top right, #80B9F3, #CB96F6);
        }

        .accordion-gradient {
            height: 3px;

            &.green-gradient {
                background: linear-gradient(to right, rgba(39, 233, 195, 0) 0%, #27E9c3 50%, #27E9c3 50%, rgba(39, 233, 195, 0) 100%);
            }

            &.lightblue-gradient {                
                background: linear-gradient(to right, rgba(30, 172, 252, 0.20) 0%, #1EACFC 50%, #1EACFC 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.blue-gradient {
                background: linear-gradient(to right, rgba(0, 116, 232, 0) 0%, #0074e8 50%, #0074e8 50%, rgba(0, 116, 232, 0) 100%);
            }

            &.yellow-gradient {
                background: linear-gradient(to right, rgba(255, 183, 0, 0) 0%, #ffb600 50%, #ffb600 50%, rgba(255, 183, 0, 0) 100%);
            }

            &.purple-gradient {
                background: linear-gradient(to right, rgba(169, 51, 251, 0) 0%, #a933fb 50%, #a933fb 50%, rgba(169, 51, 251, 0) 100%);
            }
            
            &.pink-gradient {
                background: linear-gradient(to right, rgba(251, 36, 169, 0) 0%, #fb24a9 50%, #fb24a9 50%, rgba(251, 36, 169, 0) 100%);
            }
        }
    }

    @media (max-width: 1023px) {
        .accordion-body {
            padding-right: 0;
        }
    }
}
