.block_BN002 {
    @apply relative;

    .block-caption{
        @apply max-w-[660px] text-center relative z-[1] m-auto;
    }
    &.bg-default,
    &.bg-dark-gray,
    &.bg-dark{
        * {
            @apply text-sol-white;
        }
    }
    .bg-img,
    .bg-video{
        @apply absolute left-0 top-0 object-cover w-full h-full block z-0;
    }
}