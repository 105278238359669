.block_TXT002 {
    .block-img {
        box-shadow: 0px 10px 30px 0px #0000001A;
        img {
            @apply w-full object-contain max-h-[800px];

            @media screen and (max-width: 768px) {
                @apply max-h-[500px];
            }

            @media screen and (max-width: 568px) {
                @apply max-h-[300px];
            }
        }
    }

    .block-caption {
        .heading {
            @apply max-w-[450px];
        }

        .block-paragraph {
            columns: 450px 2;
            @apply gap-[30px];
        }
    }

    &.bg-default,
    &.bg-dark-gray,
    &.bg-dark {
        * {
            @apply text-sol-white;

            &:before,
            &:after {
                @apply text-sol-white bg-sol-white;
            }
        }
    }
}