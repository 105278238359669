.block_SAI-Icon-Cards-Slider {
    @apply max-lg:px-[18px] #{!important};
    
    .block-caption {
        @apply relative z-[1];
    }

    .gradient-border {
        background: linear-gradient(107deg, rgba(255, 255, 255, 0.79) 9.26%, #FFF 117.15%);
        &::before {
            @apply content-[''] opacity-[0.32] absolute p-[1px] rounded-[1rem] inset-0;
            -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
            mask-composite: exclude;            
        }
    }

    .swiper-benefits-new__mobile,
    .swiper-benefits-new {
        @apply md:pt-[110px] pt-0 md:mt-[-100px] mt-[48px] md:pb-[20px] pb-[100px] relative;

        .swiper-nav {
            @apply desktop:max-h-[70px] max-h-[48px] h-auto w-[120px] gap-[16px] absolute md:right-[20px] right-auto md:bottom-0 bottom-auto flex items-center justify-between;

            @media(max-width:767px) {
                @apply left-1/2 bottom-[16px] translate-x-[-50%];
            }

            div {
                @apply rounded-[60px] w-[40px] desktop:h-[70px] h-[48px] flex items-center justify-center duration-200 ease-in-out;

                &::after {
                    @apply text-[18px] font-bold text-[#17191B];
                }
            }
        }

        .swiper-slide {
            @apply h-auto lg:pr-[56px] md:pr-[36px] pr-0 opacity-[0.5];
            &.swiper-slide-prev,
            &.swiper-slide-active,
            &.swiper-slide-next,
            &.swiper-slide-next + .swiper-slide {
                @apply opacity-100;
            }
            
            &:after {
                @apply content-[''] h-full absolute right-0 top-0 w-[1px];
                background: linear-gradient(180deg, hsla(0, 0%, 100%, 0), #0074e8 50%, hsla(0, 0%, 100%, 0));                
            }

            & > a {
                @apply text-sol-charcoal;
            }

            &:last-of-type::after {
                @apply hidden;                
            }
        }
    }
    .swiper-benefits-new__mobile {
        @apply pb-0;
        .swiper-wrapper {
            @apply flex-col;
        }
        .swiper-slide {
            @apply pb-[24px] mb-[24px] opacity-[1];
            &::after {
                @apply w-full h-[1px] bottom-0 top-[unset];
                background: linear-gradient(270deg, hsla(0, 0%, 100%, 0), #0074e8 50%, hsla(0, 0%, 100%, 0))
            }
            &:last-of-type {
                @apply pb-0 mb-0;
                &::after {
                    @apply hidden;
                }
            }
        }

    }
}