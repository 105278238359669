.block_SAI-CTA {
    @apply relative bg-contain bg-center bg-no-repeat;
    padding-bottom: 150px !important;
    padding-top: 150px !important;

    @media (max-width: 480px) {
        padding-bottom: 60px !important;
        padding-top: 60px !important;
        background-color: #ffffff !important;
    }

    .block-caption{
        @apply max-w-[660px] text-center relative z-[1] m-auto;

        @media screen and (min-width: 480px) {
            .btn.btn-primary {
                @apply inline-block;
            }
        }
    }
    &.bg-default,
    &.bg-dark-gray,
    &.bg-dark{
        * {
            @apply text-sol-white;
        }
    }

    &.bg-dark {
        background-image: url('/wp-content/uploads/sai-cta-bg.png');
    }

    @media (max-width: 768px) {
        &.bg-dark {
            background-image: url('/wp-content/uploads/sai-cta-bg-mobile.png');
        }
    }

    &.bg-white-2 {
        &.green-image-gradient {
            background-image: url('/wp-content/uploads/cta-green-bg-scaled.jpg');
        }

        &.blue-image-gradient {
            background-image: url('/wp-content/uploads/cta-blue-bg-scaled.jpg');
        }

        &.purple-image-gradient {
            background-image: url('/wp-content/uploads/cta-purple-bg-scaled.jpg');
        }

        &.pink-image-gradient {
            background-image: url('/wp-content/uploads/cta-pink-bg-scaled.jpg');
        }

        &.yellow-image-gradient {
            background-image: url('/wp-content/uploads/cta-yellow-bg-scaled.jpg');
        }

        &.light-blue-image-gradient {
            background-image: url('/wp-content/uploads/cta-light-blue-bg-scaled.jpg');
        }
    }

    @media (max-width: 480px) {
        &.bg-white-2 {
            background-size: cover;

            &.green-image-gradient {
                background-image: url('/wp-content/uploads/cta-green-bg-mobile.jpg');
            }
    
            &.blue-image-gradient {
                background-image: url('/wp-content/uploads/cta-blue-bg-mobile.jpg');
            }
    
            &.purple-image-gradient {
                background-image: url('/wp-content/uploads/cta-purple-bg-mobile.jpg');
            }
    
            &.pink-image-gradient {
                background-image: url('/wp-content/uploads/cta-pink-bg-mobile.jpg');
            }
    
            &.yellow-image-gradient {
                background-image: url('/wp-content/uploads/cta-yellow-bg-mobile.jpg');
            }
    
            &.light-blue-image-gradient {
                background-image: url('/wp-content/uploads/cta-light-blue-bg-mobile.jpg');
            }
        }
    }
}
