@media (min-width: 1200px) {
    body.baunfire-homepage .section:first-of-type {
        padding-top: 140px;
    }
}
.block_SAI-50-50 {
    .h1 {
        font-family: Inter;
        font-size: 56px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -1.12px;

        b {
            background: linear-gradient(92deg, #0074E8 4.38%, #B247FB 67.98%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    .btn-outline--blue span {
        background: linear-gradient(92deg, #0074E8 0%, #250144 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .btn-outline--blue:hover span {
        background: linear-gradient(92deg, #ffffff 0%, #ffffff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}
@media (min-width: 768px) and (max-width: 1000px) {
    .jgproduct-media img {
       max-width: 420px !important;
    }
}
@media (max-width: 500px)  {
    .jgproduct-media img {
    max-width: 430px !important;
    }
}
@media (max-width: 767px)  {
    .jgproduct-media img {
       float:right;
    }
    .pm-master {
        height: auto;
        min-height: 450px;
    }
}
@media (min-width: 768px)  {
.jgproduct-media {
    transform: translateY(-50%);

}
}
.for-product-page {
    background: radial-gradient(85.76% 169.52% at 50% 18%, #121212 0%, #250144 100%);
    min-height: 650px;
}

