.block_LOG002 {
    .block-caption {
        @apply h-full flex flex-col justify-center max-w-[500px];

        @media(max-width:991px) {
            @apply max-w-[inherit];
        }
    }

    .block-grid {
        @apply grid grid-cols-3 gap-[16px] m-0 p-0 h-full list-none;

        @media(max-width:991px) {
            @apply grid-cols-3;
        }

        @media(max-width:576px) {
            @apply grid-cols-2;
        }

        li {
            .block-card {
                @apply bg-sol-white h-full flex flex-col items-center justify-center p-[32px];

                img {
                    @apply max-w-[100%];
                }
            }
        }
    }

}