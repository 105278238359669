.block_AB012 {
    .block-caption {
        @apply max-w-[870px] mx-auto;
    }

    .block-card {
        @apply p-[32px] bg-sol-white dark:bg-sol-dark-gray h-full flex flex-col gap-[16px] items-start shadow-[0px_6px_10px_0px_#00000020];

        .card-icon {
            @apply h-[64px] w-auto;

            &.lottie {
                @apply max-w-fit;
            }
        }
    }
}