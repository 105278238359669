.block_AB013 {
    .block-caption {
        @apply max-w-[860px] mx-auto;
    }

    .block-card {
        @apply desktop:p-[48px] p-[32px] bg-sol-white dark:bg-sol-dark-gray h-full flex flex-col gap-[32px] items-start shadow-[0px_0px_20px_0px_rgba(0,0,0,.15)];

        .card-icon {
            @apply w-auto h-[64px];

            &.lottie {
                @apply max-w-fit;
            }
        }

        .btn {
            @apply font-bold;
        }
    }
    &.bg-light-blue .block-card,
    &.bg-transparent .block-card {
        @apply bg-sol-white;

    }
}