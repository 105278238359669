.sai-interactive-graphic {
    @apply relative overflow-y-visible;

    .block-caption {
        @apply flex items-start flex-col lg:pr-[80px];

        @media(max-width:767px) {
            max-width: inherit;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @apply mb-0;
        }

        .sub-heading {
            @apply flex flex-col gap-[20px];

            p {
                @apply mb-0;
            }
        }
    }

    &.flip-columns .block-caption {
        @apply lg:pr-0 lg:pl-[80px];

    }

    &.block-padding {
        &-top {
            @apply lg:pt-[120px] pt-[60px];
        }

        &-bottom {
            @apply lg:pb-[120px] pb-[30px];
        }
    }

    .block-media {
        @apply w-full h-full overflow-hidden relative;

        .bg-img,
        .bg-video {
            @apply object-cover h-full w-full z-0 block;
        }

        .icon-play {
            transform: translate(-50%, -50%);
            @apply absolute left-1/2 top-1/2 w-[90px] h-[90px] rounded-full bg-sol-royal-blue flex items-center justify-center cursor-pointer transition-all ease-in-out duration-200;            

            svg {
                @apply translate-x-[3px];
            }

            @media(min-width: 768px) {
                @apply w-[131px] h-[131px];
            }
        }
    }

    &.gradient::after {
        @apply content-[''] blur-[212px] absolute top-0 right-0 opacity-[0.34] h-[600px] w-[600px] z-0 rounded-[619.776px] bg-sol-royal-blue hidden lg:block;
    }

    small b {
        background: linear-gradient(to right, $ColorPrimary, $ColorPurple);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
    }
}

.embed-video {
    >iframe {
        @apply w-full h-full;
    }
}