.block_AB007 {
    .block-title {
        @apply max-w-[1030px] flex justify-center md:items-end items-start mx-auto;

        @media(max-width:767px) {
            @apply flex-col gap-[32px];
        }

        .heading {
            @apply text-sol-white m-0 flex-[1_1_auto];
        }

        .block-btn {
            @apply relative top-auto mt-auto flex-[1_0_auto]; /*md:top-[-10px]*/
        }
    }

    .block-card {
        @apply p-[32px] bg-sol-dark-gray h-full flex flex-col gap-[16px] items-start shadow-[0px_3px_10px_0px_rgba(0,0,0,0.08)];

        .card-icon {
            @apply h-[64px] md:mb-[48px] mb-[32px] w-auto;

            &.lottie {
                @apply max-w-fit;
            }
        }

        .heading,
        .text-paragraph {
            @apply text-sol-white;
        }
    }

    &.bg-light-blue {
        .block-title h2.heading {
            @apply text-sol-charcoal;
        }

        .block-title .heading {
            color: var(--color-black);
        }

        .block-card {
            @apply bg-sol-white;

            .card-caption {

                h6.heading,
                .text-paragraph>* {
                    @apply text-sol-charcoal;
                }
            }
        }
    }

    &.bg-white {
        .block-title h2.heading {
            @apply text-sol-charcoal;
        }

        .block-title .heading {
            color: var(--color-black);
        }

        .block-card {
            @apply shadow-[0px_-1px_9px_8px_rgba(248,249,250,1)] bg-sol-white;

            .card-caption {

                h6.heading,
                .text-paragraph>* {
                    color: var(--color-black);
                }
            }
        }
    }

    &.bg-light {
        .block-title h2.heading {
            @apply text-sol-charcoal;
        }

        .block-title .heading {
            color: var(--color-black);
        }

        .block-card {
            background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));

            .card-caption {

                h6.heading,
                .text-paragraph>* {
                    color: var(--color-black);
                }
            }
        }
    }
}