.symphonyai-footer {
    .menu {
        @apply list-none m-0 tablet:p-0 phablet:pr-[48px] pr-0 tablet:max-w-[205px] phablet:max-w-max max-w-[280px];
            
        li > a {
            @apply text-[16px] block py-[8px] px-0 font-bold no-underline text-sol-white hover:text-sol-royal-blue font-sans duration-200 ease-in-out;
        }

        ul li {
            a {
                @apply font-sans font-normal opacity-[0.7] text-[16px] block py-[8px] px-0 no-underline text-sol-white hover:text-sol-royal-blue hover:opacity-[1] duration-200 ease-in-out;
            }

            &.title-item a {
                @apply no-underline font-sans opacity-[1] py-[16px] px-0 max-[991px]:text-[20px] max-[991px]:leading-[28px] max-[991px]:tracking-[-0.02em];                
            }

            &.collapse-item {
                .sub-menu {
                    @apply max-h-0 overflow-hidden duration-200 ease-in-out;
                }

                >a {
                    @apply font-sans opacity-[1] py-[12px] pr-[30px] pl-0 relative block border-b border-x-0 border-t-0 border-solid border-[#EAEAEA30] hover:text-sol-royal-blue;

                    &::after {                    
                        @apply content-[''] bg-[$IconArrowCollapseWhite] w-[8px] h-[5px] absolute right-0 top-[50%] mt-[-3px] bg-contain rotate-0 duration-200 ease-in-out;
                    }
                }

                &.active {
                    .sub-menu {
                        @apply h-auto max-h-fit;
                    }

                    >a {
                        @apply text-sol-royal-blue font-sans border-b-transparent;

                        &::after {
                            @apply rotate-[-180deg] bg-[$IconArrowCollapseWhite];
                        }
                    }
                }
            }
        }
    }

    .legal__links {
        @apply flex desktop:flex-row flex-wrap list-none p-0 gap-y-0 desktop:gap-x-[64px] gap-x-[24px] md:m-0 mt-[32px];
    }

    .sitemap-footer {
        @apply grid md:grid-cols-[repeat(4,minmax(0,1fr))] lg:mb-0 md:mb-[60px] mb-[40px];
    }

    .menu__bottom a {
        color: rgb(13, 110, 253);
    }

    .block-subscribe {
        /* hubspot form*/
        .hbspt-form {
            .hs-form { 
                @apply flex items-center gap-[10px];
            }
            .hs-error-msgs,
            .hs-email label,
            .legal-consent-container { 
                @apply hidden #{!important};
            }
            .hs-email .input input {
                @apply  bg-[var(--color-grey)] border border-solid border-transparent text-[var(--color-body)] text-[14px] h-[48px] py-[8px] px-[16px] w-full rounded-[3px] outline-none;

                &.error {
                    @apply border border-solid border-sol-red;
                }
            }
            .hs-button {
                @apply bg-sol-royal-blue border border-solid border-sol-royal-blue text-sol-white py-[15px] px-[25px] text-[14px] font-bold w-[238px] rounded-[9999px] appearance-none text-center;
            }
            .submitted-message {
                @apply dark:text-sol-white text-charcoal;
            }
        }  
    }

    .block-subscribe__horizontal {
        @apply lg:mb-[64px] mb-[24px] -mt-[21px];

        /* hubspot form*/
        .hbspt-form {
            .hs-form { 
                @apply flex lg:flex-row flex-col items-center gap-[10px];
            }
            .hs-error-msgs,
            .hs-email label,
            .legal-consent-container { 
                @apply hidden #{!important};
            }
            .hs-email .input input {
                @apply  bg-[var(--color-grey)] border border-solid border-transparent text-[var(--color-body)] text-[14px] h-[48px] py-[8px] px-[16px] w-full rounded-[3px] outline-none;

                &.error {
                    @apply border border-solid border-sol-red;
                }
            }
            .hs-button {
                @apply bg-sol-royal-blue border border-solid border-sol-royal-blue text-sol-white py-[15px] px-[25px] text-[14px] font-bold w-[238px] rounded-[9999px] appearance-none text-center;
            }
            .submitted-message {
                @apply dark:text-sol-white text-charcoal;
            }
        }
    }
}