.block_AB002 {
    .block-background-image {
        @apply bg-cover bg-center;
    }

    .block-btn {
        @apply lg:flex block lg:gap-[20px];
    }

    .block-caption {
        @apply gap-[24px] flex flex-col md:pr-[48px] pr-0;

        @media(max-width:767px) {
            @apply mb-[40px];
        }

        .heading {
            @apply max-w-[429px];
        }

        .eyebrow,
        .heading,
        .sub-heading {
            @apply text-sol-charcoal;
        }

        ul {
            @apply ml-[30px] flex flex-col gap-[10px];
            li {
                @apply list-disc;
            }
        }
    }

    .block-media {
        @apply rounded-[16px] overflow-hidden;

        &.video,
        &.no-border-radius {
            @apply rounded-none;
        }
    }

    .block-video {
        @apply aspect-video rounded-[12px] overflow-hidden;

        iframe {
            @apply w-full h-full;
        }
    }

    .block-img {
        img {
            @apply w-full h-full object-cover block;
        }

        &.lottie {
            @apply shadow-[0px_10px_30px_0px_#0000001A];
        }
    }

    &.bg-default,
    &.bg-dark {
        .block-caption {
            .eyebrow,
            .heading,
            .sub-heading {
                @apply text-sol-white;
            }
        }
    }

    &.flip-columns .block-caption {
        @apply md:pl-[48px] pl-0 pr-0;
    }
}