.block_AB008 {
    @apply overflow-hidden;

    .asset-item {
        @apply h-full relative overflow-hidden md:rounded-[16px] rounded-[8px] transition-all duration-200 ease-in-out hidden max-[767px]:mt-[32px] max-[576px]:max-h-[380px] max-[576px]:mt-[20px];

        .image-container {
            @apply w-full h-full object-contain;
        }
    }

    .asset-group {
        @apply md:pr-[48px] overflow-hidden pr-0 md:h-full h-[400px] md:mb-0 mb-[40px];
    }

    .accordion-collapse.collapse {
        @apply hidden;

        &.show {
            @apply block;
        }
    }

    .accordion-header {
        @apply mb-0 pb-[32px];
    }

    .accordion {
        @apply rounded-none border-none;

        .accordion-item {
            @apply rounded-none border-0 m-0 relative duration-200 ease-in-out;

            .accordion-button {
                @apply text-[#052c65] dark:text-sol-white desktop:text-[28px] md:text-[24px] text-[22px] desktop:leading-[40px] md:leading-[30px] leading-[24px] font-semibold bg-transparent p-[20px_0px_32px] appearance-none opacity-[.5] relative border-t-[2px] border-solid border-[#25282E20] dark:border-sol-white rounded-none transition-all duration-200 ease-in-out shadow-none w-full text-left outline-none border-x-0 border-b-0 pb-0
                        before:content-[''] before:absolute before:top-0 before:left-0 before:w-0 before:bg-sol-green before:duration-[8s,0.01s]
                        after:content-[''] after:absolute after:top-[55%] after:right-0 after:w-[12px] after:h-[7px] after:opacity-[.5] after:bg-contain after:dark:brightness-100;                        

                &.collapsed {
                    @apply text-[#25282e] dark:text-sol-white opacity-[.5];
                }

                &::before {
                    background-color: sol-green;
                    transition: width 8s ease-in-out, background-color .01s ease;
                }
                &::after {
                    background-image: $IconChevronUp;
                }

                &.open {
                    @apply before:transition-none text-[#052c65] dark:text-sol-white;
                }

                &:hover,
                &:focus,
                &:active {
                    @apply outline-none shadow-none;
                }

                &:hover {
                    @apply opacity-100 text-[#25282e] dark:text-sol-white;
                }

                &[aria-expanded="true"] {
                    @apply opacity-100 before:w-full before:h-[4px] after:opacity-100;
                }
            }

            .accordion-body {
                @apply p-[0px_0px_32px] max-[992px]:p-0;

                .accordion-description p {
                    @apply dark:text-sol-white;
                }

                .btn-link {
                    @apply py-[16px];
                }

                .asset-item {
                    @apply max-h-[500px] block overflow-hidden rounded-[8px] mb-[32px] min-[992px]:hidden;

                    img {
                        @apply w-full h-full block object-cover;
                    }
                }
            }
        }
    }
}