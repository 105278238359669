.block_TXT006 {
    &.pt-0-mobile {
        @media(max-width: 600px) {
            @apply pt-[0] #{!important};
        }
    }

    .block-card { 
        @apply h-full flex flex-col bg-sol-white dark:bg-[#17191B] dark:rounded-[12px];

        img {
            @apply w-full block object-contain max-h-[150px] m-[50px_0_35px];

            @media screen and (max-width: 767px) {
                @apply p-0;
            }
        }

        .block-caption {
            @apply flex flex-col desktop:p-[32px_48px] p-[32px];            
        }

        .card-caption {
            @apply flex flex-col dark:p-[0_50px_50px];

            .rich-text {
                @apply mb-[36px] dark:[&_p]:text-sol-white;
            }
            .heading {
                @apply dark:text-sol-white;
            }
        }

        &.block-card--w-full .card-icon {
            @apply w-full object-cover h-full my-0 mx-auto;            
        }
    }

    &.bg-light-blue .block-card {
        @apply p-[30px] rounded-[16px];        
    }
}