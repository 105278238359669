.block_HR003 {
    @apply relative;
    
    &.section {
        @apply pb-[60px] #{!important};         
    }
    
    .block-caption {
        @apply flex items-start gap-[24px] flex-col;

        @media(max-width: 767px) {
            @apply mb-[48px] max-w-[inherit];
        }        
    }

    .block-media {
        @apply rounded-[16px] w-full h-full overflow-hidden relative;

        .bg-img,
        .bg-video {
            @apply object-cover h-full w-full z-0 block;
        }

        .icon-play {
            transform: translate(-50%, -50%);
            @apply absolute left-1/2 top-1/2 md:w-[131px] w-[90px] md:h-[131px] h-[90px] rounded-full  bg-sol-royal-blue duration-200 ease-in-out flex items-center justify-center cursor-pointer;
            
            svg {
                @apply translate-x-[3px];
            }
        }
    }
    
    .block-well-content{
        @apply md:mt-[80px] mt-[40px] relative;

        &::after {
            @apply content-[''] h-[60%] block absolute bg-transparent w-full bottom-0 z-[-1] top-[auto];
        }

        .block-well{
            background: linear-gradient(94.8deg, rgba(243, 249, 254, 1) 0%, rgba(252, 252, 252, 1) 99.67%);
            @apply items-start rounded-[12px] flex gap-[70px] overflow-hidden relative p-[40px] shadow-[0px_10px_30px_0px_#00000047];
            
            @media(max-width:1199px){
                @apply gap-[40px];
            }
            @media(max-width:991px){
                @apply flex-col gap-[30px] p-[30px];
            }
            
            &::after{
                @apply content-[''] bg-[rgba(81,81,81,.33)] blur-[10px] absolute w-full h-full left-0 top-0 z-[-1];
                
            }
            .block-media{
                @apply hidden;

                .block-img,
                .block-video{
                    @apply w-[480px] block rounded-[4px] max-h-[290px] relative overflow-hidden;

                    @media(max-width:1024px){
                        @apply w-[420px];
                    }

                    @media(max-width:991px){
                        @apply w-full;
                    }

                    .icon-play{
                        transform: translate(-50%, -50%);
                        @apply absolute z-[1] top-1/2 left-1/2 w-[80px] h-[80px];

                        svg{
                            @apply w-full h-auto;
                        }
                    }
                    img {
                        @apply rounded-[15px] h-full object-cover w-full;
                    }
                }
            }

            .block-text{
                @apply flex gap-[70px] items-start;
                @media(max-width:991px){
                    @apply flex-col gap-[30px];
                }

                .heading,
                .text-paragraph{
                    @apply text-sol-white;
                }

                .block-title{
                    @apply max-w-[480px];
                }

                .cta-group{
                    flex-flow: row wrap;
                    @apply flex gap-[20px];

                    .btn{
                        @apply mb-0;
                    }
                }

                .block-description{
                    @apply flex flex-col gap-[20px];
                }
            }

            &.with-media{
                @apply items-center;

                @media(max-width:991px){
                    @apply items-start;
                }

                .block-media{
                    @apply block w-full;
                }

                .block-text{
                    @apply flex flex-col gap-[20px] w-full;

                    .block-title{
                        max-width: inherit;
                    }
                }
            }
            
            @media(max-width:768px){
                @apply gap-[30px] p-[16px] flex-col;
            }
        }
    }
    
    &.campaign-mode-1,    
    &.campaign-mode-2 {
        .heading {
            @media(min-width:1440px) {
                @apply max-w-[75%];
            }
        }
    }
    
    &.campaign-mode-1{
        @apply mb-[130px];
        @apply pt-[100px] #{!important};
        
        .block-well-content {          
            @apply mb-[-120px];  

            .block-well .block-text {
                .heading, 
                .text-paragraph {
                    @apply text-sol-charcoal;
                }
            }
        }
    }
    
    &.campaign-mode-2{
        @apply mb-[168px];
        @apply pt-[200px] #{!important};

        .block-well-content {
            @apply mb-[-190px];

            .block-well .block-text {
                .heading, 
                .text-paragraph {
                    @apply text-sol-charcoal;
                }
            }
        }

        .bg-video {
            @apply min-h-[720px];
        }    

        &.bottom-dark .block-well-content{
            @apply after:bg-sol-charcoal;
        }
        
        &.bottom-light .block-well-content {
            @apply after:bg-sol-white;                
        }
    }
}

.embed-video > iframe {
    @apply w-full h-full;    
}
