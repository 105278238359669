.block_AB009 {
    .block-title {
        @apply max-w-[870px]  mb-[40px] mx-auto;
    }
    
    .block-caption {
        @apply mb-[48px] pb-[48px] border-b-[1px] border-x-0 border-t-0 border-solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(to left, #972DED, $ColorPrimary);

        &:last-child {
            @apply border-none mb-0 pb-0;
        }

        .block-card {
            @apply p-[30px] rounded-[12px] h-full flex flex-col gap-[20px] items-start bg-sol-white dark:bg-sol-dark-gray shadow-[0px_3px_10px_0px_rgba(0,0,0,0.08)];

            .card-icon {
                @apply max-h-[52px] w-auto;

                &.lottie {
                    @apply max-w-fit;
                }
            }
        }
    }

    &.block_AB009--logos {
        background-color: var(--color-grey) !important;

        .block-card {
            @apply items-center justify-center #{!important};
            @apply md:p-[96px_32px] p-[48px_32px] md:min-h-[300px] min-h-[260px];
        }
    }
}