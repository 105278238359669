.block_LOG001 {
    .swiper {
        .swiper-wrapper {
            @apply ease-linear;

            .swiper-slide img {               
                @apply h-auto;
            }
        }

        &.disable-swiper {
            .swiper-wrapper {
                flex-flow: row wrap;
                @apply w-full p-0 items-center justify-center;

                .swiper-slide {
                    @apply m-[1rem_0] md:h-[64px] h-[40px] w-auto;

                    img {
                        @apply opacity-100 [filter:invert(0.33)_grayscale(1)_brightness(1.5)] #{!important};
                        @apply md:h-[64px] h-[40px];
                    }
                }
            }
        }

    }


    .btn-outline-white:hover span {
        @apply text-sol-charcoal;        
    }


    &.bg-media {
        @apply relative;
        @apply pb-[270px] #{!important};

        .container {
            @apply relative z-[1];
        }

        .bg-img,
        .bg-video {
            @apply object-cover absolute bottom-0 left-0 h-1/2 w-1/2 z-0;

            @media(max-width:767px) {
                @apply h-[275px];
            }
        }

        .bg-video {
            @apply mix-blend-color-dodge;
        }
    }

    &.bg-default,
    &.bg-dark-gray,
    &.bg-dark {
        * {
            @apply text-sol-white;
        }
    }

    &.bg-default .swiper .swiper-wrapper .swiper-slide img {
        @apply opacity-70;                 
    }

    &.bg-default,
    &.bg-light-blue {
        .swiper .swiper-wrapper .swiper-slide img {
            filter: invert(0.33);            
        }
    }
    .unmute-color-band img {
        filter: invert(0) !important;
    }
}