.block_DATA002 {
	&.bg-default,
	&.bg-light-blue {
		.events-list li a {
			@apply bg-sol-white;
		}
	}

	.block-caption {
		@apply max-[1200px]:mb-[48px];		
	}

	.events-list {
		@apply m-0 p-0 list-none;

		li {
			@apply mb-[16px];

			a {
				@apply flex flex-col no-underline transition-all duration-200 bg-sol-light-blue2 p-[32px] text-[initial];

				.top-card {
					@apply flex justify-between min-[992px]:mb-[64px] mb-[48px];
				}

				.event-label span {
					@apply text-[14px] font-semibold uppercase leading-[18px] tracking-widest;
				}
				
				.event-caption {
					@apply relative;
				}

				&:hover .event-caption {
					@apply text-sol-royal-blue;					
				}
			}
		}
	}
}
