.form-wrapper {
  .form-select-vertical {
    .form-select-vertical__wrapper {
      @apply pr-0;

      select {
        @apply relative z-[2];
      }
    }
  }

  .gform-body {
    .ginput_container {
      &.ginput_container_select {
        @apply bg-[--color-grey];

        select {
          @apply relative z-[2] bg-[transparent];
        }
      }
    }
  }

  .nylon-hs-form {
    form {
      .field {
        &.hs-fieldtype-select {
          .input {
            select.hs-input {
              @apply relative z-[2];
            }
          }
        }
      }
    }
  }
}