.sai-spotlight {
    .swiper-spotlight {
        @apply pb-[20px];
    }

    .swiper-button {
        @apply absolute z-10 w-full bottom-[20px];
    }

    .swiper-button-prev {
        &::after {
            @apply text-[14px] text-white border-[1px] border-solid border-[#fff] rounded-[24px] w-[32px] h-[32px] z-10 relative;
            padding: 8px 14px 8px 11px;
        }
    }

    .swiper-button-next {
        &::after {
            @apply text-[14px] text-white border-[1px] border-solid border-[#fff] rounded-[24px] w-[32px] h-[32px] z-10 relative;
            padding: 8px 12px 8px 13px;
        }
    }

    @media (max-width: 768px) {
        padding-bottom: 0 !important;
    }
    @media (min-width: 769px) {
        .product-well-card {
            padding: 72px 144px 0px 144px;
        }
    }

    .product-well-card {
        padding-top:72px;
        background: linear-gradient(181deg, #FFF 2.47%, rgba(250, 250, 250, 0.49) 40.03%);
        background: #fafafa;
        border-radius: 9px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;
        align-self: stretch;
    }
}


.product-well-card .swiper-button {
    width: auto !important;
}


.product-well-card .swiper-button-next:after, .product-well-card .swiper-button-prev:after {
    border:none !important;
    color: #000;
    font-size: 20px !important;
    height: auto;
    width: auto;
}


.product-well-card .swiper-pagination-bullet {
    width: 100px;
    border-radius: 0px;
    height: 2px;
    max-width: 100%; /* Ensures bullets fit within */
    flex-grow: 1;
}

.product-well-card .swiper-pagination-bullet-active {
    opacity: var(--swiper-pagination-bullet-opacity, 1);
    background: #838080;
}


.product-well-card .swiper-pagination {
    display: flex;
    justify-content: left;
    left: 15px;
    max-width: 80%;
    overflow: hidden;
    right: 0;
    width: 80%;
    float: left;
    text-align: left;
}

