.block_TXT001 {
    .heading-cta {
        @apply p-[8px_18px];
    }

    .heading-text {
        @apply pt-[16px];
    }

    .block-caption {
        @apply flex items-center justify-center h-full;

        .caption-inner {
            @apply md:max-w-[400px] max-w-[inherit] p-[64px_32px];
        }

        &.block-caption--rich-text {
            @media(min-width: 768px) {
                aspect-ratio: 1;
            }

            .caption-inner {
                @apply md:w-[400px];
            }

            .color-dark {
                color: var(--color-charcoal) !important;
            }
        }
    }

    .block-grid .row {
        @apply m-0;

        .col-span-12 {
            @apply p-0;
        }
    }

    .block-img {
        background-position: center center;
        @apply overflow-hidden h-full bg-cover bg-no-repeat;

        img {
            @apply w-full h-auto block;
        }
    }

    &.bg-default,
    &.bg-secondary {
        .heading,
        .text-paragraph-large {
            @apply text-sol-white;
        }
    }
}