.block_AB014 {    
    &.center-pos {
        .block-caption,
        .text-paragraph-col {
            @apply text-center mx-auto;
        }
        .block-caption {
            @apply max-md:pt-[40px] max-md:mb-0;
        }

        .nav-wrapper {
            @apply items-center flex flex-wrap justify-center;
        }
    }

    &.bg-default {
        @apply bg-sol-charcoal;

        .heading,
        .text-paragraph-col p,
        .tab-content ul li,
        .tab-content ol li,
        .tab-content p {
            @apply text-sol-white;
        }
        
        .nav-pills {
            @apply bg-[#181A1C];

            .nav-link {
                @apply text-sol-white;
            }
        }

        .tab-pane .tap-mob-heading a {     
            @apply text-sol-white;
        }

        .mob-tabpane-toggler svg path {
            @apply stroke-white;
        }
        
        .tab-pane.active .tap-mob-heading a {
            @apply text-sol-light-blue2;
        }
    }

    .tap-mob-heading {
        @apply hidden;
    }

    .block-video iframe {
        @apply w-full max-w-[640px];
    }

    .block-media {
        @apply rounded-[16px] w-full h-full overflow-hidden relative;

        .bg-img,
        .bg-video {
            @apply object-cover h-full w-full z-0 block;
        }

        .icon-play {
            @apply absolute left-1/2 top-1/2 md:w-[131px] w-[90px] md:h-[131px] h-[90px] rounded-full duration-200 ease-in-out flex items-center justify-center cursor-pointer bg-sol-royal-blue;
            transform: translate(-50%, -50%);

            svg {
                @apply translate-x-[3px];
            }
        }
    }

    .nav-wrapper {
        @apply max-[816px]:hidden #{!important};
    }

    .tap-mob-heading {
        @apply max-[816px]:block;
    }

    .tab-pane {
        @apply hidden max-[816px]:block max-[816px]:opacity-100;

        .tap-mob-heading {
            @apply max-[816px]:border-[1px] max-[816px]:border-solid max-[816px]:border-[#e7e7e7] max-[816px]:p-[20px_0] max-[816px]:border-x-0 max-[816px]:border-b-0;
        }

        .tabpane-inner-content {
            @apply max-[816px]:hidden;
        }

        &.show {
            @apply block;
        }
    }

    .mob-tabpane-toggler {
        @apply max-[816px]:items-center max-[816px]:flex max-[816px]:justify-between max-[816px]:w-full;
    }
     
    .tab-pane.active {
        .mob-tabpane-toggler svg {
            @apply max-[816px]:rotate-[180deg];
        }
        .tap-mob-heading a {  
            @apply max-[816px]:text-sol-royal-blue; 
        }
        .tabpane-inner-content {
            @apply max-[816px]:block;

            .row {
                @apply max-[816px]:flex-col-reverse;
            }
        }
    }

    .tab-pane .tap-mob-heading a {
        @apply max-[816px]:text-[16px] max-[816px]:tracking-[0.32px] max-[816px]:font-bold max-[816px]:text-sol-charcoal; 
    }

    .tab-content {
        @apply max-[816px]:pt-0;
    }
    
    .block-caption {
        @apply mb-[24px] max-w-[1056px];
    }

    .text-paragraph-col {
        @apply mb-[40px] max-w-[1029px];
    }

    .nav-pills {
        @apply rounded-[51px] inline-flex p-[4px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)];

        .nav-link.active,
        .show>.nav-link {
            @apply rounded-[51px] font-bold bg-sol-royal-blue text-sol-white border-none;
        }

        .nav-link {
            @apply rounded-[51px] text-[16px] font-bold p-[16px] text-sol-charcoal border-none bg-transparent;
        }

        .nav-item {
            @apply mr-[15px] last-of-type:mr-0;
        }
    }

    .tab-content {
        @apply pt-[56px];

        .tab-pane {
            @apply relative;
        }

        ul {
            @apply ml-[20px];

            li {
                @apply text-[16px] leading-[32px] list-disc mb-0;
            }
        }
    }
}