@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body.baunfire-homepage {	
	@apply font-sans overflow-x-hidden text-[18px] font-normal leading-[26px] relative text-sol-charcoal antialiased m-0;

	&.page-id-38106 {
		@apply overflow-x-auto;
	}
	
	&.page-id-72264 {
		@apply bg-sol-charcoal;
	}

	.container {
		@apply max-w-[1240px];
	}
	
	.section {
		@apply py-[60px] lg:py-[80px] px-0;

		&:not(.section-hero):nth-of-type(1) {
			@apply pt-[120px] pb-[60px];
		}

		&:nth-of-type(1) {
			@apply desktop:pt-[210px] pb-[60px] lg:pt-[120px] pt-[180px];
	
			&.vh-100 {
				@media (max-height: 680px) {
					@apply h-auto #{!important};
				}
			}
			
			&.block_HR002 {			
				@apply pt-[130px] #{!important};
			}
	
			&.block_HR003 {
				@apply md:pt-[210px] pt-[150px] #{!important};
			}

			&.block_SAI-Hero-Banner {
				@apply md:pt-[210px] pt-[150px] #{!important};
			}
		}

		&.\!pb-0 {
			@apply pb-0 #{!important};
		}

		&.\!pt-0 {
			@apply pt-0 #{!important};
		}

		&.block_HR004 {
			@apply pb-[80px];
		}

		&.block_TXT004 {
			@apply py-[120px] px-0;
		}

		&.block_VID001 {
			@apply pb-[120px];
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.heading {
		@apply font-sans font-semibold;

		b,
		strong {
			@apply font-semibold;			
			background: linear-gradient(to right, $ColorRoyalBlue, $ColorDarkPurple);
			-webkit-background-clip: text;
			background-clip: text;
			-webkit-text-fill-color: transparent;
		}

		span.highlight {
			background-color: rgba($color:$ColorPrimary,$alpha:0.5);
			@apply inline-block py-0 px-[7px] md:ml-[-7px];
		}
	}

	p,
	a,
	ul,
	li {
		@apply font-sans;
	}

	.bg-primary {
		@apply bg-sol-royal-blue;
	}

	.bg-secondary {
		@apply bg-sol-dark-purple
	}

	.bg-default {
		@apply bg-sol-charcoal;
	}

	.bg-dark-gray {
		@apply bg-sol-dark-gray;
	}

	.bg-light-blue {
		@apply bg-sol-light-blue2;
	}

	.color-primary {
		@apply text-sol-royal-blue;
	}

	.color-secondary {
		@apply text-sol-dark-purple;
	}

	.color-default {
		@apply text-sol-dark-purple;
	}

	.color-light-blue {
		@apply text-sol-light-blue2;
	}

	.color-white {
		@apply text-sol-white;
	}

	.color-gradient {
		@apply bg-clip-text text-transparent bg-gradient-to-r from-sol-royal-blue to-sol-light-purple;
	}

	.lb-green,
	.labels-group span.green div::before {
		@apply bg-sol-green;
	}

	.lb-pink,
	.labels-group span.pink div::before {
		@apply bg-sol-pink;
	}

	.lb-dark-purple,
	.labels-group span.dark-purple div::before {
		@apply bg-sol-dark-purple;
	}

	.lb-purple,
	.labels-group span.purple div::before,
	.lb-light-purple,
	.labels-group span.light-purple div::before {
		@apply bg-sol-light-purple;
	}

	.lb-yellow,
	.labels-group span.yellow div::before {
		@apply bg-sol-yellow;
	}

	.lb-blue,
	.labels-group span.blue div::before {
		@apply bg-sol-royal-blue;
	}

	.lb-light-blue,
	.labels-group span.light-blue div::before {
		@apply bg-sol-light-blue;
	}

	.lb-orange,
	.labels-group span.orange div::before {
		@apply bg-sol-orange;
	}

	.btn {
		text-transform: initial;
		@apply font-sans border border-solid font-semibold;
		
		@media (max-width: 767px) {
			@apply block text-center;
		}

		&.btn-outline-white {
			@apply border-sol-white text-sol-white;

			&:hover {
				@apply bg-sol-white text-sol-charcoal;
			}

			@media (max-width: 480px) {
				@apply block text-center;
			}
		}

		&.btn-outline-primary {
			@apply text-sol-dark-primary3 border-sol-royal-blue;

			&:hover {
				@apply bg-sol-royal-blue text-sol-white;
			}
		}

		&.btn-chevron span {
			@apply relative;

			&::after {
				background-image: url("data:image/svg+xml,%3Csvg width='7' height='11' viewBox='0 0 7 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5.5L1 10' stroke='%230074E8' stroke-width='2'/%3E%3C/svg%3E%0A");				
				@apply content-[''] absolute right-[-18px] top-[4px] w-[10px] h-[14px] bg-contain;
			}
		}

		&.btn-primary {
			@media (max-width: 480px) {
				@apply block text-center;
			}
		}

		&.btn-link {
			@apply relative text-[18px] z-[1];
		}
	}

	.labels-group {
		@apply flex items-center gap-[16px_32px];

		span {
			@apply text-[16px] leading-[20px] flex items-center text-sol-charcoal;

			i {
				@apply w-[10px] h-[10px] mr-[10px] rounded-full min-w-[10px];
			}			
		}

		.nav-link {
			div.line {
				@apply hidden;
			}
			&.active {
				div.line {
					@apply block absolute bottom-[10px] left-0 w-full h-[3px];
				}
			}
		}
	}

	.flip-columns .row {
		@apply flex-row-reverse;
	}

	.modal-dialog {
		@apply transition-all duration-[0.5s] ease-in-out mt-[1.875rem] max-w-[988px] z-[9];

		.modal-content {
			@apply rounded-[18px];

			.modal-body {
				@apply p-[32px] lg:p-[64px];

				.card-img {
					@media (max-width: 576px) {
						@apply max-w-[300px] mb-[32px];
					}

					img {
						@apply w-full h-auto object-cover;
					}
				}

				.card-body {
					@apply md:pl-[48px] lg:pl-[64px] pl-[20px];

					.card-name {
						@apply relative mb-[48px];
					}

					.card-content {
						@apply lg:max-h-[380px] overflow-auto pr-[10px] md:pr-0;

						&::-webkit-scrollbar {
							@apply w-[5px] rounded-[18px];
						}

						&::-webkit-scrollbar-track {
							@apply bg-sol-gray;
						}

						&::-webkit-scrollbar-thumb {
							@apply bg-[#1a2d39] rounded-[18px] hover:bg-[#1a2d39];
						}
					}

					.btn-close {
						@apply absolute tablet:top-[32px] top-[16px] tablet:right-[32px] right-[16px] p-0 w-[40px] h-[40px] rounded-[50%] z-[999] opacity-[1] filter-none;
						@apply bg-sol-charcoal #{!important};
					}
				}
			}
		}

		&.lightbox {
			.modal-dialog {
				@apply mt-0;

				.modal-content {
					@apply rounded-[16px] overflow-hidden;

					.btn-close {
						@apply w-[20px] h-[20px] p-[10px] top-[5px] right-[5px] #{!important};

						svg {
							@apply top-[-4px] w-[50%] #{!important};
						}
					}
				}
			}
		}
	}

	.bg-white-2 {
		background-color: #fafafa;
	}
}

img {
	@apply max-w-full;
}

a {
	@apply no-underline text-sol-royal-blue;
}

ul {
	@apply mb-0 pl-0 list-none;

	li {
		@apply list-none;
	}
}

.husl-block.hero-block .text-left {
	@apply mb-0 #{!important};
}

.nav-link:focus-visible {
	@apply shadow-none;
}

.block-sticky {
	@apply md:sticky relative md:top-[120px] top-[auto] md:mt-[-160px] mt-0;
}

.bg-light {
	@apply bg-[#f8f9fa];
}

table tbody {
	td, 
	th {
		@apply text-[16px] #{!important};
	}
}


/* General - Loading Indicator */

.loading-wrapper {
	@apply relative h-full;
}

.profile-main-loader {
	@apply ml-[-100px] mt-[-100px] w-[45px];
	@apply fixed left-[50%] top-[50%] z-[9000] #{!important};	
	.loader {
		@apply relative my-0 mx-auto w-[200px] h-[200px];
		&:before {
			@apply content-[''] block pt-[100%];
		}
	}
}

.circular-loader {
	-webkit-animation: rotate 2s linear infinite;
	animation: rotate 2s linear infinite;
	@apply h-full origin-center w-full absolute top-0 left-0 m-auto;
}

.loader-path {
	stroke-dasharray: 150, 200;
	stroke-dashoffset: -10;
	-webkit-animation:
		dash 1.5s ease-in-out infinite,
		color 6s ease-in-out infinite;
	animation:
		dash 1.5s ease-in-out infinite,
		color 6s ease-in-out infinite;
	stroke-linecap: round;
}

@-webkit-keyframes rotate {
	100% {
		@apply rotate-[360deg];
	}
}

@keyframes rotate {
	100% {
		@apply rotate-[360deg];
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124;
	}
}

@-webkit-keyframes color {
	0% {
		stroke: $ColorPrimary;
	}

	40% {
		stroke: $ColorPrimary;
	}

	66% {
		stroke: $ColorPrimary;
	}

	90% {
		stroke: $ColorPrimary;
	}
}

@keyframes color {
	0% {
		stroke: $ColorPrimary;
	}

	40% {
		stroke: $ColorPrimary;
	}

	66% {
		stroke: $ColorPrimary;
	}

	90% {
		stroke: $ColorPrimary;
	}
}

.team-modal-btn {
	@apply outline-none appearance-none bg-transparent border-none;
}

.block-paragraph {
	ul li {
		@apply relative pl-[1.75rem] mb-[0.9rem];

		&:before {
			@apply content-[''] absolute top-[10px] left-[0.4rem] w-[0.45rem] h-[0.45rem] bg-[inherit] rounded-[50%];
		}
	}

	ol {
		counter-reset: list-number;
		@apply list-none;

		li {
			@apply relative pl-[1.75rem] mb-[0.9rem];

			&:before {
				counter-increment: list-number;
				content: counter(list-number) ". ";
				@apply absolute top-[5px] left-[0.4rem] rounded-[50%] text-center text-[inherit] text-[24px] leading-[1];
			}
		}
	}
}
.active_menu_link {
	@apply text-sol-royal-blue #{!important};
}

.button-block {
	@apply gap-[10px];
}

.btn-top {
	@apply mt-[32px];
}

.breadcrumb-inner,
.baunfire-breadcrumb.breadcrumb-dark .breadcrumb-inner {
	@apply text-sol-royal-blue #{!important};
	span.current {
		@apply text-sol-royal-blue;
	}
}

.block-card {
	@apply relative;
	&__cover {
		@apply absolute w-full h-full z-[99];
	}
}

.arrow-link {
	@apply bg-[url("../img/chevron-right.svg")] bg-no-repeat bg-right brightness-0 invert pr-[25px] relative;
	span {
		@apply text-sol-white font-bold;
	}
}

h1,
.h1,
h1.heading-1,
h1.heading-default,
h1.page-title,
h1.page-title--medium,
.h-size-76 {
	@apply text-[40px] font-semibold leading-[120%] -tracking-[0.8px] lg:text-[56px] lg:-tracking-[-1.12px] mt-0;
}

h2,
.h2,
.heading-2,
h2.heading-default {
	@apply text-[36px] font-semibold leading-[120%] lg:text-[48px] mt-0;
}

h3,
.h3,
.heading-3,
h3.heading-default {
	@apply text-[30px] font-semibold leading-[120%] lg:text-[36px] mt-0;
}

h4,
.h4,
.heading-4,
h4.heading-default {
	@apply text-[24px] font-semibold leading-[140%] lg:text-[28px] mt-0;
}

h5,
.h5,
.heading-5,
h5.heading-default {
	@apply text-[20px] font-semibold leading-[120%] tracking-[0.4px] lg:text-[22px] lg:tracking-[0.44px] mt-0;
}

h6,
.h6,
.heading-6,
h6.heading-default {
	@apply text-[18px] font-semibold leading-[120%] mt-0;
}

.text-overline {
  @apply text-[18px] font-semibold leading-[120%] tracking-[0.4px];
}

.text-paragraph,
.text-paragraph-large {
	@apply text-[16px] leading-[25.2px] font-normal; 
	p {
		@apply text-[16px] leading-[25.2px] font-normal; 
	}
}

.sub-heading {
	@apply text-[18px] leading-[25.2px] font-sans;
	@apply font-normal #{!important};
	
	p {
		font-size: inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
		margin-top: 0;
	}
}

.bh {
	.text-paragraph {
		p {
            @apply text-[16px] leading-[25.2px] font-sans font-normal;
		}

		&.sub-heading {
            @apply text-[18px] leading-[25.2px] font-sans font-normal;

			p {
				font-size: inherit !important;
				font-weight: inherit !important;
				line-height: inherit !important;
				margin-top: 0;
			}
		}
	}
}

.bg-dark {
	a {
		@apply text-sol-royal-blue;
	}

	.sub-heading .btn {
		@apply border-sol-white bg-transparent hover:bg-sol-white text-sol-white hover:text-sol-charcoal #{!important};

		&.btn-primary {
			@apply border-sol-white bg-sol-white hover:bg-transparent text-sol-charcoal hover:text-sol-white #{!important};
		}
	}

	.button-block {
		.btn-primary {
			@apply border-sol-white bg-sol-white hover:bg-transparent text-sol-charcoal hover:text-sol-white #{!important};
		}
		.btn-secondary {
			@apply border-sol-white bg-transparent hover:bg-sol-white text-sol-white hover:text-sol-charcoal #{!important};	
		}
	}
}

.bg-default {
	a {
		@apply text-sol-royal-blue;
	}

	.block-btn .btn {
		@apply border-sol-white bg-transparent hover:bg-sol-white text-sol-white hover:text-sol-charcoal #{!important};		
	}

	.sub-heading .btn {
		@apply border-sol-white bg-transparent hover:bg-sol-white text-sol-white hover:text-sol-charcoal #{!important};

		&.btn-primary {
			@apply border-sol-white bg-sol-white hover:bg-transparent text-sol-charcoal hover:text-sol-white #{!important};
		}
	}

	.button-block {
		.btn-primary {
			@apply border-sol-white bg-sol-white hover:bg-transparent text-sol-charcoal hover:text-sol-white #{!important};
		}
		.btn-secondary {
			@apply border-sol-white bg-transparent hover:bg-sol-white text-sol-white hover:text-sol-charcoal #{!important};	
		}
	}

	.sub-heading {
		@apply text-sol-white;

		p {
			font-size: inherit !important;
			font-weight: inherit !important;
			line-height: inherit !important;
			margin-top: 0;
		}
	}
}

.lightbox-carousel iframe {
	@apply w-full min-h-[600px] border-none;
	.ratio {
		@apply bg-transparent #{!important};
	}
}

.single .global-main .btn {
	@apply border-sol-royal-blue text-sol-white bg-sol-royal-blue hover:bg-[#005bb5] hover:text-sol-white #{!important};
}

.blog-accordion .accordion-description {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    flex: 1 0 0;
}

.blog-accordion .accordion-button {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.36px;
}

@media (max-width: 767px) {

.blog-accordion .accordion-description  {
  padding-bottom: 15px; 
}

}
.blog-accordion h4.heading {
    color: var(--charcoal, #25282E);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
}
.blog-accordion .text-paragraph-large {
    margin-bottom: 28px;
}

.blog-accordion {
    margin-top: 48px;
}

.block_FULL-WIDTH-VIDEO {
    .block-img {
        position: relative;
        display: inline-block;
        padding: 1px; // Border thickness
        border-radius: 15px; // Slightly larger than the image
        background: linear-gradient(45deg, hsla(0, 0%, 82%, 0.41), #17191b);

        img {
            display: block;
            border-radius: 15px; // Ensure rounded corners
        }
    }

	&.bg-white {
		.block-img {
			background: transparent;
		}
	}
}
