.block_AB001 {
    @apply relative min-h-[640px] max-[991px]:min-h-[540px];

    @media (max-width: 576px) {
        @apply h-auto min-h-[auto] pb-[150px] #{!important};
    }

    .block-caption {
        @apply max-w-[690px] relative z-[1];
    }

    .text-paragraph-col {
        @apply md:flex md:justify-end;
    }

    .text-paragraph-large {
        @apply relative z-[1] md:max-w-[445px];
    }

    .bg-video {
        @apply object-cover absolute bottom-0 left-0 w-full md:h-1/2 h-[275px] z-0 mix-blend-color-dodge;
    }
}