.block_TXT003 {
    .block-caption {
        @apply bg-sol-white dark:bg-[#17191B] p-[64px_32px] shadow-[0px_10px_30px_0px_#0000001A];

        .heading {
            @apply text-center max-w-[820px] ml-auto mr-auto;
        }

        .block-stats {
            flex-flow: row wrap;
            @apply flex justify-between m-0 p-0 gap-[75px_0] list-none;

            @media(max-width:767px) {
                @apply flex-col gap-[32px];
            }

            li {
                @apply px-[48px] relative h-auto flex-[33%];
    
                @media(max-width: 991px) {
                    @apply px-[16px];
                }

                @media(max-width:767px) {
                    @apply p-0 text-center;
                }

                p {
                    @apply dark:text-sol-white;
                }

                .stats-value {
                    background: linear-gradient(to right, #0074E8, #A933FB);                    
                    -webkit-text-fill-color: transparent;
                    @apply text-[62px] tracking-[-1px] leading-[1.11] font-normal bg-clip-text mb-0;

                    @media(max-width:991px) {
                        @apply text-[52px] leading-[62px];
                    }

                    .unit {
                        @apply text-[52px];

                        @media(max-width:991px) {
                            @apply text-[40px];
                        }
                    }
                }

                &::after {
                    content: "";
                    @apply absolute right-0 top-0 h-full bg-sol-royal-blue w-[1px];

                    @media(max-width:767px) {
                        @apply hidden;
                    }
                }

                &:nth-child(3n+3)::after {
                    @apply hidden;
                }
            }
        }
    }
}