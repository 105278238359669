.block_HR001 {
    @apply relative bg-sol-charcoal flex items-center min-h-[720px];

    .container {
        @apply relative z-[2] h-full flex flex-col justify-end;
    }

    .block-caption {
        @apply flex items-start gap-[50px] flex-col;

        @media (max-width: 992px) {
            @apply gap-[32px];
        }

        @media (max-width: 767px) {
            @apply gap-[50px];
        }

        .heading {
            @apply md:max-w-[75%] max-w-[inherit] text-sol-white;

            span {
                @apply bg-[#0074E880] px-[7px] py-0 ml-[-7px];

                &#translate_words {
                    @apply hidden #{!important};
                }

                &#typewrite_phrase {
                    @apply table;
                }
            }
        }

        .text-paragraph-large {
            @apply max-w-[75%] text-sol-white;
        }
    }

    .bg-video {
        @apply object-cover absolute bottom-0 left-0 top-0 h-full w-full z-0;
    }

    &::after {
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
        @apply content-[''] z-[1] absolute bottom-0 left-0 w-full h-[50vh];
    }
}